import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { ServerGcpRole } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'

export type GcpRolesNodeType = Node<
	{
		roles: ServerGcpRole[]
	},
	'gcpRoles'
>

export const GcpRolesNode = ({ data: { roles } }: NodeProps<GcpRolesNodeType>) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)

	const badgeTooltipContent = roles.map((role) => {
		const segments = role.name.split('/')
		const lastSegment = segments.pop()
		return <span key={role.name}>{lastSegment || 'Unknown role'}</span>
	})

	return (
		<BaseNode label="GCP Roles">
			<NodeGroupBadge items={roles} nodeColorType="permission" tooltipContent={badgeTooltipContent} />
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
