import React from 'react'
import { Button, Spin, Tooltip } from 'antd'
import JiraIntegration from './JiraIntegration.tsx'
import { IntegrationType } from '../schemas/common.ts'
import JiraIcon from '../assets/jira_logo.svg?react'
import { useIssue } from '../../api/issues.ts'
import { useIntegrations } from '../../api/integrations.ts'
import { useEntitlementToShowJiraButton } from '../../services/auth/featureFlags.ts'

export const JiraIntegrationWrapper: React.FunctionComponent<{
	issueId?: string
}> = ({ issueId }) => {
	const { data: integrations, isLoading: isIntegrationsLoading, isError: isIntegrationsError } = useIntegrations()
	const { data: issue, isLoading: isIssueLoading, isError: isIssueError } = useIssue(issueId ?? '')
	const { isEntitled: isEntitledToViewJiraButton } = useEntitlementToShowJiraButton()
	if (!issueId) {
		return null
	}

	if (isIntegrationsLoading || isIssueLoading) {
		return <Spin />
	}

	if (isIntegrationsError || isIssueError) {
		return null
	}

	const jiraIntegration = integrations.find((integration) => integration.integration_type === IntegrationType.JIRA)

	if (!jiraIntegration) {
		if (isEntitledToViewJiraButton) {
			return (
				<Tooltip title="Contact support to open Jira tickets" placement="bottom">
					<Button disabled={true} icon={<JiraIcon className="w-[18px] h-[18px]" />}></Button>
				</Tooltip>
			)
		} else {
			return null
		}
	}

	return <JiraIntegration integration={jiraIntegration} issue={issue} />
}
