import { FilterChipValue } from './FilterChipValue.tsx'
import { TableDateFilterEntry, TableDateFilterType } from '../../../schemas/tableFilter.ts'

const formatDate = (date: string) => new Date(date).toLocaleDateString()

const TableDateFilterToChipValue = {
	[TableDateFilterType.GREATER_THAN]: (dateFrom: string) => `After ${formatDate(dateFrom)}`,
	[TableDateFilterType.LESS_THAN]: (dateFrom: string) => `Before ${formatDate(dateFrom)}`,
	[TableDateFilterType.IN_RANGE]: (dateFrom: string, dateTo: string) =>
		`${formatDate(dateFrom)}-${formatDate(dateTo)}`,
	[TableDateFilterType.BLANK]: () => 'Blank',
} satisfies Record<TableDateFilterType, unknown>

type DateFilterChipProps = {
	filter: TableDateFilterEntry
}

export const DateFilterChip = ({ filter }: DateFilterChipProps) => {
	let title: string
	switch (filter.type) {
		case TableDateFilterType.GREATER_THAN:
		case TableDateFilterType.LESS_THAN:
			title = TableDateFilterToChipValue[filter.type](filter.dateFrom)
			break
		case TableDateFilterType.IN_RANGE:
			title = TableDateFilterToChipValue[filter.type](filter.dateFrom, filter.dateTo)
			break
		case TableDateFilterType.BLANK:
			title = TableDateFilterToChipValue[filter.type]()
			break
		default:
			console.error(`Unsupported date filter: ${JSON.stringify(filter)}`)
			title = '?'
			break
	}

	return <FilterChipValue title={title} />
}
