import { z } from 'zod'
import { AccountPriorityEnum } from './accountPriorityEnum.ts'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'
import { ServerAwsAccountCloudtrailSchema } from './awsAccountCloudtrail.ts'

export const ServerAwsAccountSchema = ServerAbstractEnvironmentModelSchema.extend({
	account_id: z.string(),
	is_management: z.boolean().default(false),
	priority: z.nativeEnum(AccountPriorityEnum),
	account_cloudtrails: z.array(ServerAwsAccountCloudtrailSchema).default([]),
	token_has_access: z.boolean().nullish(),
})

export type ServerAwsAccountSchema = z.infer<typeof ServerAwsAccountSchema>
