import { AgGridReact } from '@ag-grid-community/react'
import React, { useMemo } from 'react'
import { ColDef } from '@ag-grid-community/core'
import {
	aggregateSnowflakeUserChangeLogs,
	SnowflakeUserChangeLogs,
	SnowflakeUserChangeLogsAggregatedSchema,
} from '../schemas/identities/snowflake/snowflakeUserChangeLogs.ts'
import { formatDate, formatRelativeDateText } from '../utils.ts'
import { Tooltip } from 'antd'

export const SnowflakeAggregatedOwnershipTable: React.FunctionComponent<{
	changeLogs: SnowflakeUserChangeLogs[]
}> = ({ changeLogs }) => {
	const defaultColDef: ColDef = {
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	}

	const columnDefs: ColDef<SnowflakeUserChangeLogsAggregatedSchema>[] = [
		{
			field: 'actor_user_name',
			headerName: 'Username',
		},
		{
			field: 'query_type',
			headerName: 'Action',
			valueFormatter: ({ value }: { value: string[] }) => value.join(','),
		},
		{
			field: 'first_time',
			headerName: 'First Time',
			cellRenderer: (params: { value: string }) => (
				<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
					<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
				</Tooltip>
			),
		},
		{
			field: 'last_time',
			headerName: 'Last Time',
			sort: 'desc',
			cellRenderer: (params: { value: string }) => (
				<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
					<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
				</Tooltip>
			),
		},
	]

	const aggregatedEntries = useMemo(() => aggregateSnowflakeUserChangeLogs(changeLogs), [changeLogs])

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			rowData={aggregatedEntries}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			enableCellTextSelection
			suppressHorizontalScroll={false}
		/>
	)
}
