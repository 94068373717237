import { Node, NodeProps } from '@xyflow/react'
import { NodeGroupBadge } from '../common/NodeGroupBadge'
import GcpIcon from '../../../../assets/gcp_project_icon.svg?react'
import { ServerGcpProject } from '../../../../schemas/environments/gcpProject'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode'

export type GcpProjectsNodeType = Node<{ projects: ServerGcpProject[] }, 'gcpProjects'>
type GcpProjectsNodeProps = NodeProps<GcpProjectsNodeType>

export const GcpProjectsNode = ({ data: { projects } }: GcpProjectsNodeProps) => {
	const badgeTooltipContent = projects.map((project) => (
		<span key={project.name}>{project.project_id || 'Unknown project'}</span>
	))

	return (
		<BaseEnvironmentNode label="GCP Projects" icon={<GcpIcon />}>
			<NodeGroupBadge items={projects} nodeColorType="environment" tooltipContent={badgeTooltipContent} />
		</BaseEnvironmentNode>
	)
}
