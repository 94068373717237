import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { ServerGcpServiceAccountProjectRole } from '../../schemas/identities/gcp/gcpServiceAccountSchema.ts'
import { TagIcons } from '../../components/common/TagIcons.tsx'
import { GcpProjectsTable } from './GcpProjectsTable.tsx'
import { ResourceType } from '../../schemas/issue.ts'
import { Tag, TagsDisplayNameMap } from '../../schemas/tags.ts'

interface RoleWithProjects {
	role: ServerGcpServiceAccountProjectRole['role']
	projects: ServerGcpServiceAccountProjectRole[]
}

export const GcpRolesTable: React.FunctionComponent<{
	gcpServiceAccountRolesMappings: ServerGcpServiceAccountProjectRole[]
}> = ({ gcpServiceAccountRolesMappings }) => {
	const gridRef = useRef<AgGridReact<RoleWithProjects>>(null)
	const [rowData, setRowData] = useState<RoleWithProjects[] | null>()
	// Group mappings by role
	const processRowData = useCallback((mappings: ServerGcpServiceAccountProjectRole[]) => {
		const roleMap = new Map<string, RoleWithProjects>()

		mappings.forEach((mapping) => {
			if (!roleMap.has(mapping.role.name)) {
				roleMap.set(mapping.role.name, {
					role: mapping.role,
					projects: [],
				})
			}
			roleMap.get(mapping.role.name)?.projects.push(mapping)
		})

		return Array.from(roleMap.values())
	}, [])

	const columnDefs = useMemo<ColDef<RoleWithProjects>[]>(() => {
		return [
			{
				field: 'role.name',
				headerName: 'Role',
				cellRenderer: 'agGroupCellRenderer',
				flex: 2,
			},
			{
				field: 'role.title',
				headerName: 'Title',
				flex: 1,
			},
			{
				field: 'role.tags',
				headerName: 'Tags',
				cellRenderer: (params: { data?: RoleWithProjects }) => {
					return params.data?.role.tags ? (
						<TagIcons
							tags={params.data.role.tags.map((tag) => ({
								name: tag.name as Tag,
								resourceType: ResourceType.GCP,
							}))}
						/>
					) : null
				},
				filterValueGetter: (params: { data?: RoleWithProjects }) => {
					return params.data?.role.tags?.map((tag) => TagsDisplayNameMap[tag.name as Tag])
				},
				flex: 1,
			},
			{
				field: 'role.description',
				headerName: 'Description',
				filter: false,
				sortable: false,
				flex: 2,
				tooltipField: 'role.description',
			},
		]
	}, [])

	const detailCellRenderer = useMemo(() => {
		return (props: { data: RoleWithProjects }) => (
			<div className="p-4 h-[200px]">
				<GcpProjectsTable gcpServiceAccountRolesMappings={props.data.projects} />
			</div>
		)
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<RoleWithProjects>) => {
			const processedData = processRowData(gcpServiceAccountRolesMappings)
			setRowData(processedData)
			gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
		},
		[gcpServiceAccountRolesMappings, processRowData],
	)

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={rowData}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				detailCellRenderer={detailCellRenderer}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
