import { FilterChipValue } from './FilterChipValue.tsx'
import { TableTextFilterEntry, TableTextFilterType } from '../../../schemas/tableFilter.ts'

const TableTextFilterToChipValue: Record<TableTextFilterType, (value: string) => string> = {
	[TableTextFilterType.CONTAINS]: (value: string) => `Contains "${value}"`,
	[TableTextFilterType.NOT_CONTAINS]: (value: string) => `Does not contain "${value}"`,
	[TableTextFilterType.EQUALS]: (value: string) => `"${value}"`,
	[TableTextFilterType.NOT_EQUAL]: (value: string) => `Does not equal "${value}"`,
	[TableTextFilterType.STARTS_WITH]: (value: string) => `Begins with "${value}"`,
	[TableTextFilterType.ENDS_WITH]: (value: string) => `Ends with "${value}"`,
}

type TextFilterChipProps = {
	filter: TableTextFilterEntry
}

export const TextFilterChip = ({ filter }: TextFilterChipProps) => {
	if (filter.type in TableTextFilterToChipValue) {
		return <FilterChipValue title={TableTextFilterToChipValue[filter.type](filter.filter)} />
	} else {
		console.error(`Unsupported text filter type: ${filter.type}`)
		return <FilterChipValue title={filter.filter} />
	}
}
