import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, SideBarDef, ValueFormatterParams } from '@ag-grid-community/core'
import { formatDate, getCsvFileName } from '../../utils'
import { Button, Modal, Collapse } from 'antd'
import ExportIcon from '../../assets/export_icon_20.svg?react'
import { ServerSnowflakeUserUsageLog } from '../../schemas/identities/snowflake/snowflakeUserSchema'
import { CollapsibleItemLabel } from '../../components/common/CollaplsibleItemLabel.tsx'

type Props = {
	usageLogs: ServerSnowflakeUserUsageLog[]
	username: string
}

const ActionsModal: React.FC<{ actions: string[]; onClose: () => void }> = ({ actions, onClose }) => {
	/**
	 * Splits each action string into up to four parts:
	 *   [ queryType, dbName, schemaName, queryText ]
	 * Then groups by `queryType`, and separates actions
	 * into `known` (where dbName !== 'UNKNOWN') vs `unknown`.
	 */
	const groupedActions = useMemo(() => {
		return actions.reduce(
			(acc, action) => {
				const [queryType = '', dbName = '', schemaName = '', queryText = ''] = action.split(':')
				const queryTypeKey = queryType.toUpperCase()

				if (!acc[queryTypeKey]) {
					acc[queryTypeKey] = {
						known: [] as string[],
						unknown: [] as string[],
					}
				}

				if (dbName === '') {
					// For unknown actions, just show the query text if it exists
					if (queryText) {
						acc[queryTypeKey].unknown.push(queryText)
					}
				} else {
					// For known actions, show dbName:schemaName
					acc[queryTypeKey].known.push(`${dbName}:${schemaName}`)
				}

				return acc
			},
			{} as Record<string, { known: string[]; unknown: string[] }>,
		)
	}, [actions])

	return (
		<Modal title="Actions" open={true} onCancel={onClose} footer={null} width={800} centered style={{ top: 20 }}>
			<div className="max-h-[70vh] overflow-y-auto">
				<Collapse
					expandIconPosition="end"
					style={{ backgroundColor: 'bg-zinc-100' }}
					items={Object.entries(groupedActions).map(([queryType, { known, unknown }]) => ({
						key: queryType,
						headerClass: '!items-center',
						label: <CollapsibleItemLabel label={queryType} />,
						children: (
							<div className="flex flex-col gap-4">
								{/* Known Database Actions */}
								{known.length > 0 && (
									<div className="bg-white rounded border border-zinc-200">
										<div className="px-4 py-2 bg-zinc-50 border-b border-zinc-200 text-zinc-600">
											Database & Schema Names
										</div>
										{known.map((action, idx) => (
											<div key={idx} className="py-2 border-b border-zinc-200 px-4 last:border-0">
												{action}
											</div>
										))}
									</div>
								)}

								{/* Unknown Database Actions */}
								{unknown.length > 0 && (
									<div className="bg-white rounded border border-zinc-200">
										<div className="px-4 py-2 bg-zinc-50 border-b border-zinc-200 text-zinc-600">
											Unknown SQL Queries
										</div>
										{unknown.map((action, idx) => (
											<div key={idx} className="py-2 border-b border-zinc-200 px-4 last:border-0">
												{action}
											</div>
										))}
									</div>
								)}
							</div>
						),
					}))}
				/>
			</div>
		</Modal>
	)
}

export const SnowflakeUsageLogsTable: React.FC<Props> = ({ usageLogs, username }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedActions, setSelectedActions] = useState<string[]>([])
	const [isActionsModalOpen, setIsActionsModalOpen] = useState(false)

	const aggregatedGridRef = useRef<AgGridReact<ServerSnowflakeUserUsageLog>>(null)
	const detailedGridRef = useRef<AgGridReact<ServerSnowflakeUserUsageLog>>(null)

	const handleOpenModal = useCallback(() => {
		setIsModalOpen(true)
	}, [])

	const handleOpenActionsModal = useCallback((actions: string[]) => {
		setSelectedActions(actions)
		setIsActionsModalOpen(true)
	}, [])

	const sideBar: SideBarDef = useMemo(() => {
		return {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
				},
			],
			position: 'left',
		}
	}, [])

	// Renders a link that opens the actions modal if there are actions
	const actionsRenderer = useCallback(
		(params: { value: string[] }) => {
			const actions = params.value
			if (!actions?.length) return 'N/A'

			return (
				<div className="flex items-center gap-2 w-full h-full">
					<Button
						type="link"
						size="small"
						className="whitespace-nowrap !p-0"
						onClick={(e) => {
							e.stopPropagation()
							handleOpenActionsModal(actions)
						}}
					>
						View {actions.length} actions
					</Button>
				</div>
			)
		},
		[handleOpenActionsModal],
	)

	// Aggregated table columns
	const aggregatedColumnDefs = useMemo<ColDef<ServerSnowflakeUserUsageLog>[]>(
		() => [
			{
				field: 'day',
				headerName: 'Day',
				flex: 0.7,
				valueFormatter: (params: ValueFormatterParams) => formatDate(params.value as string),
			},
			{
				field: 'client_ip',
				headerName: 'IP',
				flex: 0.7,
			},

			{
				field: 'actions',
				headerName: 'Actions',
				flex: 1,
				cellRenderer: actionsRenderer,
				filterValueGetter: (params) => {
					return params.data?.actions?.map((action) => action.split('\n').join(' '))
				},
			},
			{
				field: 'total_count',
				headerName: 'Count',
				flex: 0.5,
			},
		],
		[],
	)

	// Detailed table columns
	const detailedColumnDefs = useMemo<ColDef<ServerSnowflakeUserUsageLog>[]>(
		() => [
			{
				field: 'day',
				headerName: 'Day',
				flex: 0.7,
				valueFormatter: (params: ValueFormatterParams) => formatDate(params.value as string),
			},
			{
				field: 'client_ip',
				headerName: 'Client IP',
				flex: 0.5,
			},
			{
				field: 'environment',
				headerName: 'Environment',
				flex: 0.9,
			},
			{
				field: 'actions',
				headerName: 'Actions',
				flex: 0.6,
				cellRenderer: actionsRenderer,
				filterValueGetter: (params) => {
					return params.data?.actions?.map((action) => action.split('\n').join(' '))
				},
			},
			{
				field: 'login',
				headerName: 'Auth Method',
				flex: 0.8,
				valueFormatter: (params: ValueFormatterParams) => {
					// login is stored as "factor1:factor2:success"
					if (!params.value) return 'N/A'
					const [factor1 = 'N/A'] = (params.value as string).split(':', 3)
					return factor1
				},
				filterValueGetter: (params) => {
					if (!params.data?.login) return 'N/A'
					const [factor1 = 'N/A'] = params.data.login.split(':', 3)
					return factor1
				},
			},
			{
				field: 'login',
				headerName: 'MFA',
				flex: 0.5,
				valueFormatter: (params: ValueFormatterParams) => {
					if (!params.value) return 'N/A'
					const [, factor2 = 'N/A'] = (params.value as string).split(':', 3)
					return factor2 || 'N/A'
				},
				filterValueGetter: (params) => {
					if (!params.data?.login) return 'N/A'
					const [, factor2 = 'N/A'] = params.data.login.split(':', 3)
					return factor2 || 'N/A'
				},
			},
			{
				field: 'login',
				headerName: 'Login Success',
				flex: 0.5,
				valueFormatter: (params: ValueFormatterParams) => {
					if (!params.value) return 'N/A'
					const parts = (params.value as string).split(':', 3)
					return parts[2] || 'N/A'
				},
				filterValueGetter: (params) => {
					if (!params.data?.login) return 'N/A'
					const parts = params.data.login.split(':', 3)
					return parts[2] || 'N/A'
				},
			},
			{
				field: 'total_count',
				headerName: 'Count',
				flex: 0.4,
				filter: 'agNumberColumnFilter',
			},
		],
		[actionsRenderer],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
				padding: '0 8px',
				overflow: 'hidden',
			}),
			autoHeight: true,
		}
	}, [])

	const exportToCsv = useCallback(() => {
		if (isModalOpen) {
			detailedGridRef.current?.api.exportDataAsCsv({
				fileName: getCsvFileName(`snowflake-usage-logs-${username}`, false),
			})
		} else {
			aggregatedGridRef.current?.api.exportDataAsCsv({
				fileName: getCsvFileName(`snowflake-usage-logs-aggregated-${username}`, false),
			})
		}
	}, [isModalOpen, username])

	if (usageLogs.length === 0 && !isModalOpen) {
		return <div className="text-center">No Snowflake Usage Logs available</div>
	}

	return (
		<>
			<div className="h-64 overflow-auto">
				<AgGridReact
					rowData={usageLogs}
					columnDefs={aggregatedColumnDefs}
					defaultColDef={defaultColDef}
					ref={aggregatedGridRef}
					enableCellTextSelection
					className="ag-theme-alpine w-full h-full overflow-x-auto"
					rowHeight={54}
				/>
			</div>
			<div className="flex justify-between">
				<button className="mt-4 underline cursor-pointer text-blue-600" onClick={handleOpenModal}>
					See all events
				</button>
			</div>
			<Modal
				centered
				width={1024}
				title="Snowflake Usage Logs"
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center w-full">
						<Button
							icon={<ExportIcon />}
							onClick={exportToCsv}
							className="bg-white text-gray-500 px-2 flex items-center mr-2"
						>
							Export CSV
						</Button>
						<Button className="bg-black text-white" onClick={() => setIsModalOpen(false)}>
							Done
						</Button>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					{usageLogs.length === 0 ? (
						<div className="w-full flex items-center justify-center text-gray-500">
							No detailed logs available
						</div>
					) : (
						<AgGridReact
							className="ag-theme-alpine w-full"
							rowData={usageLogs}
							rowHeight={54}
							columnDefs={detailedColumnDefs}
							defaultColDef={defaultColDef}
							enableCellTextSelection
							sideBar={sideBar}
							ref={detailedGridRef}
						/>
					)}
				</div>
			</Modal>

			{isActionsModalOpen && (
				<ActionsModal actions={selectedActions} onClose={() => setIsActionsModalOpen(false)} />
			)}
		</>
	)
}
