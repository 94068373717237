import { api } from './api'
import {
	AffectedEnvironmentWithEnv,
	AffectedEnvironmentWithEnvSchema,
	IdentitiesPageLens,
	IdentitySource,
	IdentityTabName,
	ServerIdentitiesQueryResponse,
	ServerIdentitiesQueryResponseSchema,
	ServerIdentitiesStats,
	ServerIdentitiesStatsSchema,
	ServerIdentity,
	ServerIdentityEnvironment,
	ServerIdentityEnvironmentSchema,
	ServerIdentityRelatedIssues,
	ServerIdentityRelatedIssuesSchema,
	ServerIdentitySchema,
	ServerIdentityUsageLogs,
	ServerIdentityUsageLogsGrouped,
	ServerIdentityUsageLogsGroupedSchema,
	ServerIdentityUsageLogsSchema,
} from '../schemas/identity'
import { useQuery } from '@tanstack/react-query'
import { ZodError } from 'zod'
import { QueryParameters } from '../schemas/query.ts'
import { queryClient } from './queryClient.ts'

export const identityKeys = {
	identityById: (identityId: string) => ['identityById', identityId] as const,
	relatedIssues: (identityId?: string) => ['relatedIssues', identityId] as const,
	identityUsageLogs: (identityId: string, aggregated: boolean) =>
		['identityUsageLogs', identityId, aggregated] as const,
	identityUsageLogsGrouped: (identityId: string) => ['identityUsageLogsGrouped', identityId] as const,
	queryIdentities: (query: QueryParameters, lens?: IdentitiesPageLens, tab?: IdentityTabName) =>
		['queryIdentities', query, lens, tab] as const,
	queryIdentitiesForExport: (query: QueryParameters, lens?: IdentitiesPageLens, tab?: IdentityTabName) =>
		['queryIdentitiesForExport', query, lens, tab] as const,
	queryIdentitiesExportingLimit: () => ['queryIdentitiesExportingLimit'] as const,
	identityStats: (query: QueryParameters, lens?: IdentitiesPageLens, tab?: IdentityTabName) =>
		['identityStats', query, lens, tab] as const,
	identityEnvironments: (query: QueryParameters, lens?: IdentitiesPageLens) =>
		['identityEnvironments', query, lens] as const,
	identitySources: (query: QueryParameters, lens?: IdentitiesPageLens) => ['identitySources', query, lens] as const,
	identityAffectedEnvironments: (query: QueryParameters, lens?: IdentitiesPageLens) =>
		['identityAffectedEnvironments', query, lens] as const,
	identityTagsWithEnvironment: (query: QueryParameters, lens?: IdentitiesPageLens) =>
		['identityTagsWithEnvironment', query, lens] as const,
	identityLensStats: () => ['identityLensStats'] as const,
}

async function getById(identityId: string): Promise<ServerIdentity> {
	try {
		const { data: identity } = await api.get<ServerIdentity>(`/api/identity/${identityId}`)
		return ServerIdentitySchema.parse(identity)
	} catch (error) {
		if (error instanceof ZodError) {
			console.error('Validation error:', JSON.stringify(error.errors, null, 2))
			throw new Error(`Data validation failed: ${error.errors.map((e) => e.message).join(', ')}`)
		} else {
			console.error('Error fetching identity:', error)
			throw error
		}
	}
}

export function useIdentity(identityId: string) {
	return useQuery({
		queryKey: identityKeys.identityById(identityId),
		queryFn: () => getById(identityId),
		cacheTime: 5 * 60 * 1000, // 5 minutes
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIdentityLensStats(): Promise<Record<IdentitiesPageLens, number>> {
	const { data } = await api.get<Record<IdentitiesPageLens, number>>(`/api/identities/lens-stats`)
	return data
}

export function useIdentityLensStats() {
	return useQuery({
		queryKey: identityKeys.identityLensStats(),
		queryFn: () => getIdentityLensStats(),
	})
}

async function getRelatedIssues(identityId?: string): Promise<ServerIdentityRelatedIssues | undefined> {
	if (!identityId) {
		return undefined
	}
	const { data: identityRelatedIssues } = await api.get<ServerIdentityRelatedIssues>(
		`/api/identity/${identityId}/related-issues`,
	)

	return ServerIdentityRelatedIssuesSchema.parse(identityRelatedIssues)
}

export function useRelatedIssues(identityId?: string, disable?: boolean) {
	return useQuery({
		queryKey: identityKeys.relatedIssues(identityId),
		queryFn: () => getRelatedIssues(identityId),
		enabled: !disable && !!identityId,
	})
}

async function getUsageLogs(identityId: string, aggregated: boolean = false): Promise<ServerIdentityUsageLogs> {
	const { data } = await api.get<ServerIdentityUsageLogs>(
		`/api/identity/${identityId}/usage-logs${aggregated ? '?aggregated=true' : ''}`,
	)
	return ServerIdentityUsageLogsSchema.parse(data)
}

type UsageLogsOptions = {
	enabled?: boolean
	staleTime?: number
	refetchOnWindowFocus?: boolean
}

export function useIdentityUsageLogs(identityId: string, aggregated: boolean = false, options: UsageLogsOptions = {}) {
	const {
		enabled = true,
		staleTime = aggregated ? 5 * 60 * 1000 : 0, // 5 minutes stale time for aggregated, no stale for detailed
		refetchOnWindowFocus = aggregated, // auto-refresh for aggregated only
	} = options

	return useQuery({
		queryKey: identityKeys.identityUsageLogs(identityId, aggregated),
		queryFn: () => getUsageLogs(identityId, aggregated),
		enabled,
		staleTime,
		refetchOnWindowFocus,
		// Keep data in cache when component unmounts
		cacheTime: 10 * 60 * 1000, // 10 minutes
		// Retry failed requests
		retry: 2,
		// Show stale data while revalidating
		keepPreviousData: true,
	})
}

async function getUsageLogsGrouped(identityId: string): Promise<ServerIdentityUsageLogsGrouped> {
	const { data } = await api.get<ServerIdentityUsageLogsGrouped>(`/api/identity/${identityId}/usage-logs-grouped`)

	return ServerIdentityUsageLogsGroupedSchema.parse(data)
}

type UsageLogsGroupedOptions = {
	enabled?: boolean
}

export function useIdentityUsageLogsGrouped(identityId: string, options: UsageLogsGroupedOptions = {}) {
	const { enabled = false } = options

	return useQuery({
		queryKey: identityKeys.identityUsageLogsGrouped(identityId),
		queryFn: () => getUsageLogsGrouped(identityId),
		cacheTime: 10 * 60 * 1000, // 10 minutes
		// Retry failed requests
		retry: 2,
		keepPreviousData: true,
		enabled: enabled,
	})
}

async function queryIdentities(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
	tab?: IdentityTabName,
): Promise<ServerIdentitiesQueryResponse> {
	const { data } = await api.post<ServerIdentitiesQueryResponse>(`/api/identity/query`, {
		query,
		lens,
		tab,
	})
	return ServerIdentitiesQueryResponseSchema.parse(data)
}

export async function fetchIdentitiesQuery(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
	tab?: IdentityTabName,
): Promise<ServerIdentitiesQueryResponse> {
	return queryClient.fetchQuery({
		queryKey: identityKeys.queryIdentities(query, lens, tab),
		queryFn: () => queryIdentities(query, lens, tab),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function queryIdentitiesForExport(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
	tab?: IdentityTabName,
): Promise<ServerIdentitiesQueryResponse> {
	const { data } = await api.post<ServerIdentitiesQueryResponse>(`/api/identity/export`, {
		query,
		lens,
		tab,
	})
	return ServerIdentitiesQueryResponseSchema.parse(data)
}

export async function fetchIdentitiesQueryForExport(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
	tab?: IdentityTabName,
): Promise<ServerIdentitiesQueryResponse> {
	return queryClient.fetchQuery({
		queryKey: identityKeys.queryIdentitiesForExport(query, lens, tab),
		queryFn: () => queryIdentitiesForExport(query, lens, tab),
		staleTime: 1 * 60 * 1000, // 1 minute
	})
}

async function getIdentitiesExportingLimit(): Promise<number> {
	const { data } = await api.get<number>(`/api/identities/export-limit`)
	return data
}

export function useIdentitiesExportingLimit() {
	return useQuery({
		queryKey: identityKeys.queryIdentitiesExportingLimit(),
		queryFn: () => getIdentitiesExportingLimit(),
	})
}

async function stats(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
	tab?: IdentityTabName,
): Promise<ServerIdentitiesStats> {
	const { data } = await api.post<ServerIdentitiesStats>(`/api/identity/query-stats`, {
		query,
		lens,
		tab,
	})
	return ServerIdentitiesStatsSchema.parse(data)
}

export function useIdentityStats(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
	tab?: IdentityTabName,
	disabled?: boolean,
	keepPreviousData?: boolean,
) {
	return useQuery({
		queryKey: identityKeys.identityStats(query, lens, tab),
		queryFn: () => stats(query, lens, tab),
		enabled: !disabled,
		staleTime: 5 * 60 * 1000, // 5 minutes
		keepPreviousData,
	})
}

async function getIdentityEnvironments(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<ServerIdentityEnvironment>> {
	const { data } = await api.post<Array<ServerIdentityEnvironment>>(`/api/identity/environments`, { query, lens })
	return data.map((datum) => ServerIdentityEnvironmentSchema.parse(datum))
}

export async function fetchIdentityEnvironments(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<ServerIdentityEnvironment>> {
	return queryClient.fetchQuery({
		queryKey: identityKeys.identityEnvironments(query, lens),
		queryFn: () => getIdentityEnvironments(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

export function useIdentityEnvironments(query: QueryParameters, lens?: IdentitiesPageLens) {
	return useQuery({
		queryKey: identityKeys.identityEnvironments(query, lens),
		queryFn: () => getIdentityEnvironments(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIdentitySources(query: QueryParameters, lens?: IdentitiesPageLens): Promise<Array<IdentitySource>> {
	const { data } = await api.post<Array<IdentitySource>>(`/api/identity/sources`, { query, lens })
	return data
}

export async function fetchIdentitySources(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<IdentitySource>> {
	return queryClient.fetchQuery({
		queryKey: identityKeys.identitySources(query, lens),
		queryFn: () => getIdentitySources(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIdentityAffectedEnvironments(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<AffectedEnvironmentWithEnv>> {
	const { data } = await api.post<Array<AffectedEnvironmentWithEnv>>(`/api/identity/affected-environments`, {
		query,
		lens,
	})
	return data.map((datum) => AffectedEnvironmentWithEnvSchema.parse(datum))
}

export async function fetchIdentityAffectedEnvironments(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<AffectedEnvironmentWithEnv>> {
	return queryClient.fetchQuery({
		queryKey: identityKeys.identityAffectedEnvironments(query, lens),
		queryFn: () => getIdentityAffectedEnvironments(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

export function useIdentityAffectedEnvironments(query: QueryParameters, lens?: IdentitiesPageLens) {
	return useQuery({
		queryKey: identityKeys.identityAffectedEnvironments(query, lens),
		queryFn: () => getIdentityAffectedEnvironments(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIdentityTagsWithEnvironment(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<string>> {
	const { data } = await api.post<Array<string>>(`/api/identity/tags-with-environment`, {
		query,
		lens,
	})
	return data
}

export async function fetchIdentityTagsWithEnvironment(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<string>> {
	return queryClient.fetchQuery({
		queryKey: identityKeys.identityTagsWithEnvironment(query, lens),
		queryFn: () => getIdentityTagsWithEnvironment(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}
