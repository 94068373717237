import React from 'react'
import { EnvironmentType } from '../../../../schemas/envType'
import { ServerEntraIdEnvironment } from '../../../../schemas/environments/entraIdEnvironment'
import { EntraIdSinglePageIntegrationBody } from './EntraIdSinglePageIntegrationBody'
import { ServerEnvironments } from '../../../../schemas/environments/environments'
import { SinglePageIntegrationEnabledEnvironments } from '../environmentIntegrationsTypes'

interface IntegrationBodyRendererProps {
	integrationEnvironmentType: EnvironmentType
	singlePageFilteredEnvironments: ServerEnvironments[keyof ServerEnvironments]
	singlePageEnvironments: ServerEnvironments[keyof ServerEnvironments]
}

export const SinglePageIntegrationBody: React.FC<IntegrationBodyRendererProps> = ({
	integrationEnvironmentType,
	singlePageFilteredEnvironments: filteredEnvironments,
	singlePageEnvironments: environments,
}) => {
	if (!SinglePageIntegrationEnabledEnvironments.includes(integrationEnvironmentType)) {
		return <div>Integration type not supported.</div>
	}
	if (environments.length !== 0 && filteredEnvironments.length === 0) {
		return <div>No accounts found for this search.</div>
	} else if (environments.length === 0 && filteredEnvironments.length === 0) {
		return <div>No accounts connected, connect an account to get started.</div>
	}
	switch (integrationEnvironmentType) {
		case EnvironmentType.ENTRA_ID:
			return (
				<EntraIdSinglePageIntegrationBody
					entraIdEnvironments={filteredEnvironments as ServerEntraIdEnvironment[]}
				/>
			)
	}
}
