import { ExclamationOutlined, KeyOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { BaseNode, BaseNodeProps } from './BaseNode.tsx'
import { ReactNode } from 'react'
import { NodeBadge } from '../NodeBadge.tsx'
import { BaseServerIssue } from '../../../../../schemas/issue.ts'

type BaseCredentialsNodeProps = {
	label: ReactNode
	isHighlighted?: boolean
	name?: BaseNodeProps['name']
	issueAttached?: BaseServerIssue
}

export const BaseCredentialsNode = ({ label, isHighlighted, name, issueAttached }: BaseCredentialsNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.credentials.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.credentials.bg]: !isHighlighted,
			[nodeColorClassNames.credentials.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode label={label} name={name}>
			{issueAttached && (
				<NodeBadge className={'text-lg bg-pink-700 border-white text-white'}>
					<ExclamationOutlined data-badge="true" />
				</NodeBadge>
			)}
			<div className={iconWrapperClassName}>
				<KeyOutlined />
			</div>
		</BaseNode>
	)
}
