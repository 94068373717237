import { useEntitlements } from '@frontegg/react'

enum FeatureFlags {
	SHOW_JIRA_BUTTON = 'show-jira-button',
	INVENTORY_FILTER_CHIPS = 'inventory-filter-chips',
	USAGE_GRAPH = 'usage-graph',
}

export const useEntitlementToShowJiraButton = () => {
	return useEntitlements({ featureKey: FeatureFlags.SHOW_JIRA_BUTTON })
}

export const useEntitlementToInventoryFilterChips = () => {
	return useEntitlements({ featureKey: FeatureFlags.INVENTORY_FILTER_CHIPS })
}

export const useUsageGraph = () => {
	return useEntitlements({ featureKey: FeatureFlags.USAGE_GRAPH })
}
