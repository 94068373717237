import { createServer, Instantiate } from 'miragejs'
import { Server } from 'miragejs/server'
import { issues } from './data/issues.ts'
import { identities } from './data/identities.ts'
import { settings } from '../env-settings.ts'
import {
	mirageGetRelatedIssues,
	mirageIdentitiesQuery,
	mirageIdentity,
	mirageUsageLogs,
	mirageIdentityFilters,
	mirageIdentityQueryStats,
	mirageIdentityExportLimit,
	mirageIdentityLensStats,
	mirageUsageLogsGrouped,
} from './endpoints/identities.ts'
import { mirageById, mirageIssueTypes, mirageSearch, mirageStats, mirageUpdateIssueTypes } from './endpoints/issues.ts'
import {
	mirageFindingsDistByEnv,
	mirageFindingsDistByName,
	mirageIdentitiesDistByPriorityAndDate,
	mirageIdentitiesLifetimeDistribution,
	mirageIdentitiesWithIssueEnvTypeDistribution,
	mirageKeyRotation,
	mirageMonitoredIdentitiesByEnv,
	mirageMostWantedIdentities,
	mirageResolvedVsUnresolvedFindings,
	mirageSummary,
	mirageTopBar,
	mirageTopIssuesAndInsights,
} from './endpoints/metrics.ts'
import { AppRegistry, models } from './schemas.ts'
import {
	mirageCallJiraProxy,
	mirageCreateIntegrationTicketInTokenDb,
	mirageGetAll,
	mirageGetTickets,
} from './endpoints/jira.ts'
import { mirageGetAccounts, mirageUpdateAccounts } from './endpoints/environments.ts'
import { notifications } from './data/notifications.ts'
import { ModelInitializer } from 'miragejs/orm/schema'
import { mirageNotifications } from './endpoints/notifications.ts'
import { awsUsageLogsGroupedByIdentityId } from './data/awsIamUserData.ts'

function configureServer(server: Server<AppRegistry>) {
	server.urlPrefix = settings.viteBackendUrl
	server.namespace = 'api'
	server.passthrough((request) => {
		if (!request.url.includes(settings.viteBackendUrl)) {
			return true
		}
	})
}

function setServerRoutes(server: Server<AppRegistry>) {
	mirageGetRelatedIssues(server)
	mirageStats(server)
	mirageIssueTypes(server)
	mirageUpdateIssueTypes(server)
	mirageSearch(server)
	mirageIdentity(server)
	mirageUsageLogs(server)
	mirageUsageLogsGrouped(server)
	mirageById(server)
	mirageSummary(server)
	mirageTopIssuesAndInsights(server)
	mirageFindingsDistByName(server)
	mirageMonitoredIdentitiesByEnv(server)
	mirageMostWantedIdentities(server)
	mirageResolvedVsUnresolvedFindings(server)
	mirageFindingsDistByEnv(server)
	mirageGetAll(server)
	mirageCallJiraProxy(server)
	mirageCreateIntegrationTicketInTokenDb(server)
	mirageGetTickets(server)
	mirageKeyRotation(server)
	mirageGetAccounts(server)
	mirageUpdateAccounts(server)
	mirageIdentitiesDistByPriorityAndDate(server)
	mirageIdentitiesLifetimeDistribution(server)
	mirageTopBar(server)
	mirageIdentitiesWithIssueEnvTypeDistribution(server)
	mirageNotifications(server)
	mirageIdentitiesQuery(server)
	mirageIdentityFilters(server)
	mirageIdentityQueryStats(server)
	mirageIdentityExportLimit(server)
	mirageIdentityLensStats(server)
}

export default function makeMirageServer({ environment = 'development' } = {}) {
	return createServer({
		environment: environment,
		models: models,
		seeds(server: Server<AppRegistry>) {
			issues.forEach((issue) => {
				server.create('issue', issue)
			})
			// Identities were written differently in the data file, not as an array, but as an array of objects
			for (const [, value] of Object.entries(identities)) {
				server.create('identity', value)
			}
			notifications.forEach((notification) => {
				// Mirage is stupid and doesn't accept the notification type, so we need to explicitly type it.
				server.create(
					'notification',
					notification as unknown as Partial<ModelInitializer<Instantiate<AppRegistry, 'notification'>>>,
				)
			})

			awsUsageLogsGroupedByIdentityId.forEach((usageLogsGrouped) => {
				server.create(
					'usage-logs-grouped',
					usageLogsGrouped as unknown as Partial<
						ModelInitializer<Instantiate<AppRegistry, 'usage-logs-grouped'>>
					>,
				)
			})
		},
		routes() {
			// Bug in mirage-js that causes the app to hang when using fetch, see issue - https://github.com/miragejs/miragejs/issues/1006#issuecomment-1439946798
			const NativeXMLHttpRequest = window.XMLHttpRequest
			// @ts-expect-error - This is a hack to fix the issue with fetch hanging in miragejs
			window.XMLHttpRequest = function XMLHttpRequest() {
				const request = new NativeXMLHttpRequest()
				// @ts-expect-error - Delete request.onloadend
				delete request.onloadend
				return request
			}

			configureServer(this)
			setServerRoutes(this)
		},
	})
}
