import { IdentityActivityStatus, ServerIdentity } from '../../../schemas/identity.ts'
import { useMemo } from 'react'
import { Link } from '@tanstack/react-router'
import { LeftOutlined } from '@ant-design/icons'
import { Breadcrumb, Tooltip } from 'antd'
import { StatItem } from '../../../components/common/StatItem.tsx'
import { PriorityNameTag } from '../../../components/common/PriorityNameTag.tsx'
import { filterOpenIssues, getIssuesMaxPriority } from '../../../utils/issueUtils.ts'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { IdentityTypeTag } from '../../../components/common/IdentityTypeTag.tsx'
import { TagIcons } from '../../../components/common/TagIcons.tsx'
import { formatDate } from '../../../utils.ts'
import { RoutePaths } from '../../RoutePaths.tsx'
import { IsDeletedBody } from './IsDeletedBody.tsx'
import { Tag } from '../../../schemas/tags.ts'

export const IdentityHeader = ({ identity }: { identity: ServerIdentity }) => {
	const breadcrumbsItems = useMemo(
		() => [
			{
				title: (
					<Link
						from={RoutePaths.Identity}
						to={RoutePaths.Identities}
						search={({ identitiesPage }) => ({ ...identitiesPage })}
						className="!flex items-center !h-full"
					>
						<LeftOutlined className="mr-2" />
						Inventory
					</Link>
				),
			},
			{ title: <Tooltip title={identity.literal}>{identity.literal_friendly_name || identity.literal}</Tooltip> },
		],
		[identity],
	)

	return (
		<div className="flex flex-col gap-4">
			<Breadcrumb className="text-base" items={breadcrumbsItems} />
			<div className="flex justify-between">
				<StatItem title="Risk Score">
					<PriorityNameTag priority={getIssuesMaxPriority(filterOpenIssues(identity.issues))} />
				</StatItem>
				<StatItem title="Identity Source">
					<IssueSourceIcon source={identity.env_type} />
				</StatItem>
				<StatItem title="Open Issues">
					<span className="text-2xl font-medium">{filterOpenIssues(identity.issues).length ?? 0}</span>
				</StatItem>
				<StatItem title="Identity Type">
					<IdentityTypeTag type={identity.type} />
				</StatItem>
				<StatItem title="Risk Type">
					{identity.tags?.length ? (
						<TagIcons tags={identity.tags.map(({ name }) => ({ name: name as Tag }))} />
					) : (
						'N/A'
					)}
				</StatItem>
				{identity?.activity_status === IdentityActivityStatus.DELETED && (
					<StatItem title="Is Deleted">
						<IsDeletedBody />
					</StatItem>
				)}
				<StatItem title="Date Created">
					<Tooltip title={formatDate(identity.created_at)}>{formatDate(identity.created_at, false)}</Tooltip>
				</StatItem>
				<StatItem title="Date Updated">
					<Tooltip title={formatDate(identity.updated_at)}>{formatDate(identity.updated_at, false)}</Tooltip>
				</StatItem>
				<StatItem title="Last Activity">
					<Tooltip title={formatDate(identity.last_activity)}>
						{formatDate(identity.last_activity, false)}
					</Tooltip>
				</StatItem>
			</div>
		</div>
	)
}
