import React from 'react'
import cx from 'classnames'
import { Button, Tooltip } from 'antd'
import { CheckCircleOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { IntegrationStatus } from '../integrationTypes.ts'

interface IntegrationCardProps {
	logo: React.ReactNode
	name: string
	description: string
	status: IntegrationStatus
	environmentsConfig?: {
		names: string[]
		logsConnected?: boolean | number
	}
	onConnect?: () => void
	onCardClick?: () => void
}

type IntegrationCardButtonProps = Pick<IntegrationCardProps, 'onConnect' | 'status' | 'name'>

const IntegrationCardButton = ({ onConnect, status, name }: IntegrationCardButtonProps) => {
	const buttonClassName = cx('w-full h-8 flex items-center justify-center gap-2 rounded text-sm font-medium', {
		'bg-green-100 text-green-500': status === IntegrationStatus.ACTIVE,
		'bg-zinc-100 text-zinc-800 border border-zinc-100': status === IntegrationStatus.INACTIVE,
	})

	if (status === IntegrationStatus.ACTIVE) {
		return (
			<div className={buttonClassName}>
				<CheckCircleOutlined /> Connected
			</div>
		)
	}

	const buttonNode = (
		<Button
			onClick={onConnect ? onConnect : undefined}
			disabled={!onConnect}
			className={buttonClassName}
			icon={<PlusOutlined />}
		>
			Connect
		</Button>
	)

	if (!onConnect) {
		return (
			<Tooltip title={`To connect your ${name} environment please contact the Token team.`}>{buttonNode}</Tooltip>
		)
	}

	return buttonNode
}

const IntegrationCardEnvironments = ({ environmentNames }: { environmentNames: string[] }) => (
	<div className="flex flex-col gap-1 truncate">
		<span className="text-neutral-500">{environmentNames.length === 1 ? 'Environment' : 'Environments'}</span>
		<Tooltip
			placement="topLeft"
			title={
				<div className="flex flex-col">
					{environmentNames.map((envName, i) => (
						<span key={i}>{envName}</span>
					))}
				</div>
			}
		>
			<span className="text-gray-900 truncate">
				{environmentNames.length === 1 ? environmentNames[0] : environmentNames.length}
			</span>
		</Tooltip>
	</div>
)

const IntegrationCardLogs = ({
	logsConnected,
	environmentCount,
}: {
	logsConnected: boolean | number
	environmentCount: number
}) => {
	return (
		<div className="flex flex-col gap-1">
			<span className="text-neutral-500">Logs</span>
			{typeof logsConnected === 'boolean' ? (
				logsConnected ? (
					<CheckCircleOutlined className="text-green-700 text-base" />
				) : (
					<ExclamationCircleOutlined className="text-orange-700 text-base" />
				)
			) : (
				`${logsConnected}/${environmentCount}`
			)}
		</div>
	)
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
	logo,
	name,
	description,
	status,
	environmentsConfig,
	onConnect,
	onCardClick,
}) => {
	return (
		<div
			className={cx(
				'w-[270px] p-4 bg-white rounded-lg border border-zinc-200 flex flex-col gap-4',
				onCardClick && 'hover:border-blue-500 hover:shadow-lg transition-all duration-300 cursor-pointer',
			)}
			onClick={onCardClick}
		>
			<div className="flex justify-between items-center">
				<div className="flex items-center gap-2">
					{logo}
					<span className="text-base font-medium text-gray-900">{name}</span>
				</div>
				{/* We currently want to show the tooltip for editing for integrations w/o single integration page */}
				{onCardClick === undefined && (
					<Tooltip title="If you wish to make changes, please contact the Token team.">
						<Button
							icon={<EditOutlined />}
							onClick={() => {}}
							className="text-gray-500 p-1"
							type="text"
							disabled={true}
						/>
					</Tooltip>
				)}
			</div>
			<div className="grow">
				{environmentsConfig?.names?.length ? (
					<div className="flex gap-4">
						<IntegrationCardEnvironments environmentNames={environmentsConfig.names} />
						{environmentsConfig.logsConnected !== undefined && (
							<IntegrationCardLogs
								logsConnected={environmentsConfig.logsConnected}
								environmentCount={environmentsConfig.names.length}
							/>
						)}
					</div>
				) : (
					<p className="text-xs text-gray-500">{description}</p>
				)}
			</div>
			<IntegrationCardButton onConnect={onConnect} status={status} name={name} />
		</div>
	)
}
