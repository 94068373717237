/* eslint-disable camelcase */
import Schema from 'miragejs/orm/schema'
import { AppRegistry } from './schemas.ts'
import {
	IssueImpactProbabilityWithCount,
	IssueImpactProbabilityWithCountSchema,
	IssueStatus,
	ServerIssue,
	ServerIssueSchema,
} from '../schemas/issue.ts'
import {
	ServerIdentityUsageLogs,
	ServerIdentityUsageLogsSchema,
	ServerIdentitiesTableRow,
	ServerIdentitiesTableRowSchema,
	ServerIdentity,
	ServerIdentitySchema,
	IdentitySource,
	ServerIdentityUsageLogsGrouped,
	ServerIdentityUsageLogsGroupedSchema,
} from '../schemas/identity.ts'
import { EnvironmentType } from '../schemas/envType.ts'
import { ServerTag } from '../schemas/tags.ts'
import { NotificationStatus, ServerNotification, ServerNotificationSchema } from '../schemas/notifications.ts'
import { z } from 'zod'

export function getAllIssues(schema: Schema<AppRegistry>): ServerIssue[] {
	const rawIssues = schema.all('issue')
	const serverIssuesArray: ServerIssue[] = []
	rawIssues.models.forEach((issue) => {
		serverIssuesArray.push(ServerIssueSchema.parse(issue.attrs))
	})
	return serverIssuesArray
}

export function getAllIssueTypes(schema: Schema<AppRegistry>): IssueImpactProbabilityWithCount[] {
	const allIssues = getAllIssues(schema)
	return allIssues.reduce((issueTypes, issue, index) => {
		const currentIssueTypeIndex = issueTypes.findIndex(
			(issueType) => issueType.issue_source === issue.issue_source && issueType.issue_name === issue.issue_name,
		)

		if (currentIssueTypeIndex === -1) {
			return [
				...issueTypes,
				IssueImpactProbabilityWithCountSchema.parse({
					id: index.toString(),
					customer_id: '1',
					issue_name: issue.issue_name,
					issue_source: issue.issue_source,
					status_update_enabled: true,
					ignore_issue: false,
					issue_count: 1,
				}),
			]
		}

		issueTypes[currentIssueTypeIndex].issue_count++
		return issueTypes
	}, [] as IssueImpactProbabilityWithCount[])
}

function addAffectedEnvironmentToIdentity(identity: ServerIdentitiesTableRow) {
	if (!identity.affected_environments) {
		identity.affected_environments = {
			[identity.env_type as EnvironmentType]: [
				{
					account_db_id: identity.account_literal,
					account_id: identity.account_literal,
					account_name: identity.account_literal_friendly_name,
				},
			],
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function addTagsWithEnv(identityObj: any, identity: ServerIdentitiesTableRow) {
	// Format: {ResourceType}__{TagName}
	const tagsWithEnv: string[] = []
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	if (identityObj.tags) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		for (const tag of identityObj.tags) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			tagsWithEnv.push(`${tag.related_resource_type}__${tag.name}`)
		}
	}
	identity.tags_with_env = tagsWithEnv
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function preserveOriginalFields(identityObj: any) {
	/* eslint-disable @typescript-eslint/no-unsafe-assignment */
	/* eslint-disable @typescript-eslint/no-unsafe-member-access */
	return {
		salesforce_user: identityObj.salesforce_user,
		salesforce_connected_application: identityObj.salesforce_connected_application,
		aws_iam_user: identityObj.aws_iam_user,
		entra_id_service_principal: identityObj.entra_id_service_principal,
		postgres_role: identityObj.postgres_role,
		entra_id_user: identityObj.entra_id_user,
		aws_iam_role: identityObj.aws_iam_role,
	}
	/* eslint-enable @typescript-eslint/no-unsafe-assignment */
	/* eslint-enable @typescript-eslint/no-unsafe-member-access */
}

export function getAllStandAloneIdentities(schema: Schema<AppRegistry>): ServerIdentitiesTableRow[] {
	/*
	Note:
	As we built mirage on base of Issues, we use issues to get identities.
	But we also want to have identities without issues.
	To signal that an identity has no issues, we add an attribute to it; 'noIssueConnectedToIdentity'
	Then another attribute; 'identity', that will contain the identity object
	*/
	const rawIdentities = schema.all('identity')
	const serverIdentitiesMap: Map<string, ServerIdentitiesTableRow> = new Map()

	rawIdentities.models.forEach((identity) => {
		const identityObj = { ...identity.attrs }

		// @ts-expect-error: identity is not a property identityObj
		if (!identityObj.noIssueConnectedToIdentity || !identityObj.identity) return

		/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call */
		// @ts-expect-error: identity is not a property identityObj, as it can be structured differently
		identityObj.identity.tags_names = identityObj.identity.tags?.map((tag: ServerTag) => tag.name)
		/* eslint-enable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call */

		// @ts-expect-error: identity is not a property identityObj, as it can be structured differently
		const identityParsed = ServerIdentitiesTableRowSchema.parse(identityObj.identity)
		if (!identityParsed) return
		if (!serverIdentitiesMap.has(identityParsed.literal)) {
			identityParsed.max_priority = 1
			// Add back the original fields that were removed when parsing so that we can parse owners
			// @ts-expect-error: identity is not a property identityObj, as it can be structured differently
			Object.assign(identityParsed, preserveOriginalFields(identityObj.identity))
			serverIdentitiesMap.set(identityParsed.literal, identityParsed)
			addAffectedEnvironmentToIdentity(identityParsed)
			findAndAddOwners(identityParsed)
			// Set issue_count to 0 for standalone identities
			identityParsed.issue_count = 0
		}
	})

	return [...serverIdentitiesMap.values()]
}

export function getAllIdentitiesFromIssues(schema: Schema<AppRegistry>): ServerIdentitiesTableRow[] {
	const rawIssues = schema.all('issue')
	const serverIdentitiesMap: Map<string, ServerIdentitiesTableRow> = new Map()

	rawIssues.models.forEach((issue) => {
		// @ts-expect-error: identity is not a property of issue
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const identityObj = { ...issue.attrs?.identity }

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		identityObj.issues = []
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
		identityObj.tags_names = identityObj.tags?.map((tag: ServerTag) => tag.name)
		const identity = ServerIdentitiesTableRowSchema.parse(identityObj)
		const issueParsed = ServerIssueSchema.parse(issue.attrs)
		if (issueParsed.status !== IssueStatus.OPEN && issueParsed.status !== IssueStatus.IN_PROGRESS) return
		if (!identity) return

		if (!serverIdentitiesMap.has(identity.literal)) {
			// Add back the original fields that were removed when parsing so that we can parse owners
			Object.assign(identity, preserveOriginalFields(identityObj))
			serverIdentitiesMap.set(identity.literal, identity)
			addAffectedEnvironmentToIdentity(identity)
			addTagsWithEnv(identityObj, identity)
			findAndAddOwners(identity)
			identity.issue_count = 0
			identity.issues = []
		}

		const currentIdentity = serverIdentitiesMap.get(identity.literal)!
		currentIdentity.issues.push(issueParsed)

		// Only count OPEN or IN_PROGRESS issues
		if (issueParsed.status === IssueStatus.OPEN || issueParsed.status === IssueStatus.IN_PROGRESS) {
			currentIdentity.issue_count = (currentIdentity.issue_count || 0) + 1

			// Calculate max_priority for the identity based on its issues
			// Priority is 1-4 where 4 is highest priority (most severe)
			// For each identity, we want the highest severity (highest number) among all its issues
			currentIdentity.max_priority = Math.max(issueParsed.priority ?? 1, currentIdentity.max_priority ?? 1)
		}
	})

	return [...serverIdentitiesMap.values()]
}

export function getIssueById(schema: Schema<AppRegistry>, id: string): ServerIssue | undefined {
	const rawIssue = schema.findBy('issue', { id })
	if (!rawIssue) return
	return ServerIssueSchema.parse(rawIssue.attrs)
}

export function getIdentityById(schema: Schema<AppRegistry>, id: string): ServerIdentity {
	const rawIdentities = schema.all('identity')
	const serverIdentitiesMap: Map<string, ServerIdentity> = new Map()

	rawIdentities.models.forEach((identity) => {
		const identityObj = { ...identity.attrs }

		// @ts-expect-error: id is not a property identityObj, as it can be structured differently

		if (identityObj.id !== id) return
		// @ts-expect-error: identity is not a property identityObj, as it can be structured differently

		if (!identityObj.noIssueConnectedToIdentity && !identityObj.identity) return

		// @ts-expect-error: identity is not a property identityObj, as it can be structured differently
		const identityParsed = ServerIdentitySchema.parse(identityObj.identity)
		if (!identityParsed) return
		if (!serverIdentitiesMap.has(identityParsed.literal)) {
			serverIdentitiesMap.set(identityParsed.literal, identityParsed)
		}
	})
	return [...serverIdentitiesMap.values()][0]
}

export function getIdentityByIdFromIssues(schema: Schema<AppRegistry>, id: string): ServerIdentity {
	const rawIssues = schema.all('issue')
	const serverIdentitiesMap: Map<string, ServerIdentity> = new Map()

	rawIssues.models.forEach((issue) => {
		// @ts-expect-error: identity is not a property of issue
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const identityObj = issue.attrs?.identity
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		if (identityObj.id !== id) return

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		identityObj.issues = []
		const identity = ServerIdentitySchema.parse(identityObj)
		const issueParsed = ServerIssueSchema.parse(issue.attrs)
		if (issueParsed.status !== IssueStatus.OPEN && issueParsed.status !== IssueStatus.IN_PROGRESS) return
		if (!identity) return
		if (!serverIdentitiesMap.has(identity.literal)) {
			serverIdentitiesMap.set(identity.literal, identity)
		}
		serverIdentitiesMap.get(identity.literal)?.issues!.push(issueParsed)
	})
	return [...serverIdentitiesMap.values()][0]
}

export function getUsageLogsByIdentityId(schema: Schema<AppRegistry>, id: string): ServerIdentityUsageLogs | null {
	const rawIdentity = schema.findBy('identity', { id })

	// Parse the data with partial schema - This allows us to create identities with missing keys (of logs)
	const partialData = ServerIdentityUsageLogsSchema.partial().parse(rawIdentity?.attrs)

	// Fill in any missing keys with empty arrays after parsing
	const usageLogs = Object.fromEntries(
		Object.keys(ServerIdentityUsageLogsSchema.shape).map((key) => [
			key,
			partialData[key as keyof ServerIdentityUsageLogs] ?? [],
		]),
	) as ServerIdentityUsageLogs

	return usageLogs ?? null
}

export function getUsageLogsGroupedByIdentityId(
	schema: Schema<AppRegistry>,
	id: string,
): ServerIdentityUsageLogsGrouped | null {
	const groupedLogs = schema.findBy('usage-logs-grouped', { id })

	const partialData = ServerIdentityUsageLogsGroupedSchema.partial().parse(groupedLogs?.attrs)

	const usageLogs = Object.fromEntries(
		Object.keys(ServerIdentityUsageLogsGroupedSchema.shape).map((key) => [
			key,
			partialData[key as keyof ServerIdentityUsageLogsGrouped] ?? [],
		]),
	) as ServerIdentityUsageLogsGrouped

	return usageLogs ?? null
}

export function getAllNotifications(schema: Schema<AppRegistry>): ServerNotification[] {
	const rawNotifications = schema.all('notification')
	const serverNotificationsArray: ServerNotification[] = []
	rawNotifications.models.forEach((notification) => {
		serverNotificationsArray.push(ServerNotificationSchema.parse(notification.attrs))
	})
	return serverNotificationsArray
}

export function updateNotificationStatus(
	schema: Schema<AppRegistry>,
	id: string,
	status: NotificationStatus,
): ServerNotification {
	// @ts-expect-error: playbook_id is not a property of notification - again, this is a mirage thing
	const notification = schema.findBy('notification', { playbook_id: id })
	if (!notification) {
		throw new Error(`Notification with id ${id} not found`)
	}

	notification.update({ status })
	return ServerNotificationSchema.parse(notification.attrs)
}

// We use this for mirage, but it's not used anywhere else
// as owners are defined as any in ServerIdentitiesTableRowSchema
export const ServerIdentityOwnerSchema = z.object({
	name: z.string(),
	email: z.string(),
})

export type ServerIdentityOwner = z.infer<typeof ServerIdentityOwnerSchema>

function findAndAddOwners(identity: ServerIdentitiesTableRow) {
	if (identity.owners && identity.owners.length > 0) {
		// Set identities_has_owner to a long string with all owner emails
		// In the backend we join on this field's table to get all owners
		// This is a workaround to get the owners to show up in the table
		identity.identities_has_owner = identity.owners.join(', ')
		return
	}

	const owners = findExistingOwners(identity)
	if (owners.length > 0) {
		identity.owners = owners.map((owner) => owner.email)
		// Set identities_has_owner to a long string with all owner emails
		// In the backend we join on this field's table to get all owners
		// This is a workaround to get the owners to show up in the table
		identity.identities_has_owner = identity.owners.join(', ')
	}
}

export function findExistingOwners(identity: ServerIdentity): ServerIdentityOwner[] {
	// This entire thing is a mess, but it's a mirage thing, so we can't do much about it
	// Its the way we structured our code, and how we relay on owners being in the identity object
	// while in reality its a separate table that we join on getting all identities
	const owners: ServerIdentityOwner[] = []
	/* eslint-disable @typescript-eslint/no-unsafe-assignment */
	/* eslint-disable @typescript-eslint/no-unsafe-member-access */
	/* eslint-disable @typescript-eslint/no-unsafe-call */
	/* eslint-disable @typescript-eslint/no-explicit-any */

	// Declare variables outside switch
	let awsLogs: any[] = []
	let entraLogs: any[] = []

	switch (identity.source) {
		case IdentitySource.SALESFORCE_USER:
		case IdentitySource.SALESFORCE_CONNECTED_APPLICATION:
			if (identity.salesforce_user?.ownership_records) {
				identity.salesforce_user.ownership_records.forEach((record: any) => {
					if (record.owner) {
						owners.push({
							name: `${record.owner.first_name} ${record.owner.last_name}`,
							email: record.owner.email,
						})
					}
				})
			}
			break

		case IdentitySource.AWS_IAM_USER:
		case IdentitySource.AWS_IAM_ROLE:
			awsLogs = identity.aws_iam_user?.change_logs ?? identity.aws_iam_role?.change_logs ?? []
			if (awsLogs) {
				awsLogs.forEach((log: any) => {
					if (log.actor_arn) {
						owners.push({
							name: log.actor_arn.split('/').pop() || log.actor_arn,
							email: `${log.actor_arn.split('/').pop()}@example.com`,
						})
					}
				})
			}
			break

		case IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL:
		case IdentitySource.ENTRA_ID_USER:
			entraLogs = identity.entra_id_service_principal?.change_logs ?? identity.entra_id_user?.change_logs ?? []
			if (entraLogs) {
				entraLogs.forEach((log: any) => {
					if (log.actor_principal_name) {
						owners.push({
							name: log.actor_principal_name,
							email: log.actor_principal_name,
						})
					}
				})
			}
			break

		case IdentitySource.POSTGRES_ROLE:
			// @ts-expect-error: postgres_role is preserved but not in type
			if (identity.postgres_role?.owner) {
				owners.push({
					// @ts-expect-error: postgres_role is preserved but not in type
					name: identity.postgres_role.owner,
					// @ts-expect-error: postgres_role is preserved but not in type
					email: identity.postgres_role.owner,
				})
			}
			break
	}
	/* eslint-enable @typescript-eslint/no-unsafe-assignment */
	/* eslint-enable @typescript-eslint/no-unsafe-member-access */
	/* eslint-enable @typescript-eslint/no-unsafe-call */
	/* eslint-enable @typescript-eslint/no-explicit-any */
	return [...new Set(owners.map((o) => JSON.stringify(o)))].map((o) => JSON.parse(o) as ServerIdentityOwner)
}
