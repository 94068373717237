import React, { createContext, ReactNode, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'

export interface IdentitiesContextState {
	gridRef: React.RefObject<AgGridReact>
	gridReady: boolean
	setGridReady: React.Dispatch<React.SetStateAction<boolean>>
	searchText: string
	setSearchText: React.Dispatch<React.SetStateAction<string>>
}

export const IdentitiesContext = createContext<IdentitiesContextState | null>(null)

interface ProviderProps {
	children: ReactNode
}

export const IdentitiesContextProvider: React.FC<ProviderProps> = ({ children }) => {
	const gridRef = useRef<AgGridReact>(null)
	const [gridReady, setGridReady] = useState<boolean>(false)
	const [searchText, setSearchText] = useState<string>('')
	const value: IdentitiesContextState = {
		gridRef,
		gridReady,
		setGridReady,
		searchText,
		setSearchText,
	}

	return <IdentitiesContext.Provider value={value}>{children}</IdentitiesContext.Provider>
}
