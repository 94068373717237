import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ReactNode } from 'react'

type FilterChipProps = {
	label: string
	children?: ReactNode
	onRemove?: () => void
}

export const FilterChip = ({ label, children, onRemove }: FilterChipProps) => (
	<div className="flex items-center gap-1 py-2 pl-2 pr-1 h-8 rounded border border-neutral-200 bg-white text-xs max-w-96">
		<div className="text-gray-500 whitespace-nowrap px-1 shrink-0">{label}</div>
		<div className="grow truncate">{children}</div>
		{!!onRemove && (
			<Button
				type="text"
				size="small"
				className="shrink-0"
				icon={<CloseOutlined className="text-gray-500" />}
				onClick={onRemove}
			/>
		)}
	</div>
)
