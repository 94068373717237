import { Segmented } from 'antd'
import { ControlButton } from '@xyflow/react'
import { IdentityGraphViewTypeEnum } from './identityGraphTypes.ts'
import Icon, { ExclamationCircleOutlined } from '@ant-design/icons'
import UsageIcon from '../../../assets/Usage.svg?react'
import cx from 'classnames'

type GraphViewControlButtonProps = {
	viewType: IdentityGraphViewTypeEnum
	onViewTypeClicked: (viewType: IdentityGraphViewTypeEnum) => void
	className: string
}

const styles = {
	selectedSegment: '!border !border-indigo-500',
}

export const GraphViewControlButton = ({ viewType, onViewTypeClicked, className }: GraphViewControlButtonProps) => {
	return (
		<Segmented
			className={cx(className, '!h-14 hover:!bg-gray-50')}
			vertical
			value={viewType}
			options={[
				{
					value: IdentityGraphViewTypeEnum.USAGE,
					label: (
						<ControlButton
							onClick={() => onViewTypeClicked(IdentityGraphViewTypeEnum.USAGE)}
							className={cx(
								className,
								'!w-6 !h-6 !text-white !-mb-0.5',
								viewType === IdentityGraphViewTypeEnum.USAGE && styles.selectedSegment,
							)}
						>
							<Icon component={UsageIcon} />
						</ControlButton>
					),
				},
				{
					value: IdentityGraphViewTypeEnum.STATIC,
					label: (
						<ControlButton
							onClick={() => onViewTypeClicked(IdentityGraphViewTypeEnum.STATIC)}
							className={cx(
								className,
								'!w-6 !h-6 !text-black !-mt-0.5',
								viewType === IdentityGraphViewTypeEnum.STATIC && styles.selectedSegment,
							)}
						>
							<ExclamationCircleOutlined style={{ fill: 'black' }} />
						</ControlButton>
					),
				},
			]}
		/>
	)
}
// <ControlButton onClick={item.onClick} className={styles.controlButton}>
