import React from 'react'
import { ActionTypeEnum } from '../../schemas/identities/usageActionItemSchema'
import ActivityLogoIcon from '../../assets/activity.svg?react'
import LegoLogoIcon from '../../assets/lego.svg?react'
import NutLogoIcon from '../../assets/nut.svg?react'
import StarLogoIcon from '../../assets/star.svg?react'
import BoxLogoIcon from '../../assets/box.svg?react'

export const UsageActionIcon: React.FunctionComponent<{
	name?: string | null
	color?: string
	size?: number
	style?: React.CSSProperties
}> = ({ name, color = '#E4750D', size = 28, style }) => {
	let IconComponent
	switch (name) {
		case ActionTypeEnum.EC2:
			IconComponent = LegoLogoIcon
			break
		case ActionTypeEnum.S3:
			IconComponent = BoxLogoIcon
			break
		case ActionTypeEnum.SQS:
			IconComponent = StarLogoIcon
			break
		case ActionTypeEnum.STS:
			IconComponent = NutLogoIcon
			break

		default:
			IconComponent = ActivityLogoIcon
	}

	return <IconComponent style={{ color, width: size, height: size, ...style }} />
}
