import { z } from 'zod'
import { ServerAdAceSchema } from './adAceSchema'
import { TagSchema } from '../../tags.ts'

export const ServerAdGroupSchema = z.object({
	sam_account_name: z.string(),
	object_sid: z.string(),
	description: z.string().nullish(),
	outbound_permissions: z.array(ServerAdAceSchema),
	tags: z.array(TagSchema).nullish(),
})

export type ServerAdGroup = z.infer<typeof ServerAdGroupSchema>
