import cx from 'classnames'
import { useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { IdentityFilterKeys, inventoryUrlQuickSearchFilterParameter } from '../../../schemas/identity.ts'
import { OverflowingFilters } from './OverflowingFilters.tsx'
import { InventoryFilterChip } from './InventoryFilterChip.tsx'

import { useEntitlementToInventoryFilterChips } from '../../../services/auth/featureFlags.ts'

// This component renders filter chips into the filter bar, and handles overflow of filter chips. When the filter chips
//  overflow their container, they are rendered inside a popover instead of in the filter bar.
export const FilterView = () => {
	const { identityFilter } = useSearch({ from: RoutePaths.Identities })
	const { isEntitled: isEntitledToInventoryFilterChips } = useEntitlementToInventoryFilterChips()
	const measurementContainerRef = useRef<HTMLDivElement>(null)
	const [visibleChips, setVisibleChips] = useState<JSX.Element[]>([])
	const [overflowChips, setOverflowChips] = useState<JSX.Element[]>([])

	const filterEntries = useMemo(() => {
		const newFilterEntries = { ...identityFilter }
		// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
		delete newFilterEntries[inventoryUrlQuickSearchFilterParameter]
		return newFilterEntries
	}, [identityFilter])

	// These are all the chips that will be rendered into an invisible container to measure which of them overflow
	const filterChipsToMeasure = useMemo(
		() =>
			Object.entries(filterEntries).map(([field, fieldFilter]) => (
				<InventoryFilterChip
					key={field as IdentityFilterKeys}
					field={field as IdentityFilterKeys}
					filter={fieldFilter}
				/>
			)),
		[filterEntries],
	)

	// This will run every time the filters change or when the chips container is resized, and will re-calculate which
	//  chips are overflowing.
	const calculateOverflowingChips = () => {
		if (!measurementContainerRef.current?.childNodes?.length) {
			setOverflowChips([])
			setVisibleChips([])
			return
		}

		const containerWidth = measurementContainerRef.current.clientWidth
		let accumulatedWidth = 0
		let firstOverflowIndex = NaN
		const childNodes = measurementContainerRef.current.childNodes
		for (let i = 0; i < childNodes.length; i++) {
			const child = childNodes[i] as HTMLElement
			const childWidth = child.offsetWidth + 4 // Adding the gap to the size calculation of children
			accumulatedWidth += childWidth
			if (accumulatedWidth > containerWidth) {
				firstOverflowIndex = i
				break
			}
		}

		if (isNaN(firstOverflowIndex)) {
			setVisibleChips([...filterChipsToMeasure])
			setOverflowChips([])
		} else {
			setVisibleChips([...filterChipsToMeasure].slice(0, firstOverflowIndex))
			setOverflowChips([...filterChipsToMeasure].slice(firstOverflowIndex))
		}
	}

	useEffect(() => {
		calculateOverflowingChips()
		const resizeObserver = new ResizeObserver(() => {
			calculateOverflowingChips()
		})

		if (measurementContainerRef.current) {
			resizeObserver.observe(measurementContainerRef.current)
		}

		return () => {
			resizeObserver.disconnect()
		}
	}, [filterChipsToMeasure, isEntitledToInventoryFilterChips])

	if (!isEntitledToInventoryFilterChips) {
		return null
	}

	if (!filterChipsToMeasure.length) {
		return null
	}

	const filterBarClassNames = 'flex items-center flex-nowrap gap-1 overflow-hidden'
	const measurementContainerClassNames = cx(filterBarClassNames, 'absolute top-0 left-0 right-0 bottom-0 invisible')

	return (
		<div className="flex gap-2 items-center">
			<div className="relative w-[calc(100%-32px)]">
				<div ref={measurementContainerRef} className={measurementContainerClassNames}>
					{filterChipsToMeasure}
				</div>
				<div className={filterBarClassNames}>{visibleChips}</div>
			</div>
			<div className="w-[32px]">
				<OverflowingFilters>{overflowChips}</OverflowingFilters>
			</div>
		</div>
	)
}
