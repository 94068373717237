import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { ServerGcpRole } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons.tsx'

export type GcpRoleNodeType = Node<
	{
		role: ServerGcpRole
	},
	'gcpRole'
>

export const GcpRoleNode = ({ data }: NodeProps<GcpRoleNodeType>) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)

	const segments = data.role.name.split('/')
	const displayName = segments[segments.length - 1]
	const hasTags = data.role.tags && data.role.tags.length > 0

	return (
		<BaseNode label="GCP Role" name={{ text: displayName }}>
			{hasTags && <RiskNodeTagIcons tags={data.role.tags} />}
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
