import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { ServerAdAce } from '../../schemas/identities/activeDirectory/adAceSchema'
import { TagIcons } from '../../components/common/TagIcons'
import { ServerTag, Tag, TagsDisplayNameMap } from '../../schemas/tags'

interface AdACEsTableProps {
	aces: ServerAdAce[]
}

export const AdACEsTable: React.FC<AdACEsTableProps> = ({ aces }) => {
	const gridRef = useRef<AgGridReact<ServerAdAce>>(null)

	const onGridReady = useCallback((_params: GridReadyEvent<ServerAdAce>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<ServerAdAce>[]>(
		() => [
			{
				field: 'right_name',
				headerName: 'Right Name',
				flex: 1,
			},
			{
				field: 'is_inherited',
				headerName: 'Is Inherited',
				flex: 1,
				cellRenderer: (params: { value: boolean }) => (params.value ? 'Yes' : 'No'),
			},
			{
				field: 'target_principal_sid',
				headerName: 'Target Principal SID',
				flex: 1,
			},
			{
				field: 'target_principal_name',
				headerName: 'Target Principal Name',
				flex: 1,
			},
			{
				field: 'target_principal_type',
				headerName: 'Target Principal Type',
				flex: 1,
			},
			{
				headerName: 'Risks',
				cellRenderer: (params: { data?: ServerAdAce }) => {
					return <TagIcons tags={params.data?.tags?.map(({ name }) => ({ name: name as Tag }))} />
				},
				valueGetter: (params: { data?: ServerAdAce }) => {
					return params.data?.tags?.map((tag: ServerTag): string => TagsDisplayNameMap[tag.name as Tag])
				},
			},
		],
		[],
	)

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: true,
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
			},
		}),
		[],
	)

	return (
		<div className="w-full">
			<AgGridReact
				ref={gridRef}
				rowData={aces}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				className="ag-theme-alpine"
				rowHeight={40}
			/>
		</div>
	)
}
