import { Dispatch, SetStateAction } from 'react'
import { Tooltip } from 'antd'
import { ControlButton } from '@xyflow/react'
import cx from 'classnames'
import { useUsageGraph } from '../../../services/auth/featureFlags.ts'
import { isDemo } from '../../../utils/demoUtils.ts'

type CompactModeControlButtonProps = {
	isCompact: boolean
	setIsCompact: Dispatch<SetStateAction<boolean>>
}

export const CompactModeControlButton = ({ isCompact, setIsCompact }: CompactModeControlButtonProps) => {
	const { isEntitled: isEntitledToUsageGraph } = useUsageGraph()

	const buttonClassName =
		isEntitledToUsageGraph && isDemo
			? cx(
					'!border !rounded-sm !border-solid !border-gray-100 !p-0 !m-0 hover:!bg-gray-100 !w-8 !h-8',
					isCompact ? '!bg-blue-300' : '!bg-gray-50',
				)
			: isCompact
				? '!bg-blue-300'
				: undefined

	return (
		<Tooltip title={`${isCompact ? 'Disable' : 'Enable'} compact mode`}>
			<ControlButton
				onClick={() => {
					setIsCompact((currentIsCompact) => !currentIsCompact)
				}}
				className={buttonClassName}
			>
				<span>C</span>
			</ControlButton>
		</Tooltip>
	)
}
