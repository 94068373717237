import { Tooltip } from 'antd'
import { IssuesTabName, PriorityType } from '../../schemas/issue.ts'
import { IdentityTypeTag } from '../../components/common/IdentityTypeTag.tsx'
import { ServerMostWantedIdentities as MostWantedIdentitiesType } from '../../schemas/metrics/mostWantedIdentities.ts'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket.ts'
import { EntitiesList } from './EntitiesList.tsx'
import { useMostWantedIdentities } from '../../api/metrics.ts'
import { MetricsChartContainer } from './MetricsChartContainer.tsx'
import { RoutePaths } from '../RoutePaths.tsx'
import { LinkProps } from '@tanstack/react-router'
import { SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES } from '../../schemas/identity.ts'

type MostWantedLinkResolver = (identity: MostWantedIdentitiesType[number]) => LinkProps

const linkResolverToFindings: MostWantedLinkResolver = (identity) => {
	const issueFilter = {
		'identity.literal': {
			filter: identity.friendly_name ?? identity.name,
			filterType: 'text',
			type: 'equals',
		},
		'identity.account_literal': {
			values: [
				identity.account_literal_friendly_name
					? `${identity.env_type} - ${identity.account_literal_friendly_name} (${identity.account_literal})`
					: `${identity.env_type} - ${identity.account_literal}`,
			],
			filterType: 'set',
		},
	}
	return {
		to: RoutePaths.Findings,
		search: { mostWanted: true, tabName: IssuesTabName.OPEN, issueFilter },
	}
}

const linkResolverToIdentityPage: MostWantedLinkResolver = (identity) => ({
	to: RoutePaths.Identity,
	params: { identityId: identity.id },
})

export const MostWantedIdentities = () => {
	const { data: mostWantedIdentities } = useMostWantedIdentities()

	const onMostWantedIdentityClick = (wantedIdentity: MostWantedIdentitiesType[0]) => {
		trackEvent(LogRocketTrackEvent.MostWantedIdentityFromMetricsClicked, {
			type: wantedIdentity.type,
			maxPriority: wantedIdentity.max_priority,
			count: wantedIdentity.count,
		})
	}

	return (
		<MetricsChartContainer heightSize="full" header="Top 5 Risky Identities" childrenAreList>
			<EntitiesList
				entities={mostWantedIdentities}
				linkResolver={(identity) => {
					if (SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES.includes(identity.source)) {
						return linkResolverToIdentityPage(identity)
					}

					return linkResolverToFindings(identity)
				}}
				priorityTypeResolver={(identity) => PriorityType[identity.max_priority]}
				keyResolver={(identity) => identity.id}
				onClickEntity={onMostWantedIdentityClick}
			>
				{(identity) => (
					<>
						<div className="flex grow items-center truncate">
							<Tooltip placement="topLeft" title={identity.name}>
								<div className="truncate">{identity.friendly_name ?? identity.name}</div>
							</Tooltip>
						</div>

						<div className="flex">
							<IdentityTypeTag type={identity.type} />
						</div>
					</>
				)}
			</EntitiesList>
		</MetricsChartContainer>
	)
}
