import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { api } from './api.ts'
import {
	ServerIntegration,
	ServerIntegrationSchema,
	ServerIntegrationTicketInTokenDb,
	ServerIntegrationTicketInTokenDbSchema,
} from '../jiraIntegration/schemas/common.ts'
import {
	CollaborationsIntegrationTestConnectionRequest,
	IntegrationTestConnectionRequest,
	IntegrationTestConnectionResponse,
	IntegrationTestConnectionResponseSchema,
	ServerCollaborationsIntegration,
	ServerCollaborationIntegrationSchema,
} from '../schemas/integrations/integrations.ts'
import { JiraTicket } from '../jiraIntegration/schemas/jiraSchemas.ts'

export const integrationsApi = {
	async getIntegrations(): Promise<ServerIntegration[]> {
		const { data: integrations } = await api.get<ServerIntegration[]>('/api/integrations')
		return integrations.map((integration) => ServerIntegrationSchema.parse(integration))
	},
	// TODO: This is a temporary endpoint for the new integrations page, we should remove this once we have a unified endpoint
	// that will fetch integrations from their respective tables and not the integrations table
	async getCollaborationIntegrations(): Promise<ServerCollaborationsIntegration[]> {
		const { data: integrations } = await api.get<ServerCollaborationsIntegration[]>(
			'/api/integrations/get-all-collaboration-integrations',
		)
		return integrations.map((integration) => ServerCollaborationIntegrationSchema.parse(integration))
	},
	async createIntegrationTicketInTokenDb(
		issueId: string,
		integrationId: string,
		ticketMetadata: JiraTicket,
	): Promise<ServerIntegrationTicketInTokenDb> {
		const { data } = await api.post<ServerIntegrationTicketInTokenDb>(`/api/integrations/ticket`, {
			issueId,
			ticket: ticketMetadata,
			integrationId,
		})
		return ServerIntegrationTicketInTokenDbSchema.parse(data)
	},
	async getTickets(integrationId: string, issueId: string): Promise<ServerIntegrationTicketInTokenDb[]> {
		const { data } = await api.get<ServerIntegrationTicketInTokenDb[]>(
			`/api/integrations/${integrationId}/${issueId}`,
		)
		return data.map((ticket) => ServerIntegrationTicketInTokenDbSchema.parse(ticket))
	},
	async getSlackCallBackUrl(userAccessToken: string | undefined): Promise<string> {
		if (!userAccessToken) {
			throw new Error('User access token is required')
		}
		const { data } = await api.get<{ url: string }>(
			`/api/integrations/slack-url-builder?current_user_token=${userAccessToken}`,
		)
		return data.url
	},
	async testConnection(request: IntegrationTestConnectionRequest): Promise<IntegrationTestConnectionResponse> {
		const { data } = await api.post<IntegrationTestConnectionResponse>('/api/environments/test-connection', request)
		return IntegrationTestConnectionResponseSchema.parse(data)
	},
	async collaborationIntegrationTestConnection(
		request: CollaborationsIntegrationTestConnectionRequest,
	): Promise<IntegrationTestConnectionResponse> {
		const { data } = await api.post<CollaborationsIntegrationTestConnectionRequest>(
			'/api/integrations/test-collaboration-integration-connection',
			request,
		)
		return IntegrationTestConnectionResponseSchema.parse(data)
	},
}

export function useIntegrations() {
	return useQuery({
		queryKey: ['integrations', 'definitions'],
		queryFn: () => integrationsApi.getIntegrations(),
	})
}

export function useCollaborationIntegrations() {
	return useQuery({
		queryKey: ['integrationsV2'], // TODO: Remove this once we have a unified endpoint for all integrations
		queryFn: () => integrationsApi.getCollaborationIntegrations(),
	})
}

export function useSlackUrlBuilder(userAccessToken: string | undefined) {
	return useQuery({
		queryKey: ['integrations', 'slack-url'],
		queryFn: () => integrationsApi.getSlackCallBackUrl(userAccessToken),
	})
}

export function useTestConnection() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: async (request: IntegrationTestConnectionRequest) => {
			return await integrationsApi.testConnection(request)
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: ['integrations'] })
		},
	})
}

export function useCollaborationTestConnection() {
	return useMutation({
		mutationFn: async (request: CollaborationsIntegrationTestConnectionRequest) => {
			return await integrationsApi.collaborationIntegrationTestConnection(request)
		},
	})
}
