import { Skeleton } from 'antd'
import { FronteggUserAvatar } from '../../components/common/FronteggUserAvatar'

import { useFronteggUsers } from '../../services/auth/fronteggApi.ts'

export const FindingsAssigneeCell: React.FC<{ assigneeUuid?: string; compact?: boolean }> = ({
	assigneeUuid,
	compact,
}) => {
	const { data: fronteggUsersData, isLoading: isFronteggUsersLoading } = useFronteggUsers()

	if (isFronteggUsersLoading || !fronteggUsersData) {
		return <Skeleton className="m-2" loading={isFronteggUsersLoading} active />
	}
	if (!assigneeUuid) {
		return 'Unassigned'
	}
	if (assigneeUuid === '(Select All)') {
		return assigneeUuid
	}
	return <FronteggUserAvatar user={fronteggUsersData[assigneeUuid]} compact={compact} />
}
