import { IdentityNodeType } from './common/IdentityNode.tsx'
import { IssueNodeType } from './common/IssueNode.tsx'
import { AccessKeyNodeType } from './aws/AccessKeyNode.tsx'
import { GcpAccessKeyNodeType } from './gcp/GcpSaAccessKeyNode.tsx'
import { AwsPolicyNodeType } from './aws/AwsPolicyNode.tsx'
import { Ec2InstancesNodeType } from './aws/Ec2InstancesNode.tsx'
import { KubernetesResourcesNodeType } from './common/KubernetesResourcesNode.tsx'
import { OktaUserNodeType } from './okta/OktaUserNode.tsx'
import { GcpProjectNodeType } from './gcp/GcpProjectNode.tsx'
import { GcpRoleNodeType } from './gcp/GcpRoleNode.tsx'
import { GcpProjectsNodeType } from './gcp/GcpProjectsNode.tsx'
import { GithubRepositoryNodeType } from './github/GithubRepositoryNode.tsx'
import { GithubRepositoriesNodeType } from './github/GithubRepositoriesNode.tsx'
import { GithubUserTokenNodeType } from './github/GithubUserTokenNode.tsx'
import { EntraIdSpAuthCredentialNodeType } from './entraId/EntraIdSpAuthCredentialNode.tsx'
import { DatabricksOAuthNodeType } from './databricks/DatabricksOAuthNode.tsx'
import { DatabricksPATNodeType } from './databricks/DatabricksPATNode.tsx'
import { DatabricksRoleNodeType } from './databricks/DatabricksRoleNode.tsx'
import { DemoAzureVirtualMachinesNodeType } from './azure/demo/DemoAzureVirtualMachinesNode.tsx'
import { EnrichedAzureRoleNodeType } from './azure/azureRBAC/EnrichedAzureRoleNode.tsx'
import { EnrichedAzureRolesNodeType } from './azure/azureRBAC/EnrichedAzureRolesNode.tsx'
import { AzureSubscriptionNodeType } from './azure/azureResource/AzureSubscriptionNode.tsx'
import { AzureSubscriptionsNodeType } from './azure/azureResource/AzureSubscriptionsNode.tsx'
import { AzureManagementGroupNodeType } from './azure/azureResource/AzureManagementGroupNode.tsx'
import { AzureManagementGroupsNodeType } from './azure/azureResource/AzureManagementGroupsNode.tsx'
import { AzureKeyVaultNodeType } from './azure/azureKV/AzureKeyVaultNode.tsx'
import { EntraIDUserNodeType } from './entraId/EntraIDUserNode.tsx'
import { EntraIDUsersNodeType } from './entraId/EntraIDUsersNode.tsx'
import { EntraIDServicePrincipalNodeType } from './entraId/EntraIDServicePrincipalNode.tsx'
import { EntraIDServicePrincipalsNodeType } from './entraId/EntraIDServicePrincipalsNode.tsx'
import { DetailedEntraIdRoleNodeType } from './entraId/entraIdRbac/DetailedEntraIdRoleNode.tsx'
import { DetailedEntraIdRolesNodeType } from './entraId/entraIdRbac/DetailedEntraIdRolesNode.tsx'
import { AwsRoleNodeType } from './aws/AwsRoleNode.tsx'
import { AwsAccountNodeType } from './aws/AwsAccountNode.tsx'
import { Node as NodeType } from '@xyflow/react'
import { AwsRoleNodesType } from './aws/AwsRolesNode.tsx'
import { SnowflakeRoleNodeType } from './snowflake/SnowflakeRoleNode.tsx'
import { SnowflakeRolesNodeType } from './snowflake/SnowflakeRolesNode.tsx'
import { OwnershipNodeType } from './common/OwnershipNode.tsx'
import { GithubAppPermissionNodeType } from './github/GithubAppPermissionNode.tsx'
import { SalesforceProfileNodeType } from './salesforce/SalesforceProfileNode.tsx'
import { SalesforcePermissionSetNodeType } from './salesforce/SalesforcePermissionSetNode.tsx'
import { AwsPoliciesNodeType } from './aws/AwsPoliciesNode.tsx'
import { GcpRolesNodeType } from './gcp/GcpRolesNode.tsx'
import { SalesforcePermissionSetsNodeType } from './salesforce/SalesforcePermissionSetsNode.tsx'
import { SalesforceConnectedAppMetadataNodeType } from './salesforce/SalesforceConnectedAppOAuthNode.tsx'
import { JumpcloudUserNodeType } from './jumpcloud/JumpcloudUserNode.tsx'
import { SalesforceConsumerKeyNodeType } from './salesforce/SalesforceCosumerKeyNode.tsx'
import { AzureDevopsPatNodeType } from './azure/azureDevops/AzureDevopsPatNode.tsx'
import { FederationNodeType } from './common/FederationNode.tsx'
import { AdGroupNodeType } from './activeDirectory/AdGroupNode.tsx'
import { DemoAdEndpointsNodeType } from './activeDirectory/DemoAdEndpointsNode.tsx'
import { Ec2InstanceKeyPairNodeType } from './aws/Ec2InstanceKeyPairNode.tsx'
import { DemoAdServersNodeType } from './activeDirectory/DemoAdServersNode.tsx'
import { ConnectedGcpProjectNodeType } from './gcp/ConnectedGcpProjectNode.tsx'
import { ConnectedGcpRoleNodeType } from './gcp/ConnectedGcpRoleNode.tsx'
import { ConnectedGcpRolesNodeType } from './gcp/ConnectedGcpRolesNode.tsx'
import { ConnectedGcpProjectsNodeType } from './gcp/ConntectedGcpProjectsNode.tsx'
import { AssociationNodeType } from './common/baseNodes/BaseAssociationNode.tsx'
import { ActionNodeType } from './common/ActionNode.tsx'
import { AdGroupsNodeType } from './activeDirectory/AgGroupsNode.tsx'

/** A representation of a node without a position */
export type BareNodeType<TNodeType extends NodeType = NodeType> = Omit<TNodeType, 'position'>

export type IdentityGraphNodeType =
	| IdentityNodeType
	| FederationNodeType
	| IssueNodeType
	| AccessKeyNodeType
	| AwsPolicyNodeType
	| AwsPoliciesNodeType
	| Ec2InstancesNodeType
	| KubernetesResourcesNodeType
	| OktaUserNodeType
	| GcpProjectNodeType
	| GcpRoleNodeType
	| GcpRolesNodeType
	| GcpProjectsNodeType
	| ConnectedGcpProjectNodeType
	| ConnectedGcpRoleNodeType
	| ConnectedGcpRolesNodeType
	| ConnectedGcpProjectsNodeType
	| GithubAppPermissionNodeType
	| GithubRepositoryNodeType
	| GithubRepositoriesNodeType
	| GithubUserTokenNodeType
	| GcpAccessKeyNodeType
	| EntraIdSpAuthCredentialNodeType
	| DatabricksOAuthNodeType
	| DatabricksPATNodeType
	| DatabricksRoleNodeType
	| DemoAzureVirtualMachinesNodeType
	| EnrichedAzureRoleNodeType
	| EnrichedAzureRolesNodeType
	| AzureSubscriptionNodeType
	| AzureSubscriptionsNodeType
	| AzureManagementGroupNodeType
	| AzureManagementGroupsNodeType
	| AzureKeyVaultNodeType
	| EntraIDUserNodeType
	| EntraIDUsersNodeType
	| EntraIDServicePrincipalNodeType
	| EntraIDServicePrincipalsNodeType
	| DetailedEntraIdRoleNodeType
	| DetailedEntraIdRolesNodeType
	| AwsRoleNodeType
	| AwsRoleNodesType
	| AwsAccountNodeType
	| SnowflakeRoleNodeType
	| SnowflakeRolesNodeType
	| OwnershipNodeType
	| SalesforceProfileNodeType
	| SalesforcePermissionSetNodeType
	| SalesforcePermissionSetsNodeType
	| SalesforceConnectedAppMetadataNodeType
	| SalesforceConsumerKeyNodeType
	| JumpcloudUserNodeType
	| AzureDevopsPatNodeType
	| AdGroupNodeType
	| AdGroupsNodeType
	| Ec2InstanceKeyPairNodeType
	| DemoAdEndpointsNodeType
	| DemoAdServersNodeType
	| AssociationNodeType
	| ActionNodeType

export type BareIdentityGraphNodeType =
	| BareNodeType<IdentityNodeType>
	| BareNodeType<FederationNodeType>
	| BareNodeType<IssueNodeType>
	| BareNodeType<AccessKeyNodeType>
	| BareNodeType<GcpAccessKeyNodeType>
	| BareNodeType<AwsPolicyNodeType>
	| BareNodeType<AwsPoliciesNodeType>
	| BareNodeType<Ec2InstancesNodeType>
	| BareNodeType<KubernetesResourcesNodeType>
	| BareNodeType<OktaUserNodeType>
	| BareNodeType<GcpProjectNodeType>
	| BareNodeType<GcpRoleNodeType>
	| BareNodeType<GcpRolesNodeType>
	| BareNodeType<GcpProjectsNodeType>
	| BareNodeType<ConnectedGcpProjectNodeType>
	| BareNodeType<ConnectedGcpProjectsNodeType>
	| BareNodeType<ConnectedGcpRoleNodeType>
	| BareNodeType<ConnectedGcpRolesNodeType>
	| BareNodeType<GithubAppPermissionNodeType>
	| BareNodeType<GithubRepositoryNodeType>
	| BareNodeType<GithubRepositoriesNodeType>
	| BareNodeType<GithubUserTokenNodeType>
	| BareNodeType<EntraIdSpAuthCredentialNodeType>
	| BareNodeType<DatabricksOAuthNodeType>
	| BareNodeType<DatabricksPATNodeType>
	| BareNodeType<DatabricksRoleNodeType>
	| BareNodeType<DemoAzureVirtualMachinesNodeType>
	| BareNodeType<EnrichedAzureRoleNodeType>
	| BareNodeType<EnrichedAzureRolesNodeType>
	| BareNodeType<AzureSubscriptionNodeType>
	| BareNodeType<AzureSubscriptionsNodeType>
	| BareNodeType<AzureManagementGroupNodeType>
	| BareNodeType<AzureManagementGroupsNodeType>
	| BareNodeType<AzureKeyVaultNodeType>
	| BareNodeType<EntraIDUserNodeType>
	| BareNodeType<EntraIDUsersNodeType>
	| BareNodeType<EntraIDServicePrincipalNodeType>
	| BareNodeType<EntraIDServicePrincipalsNodeType>
	| BareNodeType<DetailedEntraIdRoleNodeType>
	| BareNodeType<DetailedEntraIdRolesNodeType>
	| BareNodeType<AwsRoleNodeType>
	| BareNodeType<AwsRoleNodesType>
	| BareNodeType<AwsAccountNodeType>
	| BareNodeType<SnowflakeRoleNodeType>
	| BareNodeType<SnowflakeRolesNodeType>
	| BareNodeType<OwnershipNodeType>
	| BareNodeType<SalesforceProfileNodeType>
	| BareNodeType<SalesforcePermissionSetNodeType>
	| BareNodeType<SalesforcePermissionSetsNodeType>
	| BareNodeType<SalesforceConnectedAppMetadataNodeType>
	| BareNodeType<SalesforceConsumerKeyNodeType>
	| BareNodeType<JumpcloudUserNodeType>
	| BareNodeType<AzureDevopsPatNodeType>
	| BareNodeType<AdGroupNodeType>
	| BareNodeType<AdGroupsNodeType>
	| BareNodeType<DemoAdEndpointsNodeType>
	| BareNodeType<Ec2InstanceKeyPairNodeType>
	| BareNodeType<DemoAdServersNodeType>
	| BareNodeType<AssociationNodeType>
	| BareNodeType<ActionNodeType>

export type BareNodesColumnsType = {
	yPosition: 'top' | 'center'
	nodes: BareIdentityGraphNodeType[]
}

export enum IdentityGraphViewTypeEnum {
	STATIC = 'static',
	USAGE = 'usage',
}
