import { uniq } from 'lodash'
import { Tag } from '../../schemas/tags.ts'
import { IdentityTagIcon } from './IdentityTag/IdentityTagIcon.tsx'
import { ResourceType } from '../../schemas/issue.ts'

type TagIconsProps = {
	tags?: Array<{ name: Tag; resourceType?: ResourceType }> | null
}

export const TagIcons = ({ tags }: TagIconsProps) => {
	if (!tags?.length) {
		return null
	}

	const tagNameToResourceTypes = tags.reduce(
		(acc, tag) => {
			if (!(tag.name in acc)) {
				acc[tag.name] = []
			}
			if (tag.resourceType) {
				acc[tag.name].push(tag.resourceType)
			}

			return acc
		},
		{} as Record<Tag, Array<ResourceType>>,
	)

	return (
		<div className="flex items-center gap-1">
			{Object.keys(tagNameToResourceTypes)
				.sort()
				.map((tag) => (
					<IdentityTagIcon
						key={tag}
						name={tag as Tag}
						resourceTypes={uniq(tagNameToResourceTypes[tag as Tag])}
						size={24}
					/>
				))}
		</div>
	)
}
