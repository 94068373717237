/* eslint-disable camelcase */

import EntraIdIcon from '../../../../assets/entra_id_icon_16.svg?react'
import AzureIcon from '../../../../assets/azure_icon.svg?react'
import EntraIdGuide from '../../../../assets/integrationGuides/entra-id-azure-integration-guide.pdf'
import { EnvironmentCreateRequest, ServerEnvironments } from '../../../../schemas/environments/environments'
import { EnvironmentType } from '../../../../schemas/envType'
import { IntegrationTestConnectionRequest } from '../../../../schemas/integrations/integrations'
import { ConnectableEnvironmentConfig, EnvironmentIntegrationFormData } from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const entraIdConfig: ConnectableEnvironmentConfig = {
	key: EnvironmentType.ENTRA_ID,
	name: 'Azure & Entra ID ',
	description:
		'Link Token Security to your Azure subscriptions and Entra ID tenants to map cloud identities across your Azure framework.',
	logo: <IntegrationIcon leftIcon={EntraIdIcon} rightIcon={AzureIcon} />,
	environmentNamesResolver: (environments: ServerEnvironments) =>
		environments[EnvironmentType.ENTRA_ID].map((env) => env.name),
	category: 'Cloud',
	guideFileName: EntraIdGuide,
	formFields: [
		{
			name: 'entraIdTenantId',
			label: 'Tenant ID',
			type: 'text',
			placeholder: 'Ex: 87654321-dcba-4321-fe00-cba987654321',
			required: true,
		},
		{
			name: 'entraIdAppId',
			label: 'Application ID',
			type: 'text',
			placeholder: 'Ex: 12345678-abcd-1234-ef00-123456789abc',
			required: true,
		},
		{
			name: 'entraIdAppSecret',
			label: 'Application Secret',
			type: 'password',
			placeholder: 'Ex: AbC12345XyZ67890!@#$%^&*()_+',
			required: true,
		},
		{
			name: 'entraIdLogsWorkspaceId',
			label: 'Log Analytics ID',
			type: 'text',
			placeholder: 'Ex: abcdef12-3456-7890-abcd-ef1234567890',
			required: false,
		},
	],
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.ENTRA_ID].filter(
			(env) =>
				env.entra_id_tenant_id.toLowerCase().includes(searchText.toLowerCase()) ||
				env.entra_id_app_id.toLowerCase().includes(searchText.toLowerCase()) ||
				env.name.toLowerCase().includes(searchText.toLowerCase()),
		)
		return filtered
	},
	createTestConnectionPayload: (formData: EnvironmentIntegrationFormData): IntegrationTestConnectionRequest => ({
		environment_type: EnvironmentType.ENTRA_ID,
		config: {
			type: EnvironmentType.ENTRA_ID,
			entra_id_tenant_id: formData.entraIdTenantId as string,
			entra_id_app_id: formData.entraIdAppId as string,
			entra_id_app_secret: formData.entraIdAppSecret as string,
			entra_id_logs_workspace_id: formData.entraIdLogsWorkspaceId as string,
			entra_id_logs_workspace_collector_enabled: !!formData.entraIdLogsWorkspaceId,
			// Currently we always want to enable the Azure collector for Entra ID
			entra_id_azure_collector_enabled: true,
		},
	}),
	createEnvironmentPayload: (formData: EnvironmentIntegrationFormData): EnvironmentCreateRequest => ({
		name: formData.name as string,
		environment_type: EnvironmentType.ENTRA_ID,
		config: {
			type: EnvironmentType.ENTRA_ID,
			entra_id_tenant_id: formData.entraIdTenantId as string,
			entra_id_app_id: formData.entraIdAppId as string,
			entra_id_app_secret: formData.entraIdAppSecret as string,
			entra_id_logs_workspace_id: formData.entraIdLogsWorkspaceId as string,
			entra_id_logs_workspace_collector_enabled: !!formData.entraIdLogsWorkspaceId,
			// Currently we always want to enable the Azure collector for Entra ID
			entra_id_azure_collector_enabled: true,
		},
	}),
}
