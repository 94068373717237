import { api } from './api.ts'
import {
	ServerResolvedVsUnresolvedSchema,
	ServerResolvedVsUnresolved,
} from '../schemas/metrics/resolvedVsUnresolved.ts'
import { ServerFindingDistName, ServerFindingDistNameSchema } from '../schemas/metrics/findingsDistByName.ts'
import { ServerFindingDistEnvSchema, ServerFindingDistEnv } from '../schemas/metrics/findingsDistByEnv.ts'
import { ServerIdentitiesByEnvSchema, ServerIdentitiesByEnv } from '../schemas/metrics/identitiesByEnv.ts'
import {
	ServerIdentitiesDistByPriorityAndDateSchema,
	ServerIdentitiesDistByPriorityAndDate,
} from '../schemas/metrics/identitiesDistByPriority.ts'
import {
	ServerTopIssuesAndInsights,
	ServerTopIssuesAndInsightsSchema,
} from '../schemas/metrics/topIssuesAndInsights.ts'
import { ServerMetricsSummary, ServerMetricsSummarySchema } from '../schemas/metrics/metricsSummary.ts'
import {
	ServerMostWantedIdentitiesSchema,
	ServerMostWantedIdentities,
} from '../schemas/metrics/mostWantedIdentities.ts'
import { useQuery } from '@tanstack/react-query'
import { ServerDistributionDataSchema, ServerDistributionData } from '../schemas/metrics/distributionData.ts'
import { ServerMetricsTopBarSchema, ServerMetricsTopBar } from '../schemas/metrics/metricsTopBar.ts'
import {
	ServerEnvTypeOrOtherIdentitiesCount,
	ServerEnvTypeOrOther,
	ServerEnvTypeIdentitiesCount,
	ServerEnvTypeIdentitiesCountSchema,
	OtherEnvironmentType,
} from '../schemas/metrics/envTypeIdentitiesCount.ts'
import { IssueName } from '../schemas/issue.ts'
import { EnvironmentType } from '../schemas/envType.ts'

const metricsApi = {
	async resolvedVsUnresolvedFindings() {
		const { data: resolvedVsUnResolved } = await api.get<ServerResolvedVsUnresolved[]>(
			`/api/metrics/resolved-vs-unresolved-findings`,
		)
		return resolvedVsUnResolved.map((item: ServerResolvedVsUnresolved) =>
			ServerResolvedVsUnresolvedSchema.parse(item),
		)
	},
	async findingsDistByName() {
		const { data: findingsDistByName } = await api.get<ServerFindingDistName[]>(
			`/api/metrics/findings-dist-by-name`,
		)
		return findingsDistByName.map((item) => ServerFindingDistNameSchema.parse(item))
	},

	async findingsDistByEnv(): Promise<ServerFindingDistEnv[]> {
		const { data: findingsDistByEnv } = await api.get<ServerFindingDistEnv[]>(`/api/metrics/findings-dist-by-env`)
		return findingsDistByEnv.map((item) => ServerFindingDistEnvSchema.parse(item))
	},

	async monitoredIdentitiesByEnv(): Promise<ServerIdentitiesByEnv[]> {
		const { data: identitiesByEnv } = await api.get<ServerIdentitiesByEnv[]>(
			`/api/metrics/monitored-identities-by-env`,
		)
		return identitiesByEnv.map((item) => ServerIdentitiesByEnvSchema.parse(item))
	},
	async topIssuesAndInsights(): Promise<ServerTopIssuesAndInsights | undefined> {
		try {
			const { data: top } = await api.get<ServerTopIssuesAndInsights>(`/api/metrics/top-issues-and-insights`)

			return ServerTopIssuesAndInsightsSchema.parse(top)
		} catch (error) {
			console.error(error)
			return
		}
	},
	async mostWantedIdentities(limit: number): Promise<ServerMostWantedIdentities> {
		const { data: top } = await api.get<ServerMostWantedIdentities>(
			`/api/metrics/most-wanted-identities?limit=${limit}`,
		)
		return ServerMostWantedIdentitiesSchema.parse(top)
	},
	async summary() {
		try {
			const { data: summary } = await api.get<ServerMetricsSummary>(`/api/metrics/summary`)

			return ServerMetricsSummarySchema.parse(summary)
		} catch (error) {
			console.error(error)
			return
		}
	},
	async topBar() {
		try {
			const { data: summary } = await api.get<ServerMetricsTopBar>(`/api/metrics/top-bar`)

			return ServerMetricsTopBarSchema.parse(summary)
		} catch (error) {
			console.error(error)
			return
		}
	},
	async keyRotation(): Promise<ServerDistributionData> {
		const { data: keyRotation } = await api.get<ServerDistributionData>(`/api/metrics/key-rotation`)
		return ServerDistributionDataSchema.parse(keyRotation)
	},
	async identitiesLifetimeDistribution(): Promise<ServerDistributionData> {
		const { data: lifecycleDistribution } = await api.get<ServerDistributionData>(
			`/api/metrics/identities-lifetime-distribution`,
		)
		return ServerDistributionDataSchema.parse(lifecycleDistribution)
	},
	async identitiesDistByPriorityAndDate(): Promise<ServerIdentitiesDistByPriorityAndDate[]> {
		const { data: identitiesDistByPriorityAndDate } = await api.get<ServerIdentitiesDistByPriorityAndDate[]>(
			`/api/metrics/identities-dist-by-priority-and-date`,
		)
		return identitiesDistByPriorityAndDate.map((item) => ServerIdentitiesDistByPriorityAndDateSchema.parse(item))
	},
	async identitiesWithIssueEnvTypeDistribution(issueName: IssueName): Promise<ServerEnvTypeIdentitiesCount[]> {
		const { data } = await api.get<ServerEnvTypeIdentitiesCount[]>(
			`/api/metrics/identities-with-issue-env-type-distribution?issue_name=${issueName}`,
		)
		return data.map((datum) => ServerEnvTypeIdentitiesCountSchema.parse(datum))
	},
}

export function useResolvedVsUnresolvedMetrics() {
	return useQuery({
		queryKey: ['resolvedVsUnresolvedMetrics'],
		queryFn: () => metricsApi.resolvedVsUnresolvedFindings(),
	})
}

export function useFindingsDistByName() {
	return useQuery({
		queryKey: ['findingsDistByName'],
		queryFn: () => metricsApi.findingsDistByName(),
	})
}

export function useFindingsDistByEnv() {
	return useQuery({
		queryKey: ['findingsDistByEnv'],
		queryFn: () => metricsApi.findingsDistByEnv(),
	})
}

export function useIdentitiesByEnv() {
	return useQuery({
		queryKey: ['identitiesByEnv'],
		queryFn: () => metricsApi.monitoredIdentitiesByEnv(),
	})
}

export function useTopIssuesAndInsights() {
	return useQuery({
		queryKey: ['topIssuesAndInsights'],
		queryFn: () => metricsApi.topIssuesAndInsights(),
	})
}

export function useMostWantedIdentities(limit = 5) {
	return useQuery({
		queryKey: ['mostWantedIdentities', limit],
		queryFn: () => metricsApi.mostWantedIdentities(limit),
	})
}

export function useMetricsSummary() {
	return useQuery({
		queryKey: ['metricsSummary'],
		queryFn: () => metricsApi.summary(),
	})
}

export function useMetricsTopBar() {
	return useQuery({
		queryKey: ['metricsTopBar'],
		queryFn: () => metricsApi.topBar(),
	})
}

export function useKeyRotation() {
	return useQuery({
		queryKey: ['keyRotation'],
		queryFn: () => metricsApi.keyRotation(),
	})
}

export function useIdentitiesLifetimeDistribution() {
	return useQuery({
		queryKey: ['identitiesLifetimeDistribution'],
		queryFn: () => metricsApi.identitiesLifetimeDistribution(),
	})
}

export function useIdentitiesDistByPriorityAndDate() {
	return useQuery({
		queryKey: ['identitiesDistByPriorityAndDate'],
		queryFn: () => metricsApi.identitiesDistByPriorityAndDate(),
	})
}

export function useIdentitiesWithIssueEnvTypeDistribution(issueName: IssueName) {
	return useQuery({
		queryKey: ['identitiesWithIssueEnvTypeDistribution', issueName],
		queryFn: () => metricsApi.identitiesWithIssueEnvTypeDistribution(issueName),
		select: (data): ServerEnvTypeOrOtherIdentitiesCount[] => {
			if (data.length <= 4) {
				return data
			}

			// We compress the data from N environment types to 3 environment types with the most identities and an
			// "Other" environment type that aggregates the rest of the environment types
			const topEnvTypes: EnvironmentType[] = data
				.sort((a, b) => b.total_count - a.total_count)
				.slice(0, 3)
				.map((datum) => datum.env_type)

			const selectedData: Partial<Record<ServerEnvTypeOrOther, ServerEnvTypeOrOtherIdentitiesCount>> =
				data.reduce(
					(acc, datum) => {
						if (topEnvTypes.includes(datum.env_type)) {
							return { ...acc, [datum.env_type]: datum }
						}

						const totalCount = (acc[OtherEnvironmentType.OTHER]?.total_count ?? 0) + datum.total_count
						const adminCount = (acc[OtherEnvironmentType.OTHER]?.admin_count ?? 0) + datum.admin_count
						return {
							...acc,
							[OtherEnvironmentType.OTHER]: {
								/* eslint-disable camelcase */
								env_type: OtherEnvironmentType.OTHER,
								total_count: totalCount,
								admin_count: adminCount,
								/* eslint-enable camelcase */
							},
						}
					},
					{} as Partial<Record<ServerEnvTypeOrOther, ServerEnvTypeOrOtherIdentitiesCount>>,
				)

			return Object.values(selectedData)
		},
	})
}
