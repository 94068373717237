import { z } from 'zod'
import { DateSchema } from '../../common.ts'

export const SnowflakeUserChangeLogsSchema = z.object({
	query_type: z.string(),
	query_text: z.string(),
	query_time: DateSchema,
	actor_user_name: z.string(),
})

export type SnowflakeUserChangeLogs = z.infer<typeof SnowflakeUserChangeLogsSchema>

export interface SnowflakeUserChangeLogsAggregatedSchema {
	actor_user_name: string
	query_type: string[]
	first_time: Date
	last_time: Date
}

export function aggregateSnowflakeUserChangeLogs(
	logs: z.infer<typeof SnowflakeUserChangeLogsSchema>[],
): SnowflakeUserChangeLogsAggregatedSchema[] {
	const aggregationMap: Map<string, SnowflakeUserChangeLogsAggregatedSchema> = new Map()

	for (const log of logs) {
		const { actor_user_name, query_type, query_time } = log

		if (!aggregationMap.has(actor_user_name)) {
			// Initialize the entry if it doesn't exist
			aggregationMap.set(actor_user_name, {
				actor_user_name,
				query_type: [query_type],
				first_time: query_time,
				last_time: query_time,
			})
		} else {
			// Update the existing entry
			const aggregated = aggregationMap.get(actor_user_name)!
			if (!aggregated.query_type.includes(query_type)) {
				aggregated.query_type.push(query_type)
			}
			aggregated.first_time = query_time < aggregated.first_time ? query_time : aggregated.first_time
			aggregated.last_time = query_time > aggregated.last_time ? query_time : aggregated.last_time
		}
	}

	return Array.from(aggregationMap.values()).sort((a, b) => a.last_time.getTime() - b.last_time.getTime())
}
