import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { BaseNode } from '../common/baseNodes/BaseNode'
import { NodeGroupBadge } from '../common/NodeGroupBadge'
import { ServerAdGroup } from '../../../../schemas/identities/activeDirectory/adGroupSchema'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons'
import { ServerTag } from '../../../../schemas/tags'

export type AdGroupsNodeType = Node<{ groups: ServerAdGroup[] }, 'adGroups'>
type AdGroupsNodeProps = NodeProps<AdGroupsNodeType>

export const AdGroupsNode = ({ data: { groups } }: AdGroupsNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = groups.some((group) =>
		highlightedNodes.some((node) => node.type === 'adGroup' && node.id === group.object_sid),
	)

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	const badgeTooltipContent = groups.map((group) => <span key={group.object_sid}>{group.sam_account_name}</span>)

	const uniqueTags = groups.reduce((tags, group) => {
		group.tags?.forEach((tag) => {
			if (!tags.find((t) => t.name === tag.name)) {
				tags.push(tag)
			}
		})
		return tags
	}, [] as ServerTag[])

	return (
		<BaseNode label="AD Groups">
			<NodeGroupBadge
				items={groups}
				nodeColorType="permission"
				tooltipContent={badgeTooltipContent}
				positionOnLeft
			/>
			<RiskNodeTagIcons tags={uniqueTags} />
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}

export default AdGroupsNode
