import { useCallback, useEffect, useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import {
	ColDef,
	FilterModel,
	IRowNode,
	IServerSideDatasource,
	IServerSideGetRowsParams,
	PaginationChangedEvent,
	RowClickedEvent,
	SetFilterValuesFuncParams,
	SideBarDef,
} from '@ag-grid-community/core'
import { Tag as AntdTag, Tooltip } from 'antd'
import { isResourceType, PriorityType, PriorityTypeMap, ResourceType } from '../../schemas/issue'
import {
	AffectedEnvironmentWithEnv,
	IdentitySource,
	IdentitySourceMap,
	IdentityType,
	inventoryUrlQuickSearchFilterParameter,
	isIdentitySource,
	ServerIdentitiesTableRow,
	ServerIdentityEnvironment,
	PaginatedTableContext,
	SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES,
	IdentityFilterKeys,
} from '../../schemas/identity'
import { formatDate, formatRelativeDateText } from '../../utils'
import { IdentityTypeTag } from '../../components/common/IdentityTypeTag'
import { PriorityNameTag } from '../../components/common/PriorityNameTag'
import { IdentityTypeWithIcon } from '../../components/common/IdentityTypeWithIcon.tsx'
import { IssueSourceIcon } from '../../components/common/IssueSourceIcon.tsx'
import KubernetesIcon from '../../assets/kubernetes_icon_20.svg?react'
import PostgresIcon from '../../assets/postgres_icon_20.svg?react'
import { Tag, TagsDisplayNameMap } from '../../schemas/tags.ts'
import { useIdentitiesContext } from './context/IdentitiesProvider.tsx'
import { TagIcons } from '../../components/common/TagIcons.tsx'
import { getIdentitiesTableRowStyle } from '../../tables/helpers.ts'
import {
	fetchIdentitiesQuery,
	fetchIdentityAffectedEnvironments,
	fetchIdentityEnvironments,
	fetchIdentitySources,
	fetchIdentityTagsWithEnvironment,
} from '../../api/identities.ts'
import {
	supportedTableDateFilterTypes,
	supportedTableNumberFilterTypes,
	supportedTableTextFilterTypes,
} from '../../utils/tableQueryUtils.ts'
import { IssueSourceFilterIcon } from '../../components/common/IssueSourceIconFilter.tsx'
import { RoutePaths } from '../RoutePaths.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { getQueryFilterFromTableFilterModel, refreshDynamicFilterValues } from './paginatedInventoryUtils.ts'
import { IdentityTagFilterIcon } from '../../components/common/IdentityTag/IdentityTagFilterIcon.tsx'
import { OverlappingOwnershipTags } from '../../components/common/OverLappingOwnershipTags.tsx'
import { difference, isEmpty, isEqual, omit } from 'lodash'
import { TableFilterEntry, TableTextFilterEntry } from '../../schemas/tableFilter.ts'

const defaultPageSize = 100

const dataSource: IServerSideDatasource = {
	getRows: (params: IServerSideGetRowsParams<ServerIdentitiesTableRow, PaginatedTableContext>) => {
		fetchIdentitiesQuery(
			{
				pagination: {
					offset: params.request.startRow!,
					limit: params.request.endRow! - params.request.startRow!,
				},
				sort: params.request.sortModel.map((col) => ({
					field: col.colId,
					direction: col.sort,
				})),
				filter: getQueryFilterFromTableFilterModel(params.request.filterModel as FilterModel),
			},
			params.context.lens,
			params.context.tab,
		).then((result) => {
			params.success({ rowData: result.identities, rowCount: result.total_count })
		})
	},
}

export const IdentitiesTablePaginated = () => {
	const { gridRef, setSearchText, setGridReady } = useIdentitiesContext()
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const search = useSearch({ from: RoutePaths.Identities })

	const { lens, tab } = useSearch({ from: RoutePaths.Identities })

	useEffect(() => {
		gridRef.current?.api?.refreshServerSide({ purge: true })
		refreshDynamicFilterValues(gridRef.current?.api)
	}, [lens, tab])

	const onRowClicked = useCallback((row: RowClickedEvent<ServerIdentitiesTableRow>) => {
		const selection = window.getSelection()?.toString()
		if (selection?.length && selection.length > 0) {
			return
		}

		if (row.node.group) return

		// Navigate to single identity page if possible
		if (row.data?.source && SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES.includes(row.data.source)) {
			void navigate({
				to: RoutePaths.Identity,
				params: { identityId: row.data.id },
				search: (prev) => {
					if (isEmpty(prev)) {
						return {}
					}

					return { identitiesPage: { ...prev } }
				},
			})
			return
		}

		// Check if there are any issues and sort them by priority descending
		const highestPriorityIssueId =
			row.data?.issues?.sort((a, b) => {
				return (b.priority || PriorityType.NOT_CALCULATED) - (a.priority || PriorityType.NOT_CALCULATED)
			})[0]?.id ?? '' // Use the ID of the issue with the highest priority, or default to empty string

		void navigate({
			search: (prev) => ({ ...prev, issueId: highestPriorityIssueId }),
		})
	}, [])

	const onFilterChanged = useCallback(() => {
		refreshDynamicFilterValues(gridRef.current?.api)

		const identityFilter = gridRef?.current?.api?.getFilterModel()
		// Synchronize filter changes from the table to the URL
		void navigate({
			search: (prev) => {
				const newSearch = { ...prev }
				if (isEmpty(identityFilter)) {
					delete newSearch.identityFilter
				} else {
					// We need to maintain the order of existing filters so that the filter chips will remain in
					//  the same order and not shuffle around. When we get the filter model from the grid API the
					//  order of filters there is the order of the columns in the table, and not the order in which
					//  the filters were applied.
					const newIdentityFilter = { ...newSearch.identityFilter }
					// First, go over existing filters and remove/update them according to the new table filter
					//  model.
					const existingFilterFields = Object.keys(newIdentityFilter) as Array<IdentityFilterKeys>
					existingFilterFields.forEach((filterField) => {
						if (filterField in identityFilter) {
							newIdentityFilter[filterField] = identityFilter[filterField] as TableFilterEntry
						} else {
							// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
							delete newIdentityFilter[filterField]
						}
					})

					// Now add new filter fields from the table filter model.
					const newFilterFields = difference(
						Object.keys(identityFilter),
						existingFilterFields,
					) as Array<IdentityFilterKeys>
					newFilterFields.forEach((filterField) => {
						newIdentityFilter[filterField] = identityFilter[filterField] as TableFilterEntry
					})

					newSearch.identityFilter = newIdentityFilter
				}

				// When a filter change we need to reset the page to the first page (0)
				if (newSearch.pagination) {
					if (newSearch.pagination.pageSize === defaultPageSize) {
						delete newSearch.pagination
					} else {
						newSearch.pagination = { pageSize: newSearch.pagination.pageSize }
					}
				}

				return newSearch
			},
		})
	}, [])

	const onGridReady = useCallback(() => {
		gridRef?.current?.api?.setFilterModel(search.identityFilter ?? null)
		const filterSearchText = search.identityFilter?.[inventoryUrlQuickSearchFilterParameter] as TableTextFilterEntry
		setSearchText(filterSearchText?.filter ?? '')
	}, [search.identityFilter])

	// An effect to match the internal table filter to the one in the URL
	useEffect(() => {
		const currentFilterModel = gridRef?.current?.api?.getFilterModel()
		// When the internal table filter changes we set the URL to match it, causing this effect to re-run. To avoid a
		//  loop, we only set the internal filter to the URL if it is different from the one that is already set in the
		//  table
		if (isEqual(currentFilterModel, search.identityFilter)) {
			return
		}

		gridRef?.current?.api?.setFilterModel(search.identityFilter ?? null)
	}, [search.identityFilter])

	const onPaginationChanged = useCallback((event: PaginationChangedEvent<ServerIdentitiesTableRow>) => {
		if (!event.newPage && !event.newPageSize) {
			return
		}

		const page = event.api.paginationGetCurrentPage()
		const pageSize = event.api.paginationGetPageSize()
		void navigate({
			search: (prev) => {
				if (page === 0 && pageSize === defaultPageSize) {
					return omit(prev, 'pagination')
				} else {
					return { ...prev, pagination: { page, pageSize } }
				}
			},
		})
	}, [])

	// An effect to match the internal table pagination to the one in the URL
	useEffect(() => {
		if (!gridRef?.current?.api || gridRef.current.api.isDestroyed()) {
			return
		}

		const currentPage = gridRef.current.api.paginationGetCurrentPage() ?? 0
		const currentPageSize = gridRef.current.api.getGridOption('paginationPageSize') ?? defaultPageSize
		const currentSearchPage = search.pagination?.page ?? 0
		const currentSearchPageSize = search.pagination?.pageSize ?? defaultPageSize
		// When the internal table pagination changes we set the URL to match it, causing this effect to re-run. To
		//  avoid a loop, we only set the internal pagination to the URL if it is different from the one that is already
		//  set in the table
		if (currentPageSize !== currentSearchPageSize) {
			gridRef.current.api.setGridOption('paginationPageSize', currentSearchPageSize)
		}
		if (currentPage !== currentSearchPage) {
			gridRef.current.api.paginationGoToPage(search.pagination?.page ?? 0)
		}
	}, [search.pagination?.page, search.pagination?.pageSize])

	const onGridPreDestroyed = useCallback(() => {
		setGridReady(false)
	}, [])

	const onFirstDataRendered = useCallback(() => {
		if (search.issueId) {
			gridRef?.current?.api?.forEachNode((node: IRowNode<ServerIdentitiesTableRow>) => {
				if (node.data?.issues?.some((issue) => issue.id === search.issueId)) {
					node.setSelected(true)
					gridRef?.current?.api?.ensureNodeVisible(node, 'middle')
				}
			})
		}

		gridRef?.current?.api?.sizeColumnsToFit()

		if (search.pagination) {
			gridRef?.current?.api?.setGridOption('paginationPageSize', search.pagination.pageSize)
			gridRef?.current?.api?.paginationGoToPage(search.pagination.page ?? 0)
		}

		// Pre-fetch dynamic filter values
		refreshDynamicFilterValues(gridRef?.current?.api)

		setGridReady(true)
	}, [search.issueId, search.pagination])

	const columnDefs: ColDef<ServerIdentitiesTableRow>[] = useMemo(() => {
		return [
			{
				headerName: 'Identity Type',
				field: 'type',
				minWidth: 150,
				flex: 1,
				cellRenderer: (params: { value?: IdentityType | null }) => {
					return <IdentityTypeTag type={params.value} />
				},
				filter: 'agSetColumnFilter',
				filterParams: {
					values: Object.values(IdentityType),
					cellRenderer: (params: { value: string }) => {
						return <IdentityTypeWithIcon type={params.value} />
					},
				},
			},
			{
				headerName: 'Identity Name',
				field: 'literal_friendly_name',
				filter: 'agTextColumnFilter',
				filterParams: {
					filterOptions: supportedTableTextFilterTypes,
				},
				minWidth: 260,
				flex: 2,
				cellRenderer: (params: { data?: ServerIdentitiesTableRow; node: IRowNode }) => {
					return (
						<Tooltip placement="bottomLeft" title={params.data?.literal}>
							{params.data?.literal_friendly_name ?? params.data?.literal}
						</Tooltip>
					)
				},
			},
			{
				headerName: 'Identity Literal',
				suppressColumnsToolPanel: true,
				suppressNavigable: true,
				suppressMovable: true,
				sortable: false,
				resizable: false,
				filter: false,
				width: 1,
				valueGetter: (params: { data?: ServerIdentitiesTableRow }) => params.data?.literal ?? '',
				cellRenderer: () => null,
			},
			{
				headerName: 'Identity Source',
				field: 'source',
				flex: 1,
				filter: 'agSetColumnFilter',
				sortable: false,
				minWidth: 180,
				valueGetter: (params: { data?: ServerIdentitiesTableRow }) => {
					return params.data?.source ? IdentitySourceMap[params.data?.source] : 'N/A'
				},
				cellRenderer: (params: { value: string }) => {
					return (
						<AntdTag>
							<div className="flex items-center">
								<div className="pl-1 text-gray-800 text text-sm">{params.value}</div>
							</div>
						</AntdTag>
					)
				},
				filterParams: {
					values: (params: SetFilterValuesFuncParams<ServerIdentitiesTableRow, IdentitySource>) => {
						const { lens } = params.context as PaginatedTableContext
						const filter: FilterModel = { ...params.api.getFilterModel() }
						if (filter.source) {
							delete filter.source
						}
						fetchIdentitySources({ filter: getQueryFilterFromTableFilterModel(filter) }, lens).then(
							(sources) => {
								params.success(sources)
							},
						)
					},
					cellRenderer: (params: { value: string }) => {
						return isIdentitySource(params.value) ? IdentitySourceMap[params.value] : params.value
					},
				},
			},
			{
				headerName: 'Posture Score',
				field: 'max_priority',
				filter: 'agSetColumnFilter',
				flex: 0.9,
				minWidth: 150,
				filterParams: {
					values: Object.values(PriorityType).filter((priority) => typeof priority === 'number'),
					cellRenderer: (params: { value: PriorityType | string }) => {
						return typeof params.value === 'string' ? params.value : PriorityTypeMap[params.value]
					},
				},
				valueGetter: (params: { data?: ServerIdentitiesTableRow }) => {
					return params.data?.max_priority ? PriorityTypeMap[params.data.max_priority] : 'N/A'
				},
				cellRenderer: (params: { data?: ServerIdentitiesTableRow; node: IRowNode }) => (
					<PriorityNameTag priority={params.data?.max_priority ?? PriorityType.LOW} />
				),
				sort: 'desc',
				sortIndex: 0,
			},
			{
				headerName: 'Source Environment',
				field: 'account_literal',
				filter: 'agSetColumnFilter',
				flex: 1.2,
				minWidth: 200,
				valueGetter: (params: { data?: ServerIdentitiesTableRow }) => {
					if (params.data?.account_literal_friendly_name) {
						return `${params.data?.env_type} - ${params.data?.account_literal_friendly_name} (${params.data?.account_literal})`
					} else {
						// Concatenate the issueSource and account_literal to get the full value
						return `${params.data?.env_type} - ${params.data?.account_literal}`
					}
				},
				filterParams: {
					treeList: true,
					treeListPathGetter: (value: ServerIdentityEnvironment) => {
						const name = value.account_literal_friendly_name
							? `${value.account_literal_friendly_name} (${value.account_literal})`
							: value.account_literal
						return [value.env_type, name]
					},
					cellRenderer: (params: { value: string }) => {
						if (isResourceType(params.value)) {
							return <IssueSourceFilterIcon source={params.value} />
						}

						return params.value
					},
					keyCreator: (params: { value: ServerIdentityEnvironment }) => {
						return params.value.account_literal
					},
					values: (
						params: SetFilterValuesFuncParams<ServerIdentitiesTableRow, ServerIdentityEnvironment>,
					) => {
						const { lens } = params.context as PaginatedTableContext
						const filter: FilterModel = { ...params.api.getFilterModel() }
						if (filter.account_literal) {
							delete filter.account_literal
						}
						fetchIdentityEnvironments({ filter: getQueryFilterFromTableFilterModel(filter) }, lens).then(
							(environments) => {
								params.success(environments)
							},
						)
					},
				},
				cellRenderer: (params: { data?: ServerIdentitiesTableRow }) => {
					return (
						<>
							{params.data?.source === IdentitySource.KUBERNETES_RESOURCE ? (
								<div className="flex -space-x-4 rtl:space-x-reverse">
									<IssueSourceIcon source={params.data?.env_type} />
									<Tooltip title="Kubernetes">
										<span>
											<KubernetesIcon />
										</span>
									</Tooltip>
								</div>
							) : params.data?.source === IdentitySource.POSTGRES_ROLE ||
							  params.data?.source === IdentitySource.AZURE_POSTGRES_ROLE ? (
								<div className="flex -space-x-4 rtl:space-x-reverse">
									<IssueSourceIcon source={params.data?.env_type} />
									<Tooltip title="Postgres">
										<span>
											<PostgresIcon />
										</span>
									</Tooltip>
								</div>
							) : (
								<IssueSourceIcon source={params.data?.env_type} />
							)}

							<Tooltip
								placement="bottomLeft"
								title={
									params.data?.account_literal_friendly_name
										? `${params.data?.account_literal_friendly_name} - (${params.data?.account_literal})`
										: params.data?.account_literal
								}
							>
								<div className="ml-2 truncate">
									{params.data?.account_literal_friendly_name ?? params.data?.account_literal}
								</div>
							</Tooltip>
						</>
					)
				},
			},
			{
				headerName: 'Owners',
				field: 'owners',
				flex: 1.3,
				minWidth: 150,
				cellRenderer: (params: { data?: ServerIdentitiesTableRow }) => {
					const owners = params.data?.owners || []
					return <OverlappingOwnershipTags owners={owners} />
				},
				sortable: false,
			},
			{
				headerName: 'Affected Environments',
				field: 'affected_environments',
				sortable: false,
				filter: 'agSetColumnFilter',
				flex: 1.2,
				minWidth: 200,
				filterParams: {
					treeList: true,
					treeListPathGetter: (value: AffectedEnvironmentWithEnv) => {
						const name = value.account_name
							? `${value.account_name} (${value.account_id})`
							: value.account_id
						return [value.env_type, name]
					},
					valueFormatter: (params: { value: AffectedEnvironmentWithEnv }) => {
						return params.value.account_db_id
					},
					cellRenderer: (params: { value: string }) => {
						if (isResourceType(params.value)) {
							return <IssueSourceFilterIcon source={params.value} />
						}

						return params.value
					},
					keyCreator: (params: { value: AffectedEnvironmentWithEnv }) => {
						return params.value.account_db_id
					},
					values: (
						params: SetFilterValuesFuncParams<ServerIdentitiesTableRow, AffectedEnvironmentWithEnv>,
					) => {
						const { lens } = params.context as PaginatedTableContext
						const filter: FilterModel = { ...params.api.getFilterModel() }
						if (filter.affected_environments) {
							delete filter.affected_environments
						}
						fetchIdentityAffectedEnvironments(
							{ filter: getQueryFilterFromTableFilterModel(filter) },
							lens,
						).then((affectedEnvironments) => {
							params.success(affectedEnvironments)
						})
					},
				},
				valueGetter: (params: { data?: ServerIdentitiesTableRow }) => {
					const valueSet = new Set()
					if (params.data?.affected_environments) {
						Object.entries(params.data.affected_environments).forEach(([envType, affectedEnvs]) => {
							affectedEnvs.forEach((env) => {
								if (env.account_name) {
									valueSet.add(`${envType} - ${env.account_name} (${env.account_id})`)
								} else {
									valueSet.add(`${envType} - ${env.account_id}`)
								}
							})
						})
					}
					return Array.from(valueSet)
				},

				cellRenderer: (params: { data?: ServerIdentitiesTableRow; node: IRowNode }) => {
					return (
						<>
							{params.data?.affected_environments &&
								Object.entries(params.data.affected_environments).map(([envType, _environments]) => (
									<div className="flex items-center space-x-2" key={envType}>
										<IssueSourceIcon source={envType} />
									</div>
								))}
						</>
					)
				},
			},
			{
				headerName: 'Risk',
				field: 'tags_names',
				hide: true,
				suppressColumnsToolPanel: true,
				sortable: false,
				filter: 'agSetColumnFilter',
				flex: 1.2,
				minWidth: 200,
				filterParams: {
					values: Object.values(Tag),
				},
				valueGetter: (params: { data?: ServerIdentitiesTableRow }): string[] | null => {
					if (params.data?.tags_names && params.data.tags_names.length > 0) {
						return params.data.tags_names.map((tag) => TagsDisplayNameMap[tag as Tag] || tag).sort()
					} else {
						return null
					}
				},
			},
			{
				headerName: 'Risk by Environment',
				field: 'tags_with_env',
				sortable: false,
				filter: 'agSetColumnFilter',
				flex: 1.2,
				minWidth: 200,
				filterParams: {
					treeList: true,
					treeListPathGetter: (value: string) => {
						return value.split('__').toReversed()
					},
					cellRenderer: (params: { value: string }) => {
						if (isResourceType(params.value)) {
							return <IssueSourceFilterIcon source={params.value} />
						}

						return <IdentityTagFilterIcon name={params.value as Tag} />
					},
					values: (params: SetFilterValuesFuncParams<ServerIdentitiesTableRow, string>) => {
						const { lens } = params.context as PaginatedTableContext
						const filter: FilterModel = { ...params.api.getFilterModel() }
						if (filter.tags_with_env) {
							delete filter.tags_with_env
						}
						fetchIdentityTagsWithEnvironment(
							{ filter: getQueryFilterFromTableFilterModel(filter) },
							lens,
						).then((tagsWithEnv) => {
							params.success(tagsWithEnv)
						})
					},
				},
				cellRenderer: (params: { data?: ServerIdentitiesTableRow; node: IRowNode }) => {
					return (
						<TagIcons
							tags={params.data?.tags_with_env?.map((tagWithEnv) => {
								const [resourceType, tagName] = tagWithEnv.split('__')
								return { name: tagName as Tag, resourceType: resourceType as ResourceType }
							})}
						/>
					)
				},
			},
			{
				headerName: 'Open Issues Count',
				field: 'issue_count',
				filter: 'agNumberColumnFilter',
				filterParams: {
					filterOptions: supportedTableNumberFilterTypes,
				},
				flex: 1,
				minWidth: 200,
				cellRenderer: (params: { value: number }) => (
					<div className="flex justify-center items-center w-full">{params.value ?? 0}</div>
				),
				sort: 'desc',
				sortIndex: 1,
			},
			{
				headerName: 'Identity Last Activity',
				field: 'last_activity',
				filter: 'agDateColumnFilter',
				filterParams: {
					filterOptions: supportedTableDateFilterTypes,
				},
				flex: 1,
				minWidth: 180,
				cellRenderer: (params: {
					data?: ServerIdentitiesTableRow
					node: IRowNode
					value: Date | undefined | null
				}) => {
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
							<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
						</Tooltip>
					)
				},
			},
			{
				colId: inventoryUrlQuickSearchFilterParameter,
				hide: true,
				suppressColumnsToolPanel: true,
				filter: 'agTextColumnFilter',
			},
		]
	}, [])

	const sideBar = useMemo<SideBarDef>(
		() => ({
			position: 'left',
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					minWidth: 225,
					maxWidth: 225,
					width: 225,
					toolPanelParams: {
						suppressPivotMode: true,
						suppressRowGroups: true,
						suppressValues: true,
					},
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
					minWidth: 300,
					maxWidth: 400,
					width: 300,
				},
			],
		}),
		[],
	)

	const defaultColDef = useMemo<ColDef<ServerIdentitiesTableRow>>(
		() => ({
			filterParams: {
				defaultToNothingSelected: true,
				maxNumConditions: 1,
			},
		}),
		[],
	)

	const context: PaginatedTableContext = useMemo(() => ({ lens, tab }), [lens, tab])

	return (
		<AgGridReact<ServerIdentitiesTableRow>
			getRowStyle={getIdentitiesTableRowStyle}
			ref={gridRef}
			defaultColDef={defaultColDef}
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			onGridReady={onGridReady}
			onGridPreDestroyed={onGridPreDestroyed}
			onFilterChanged={onFilterChanged}
			columnDefs={columnDefs}
			onRowClicked={onRowClicked}
			overlayLoadingTemplate={'Loading...'}
			suppressDragLeaveHidesColumns={true}
			overlayNoRowsTemplate={'No data'}
			rowSelection={'single'}
			suppressHorizontalScroll={false}
			sideBar={sideBar}
			getRowId={({ data }) => data.id}
			rowGroupPanelShow="never"
			enableCellTextSelection
			rowModelType="serverSide"
			serverSideDatasource={dataSource}
			onPaginationChanged={onPaginationChanged}
			onFirstDataRendered={onFirstDataRendered}
			pagination
			context={context}
		/>
	)
}
