import React, { useState } from 'react'
import { Modal } from 'antd'
import { integrationConfigs } from '../integrationsRegistry.ts'
import { useEnvironments } from '../../../../api/environments.ts'

import {
	ConnectableEnvironmentConfig,
	EnvironmentIntegrationFormData,
	isIntegrationConfigConnectable,
	SinglePageIntegrationEnabledEnvironments,
} from '../environmentIntegrationsTypes.ts'
import { IntegrationStatus } from '../../common/integrationTypes.ts'
import { IntegrationsError, IntegrationsSkeleton } from '../../common/components/IntegrationStates.tsx'
import { IntegrationCard } from '../../common/components/IntegrationCard.tsx'
import { EnvironmentIntegrationForm } from './EnvironmentIntegrationForm.tsx'
import { useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../../RoutePaths.tsx'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { useIntegrationFunctions } from '../../Utils/IntegrationUtils.tsx'

interface EnvironmentIntegrationsProps {
	isModalVisible: boolean
	setIsModalVisible: (visible: boolean) => void
}

export const EnvironmentIntegrations: React.FC<EnvironmentIntegrationsProps> = ({
	isModalVisible,
	setIsModalVisible,
}) => {
	const { handleConnectSubmit, handleTestConnection, isCreatingEnvironment, testConnection } =
		useIntegrationFunctions()
	const { data: environments, isLoading, error, isError } = useEnvironments()
	const navigate = useNavigate()
	const [selectedIntegration, setSelectedIntegration] = useState<ConnectableEnvironmentConfig | null>(null)

	const handleConnect = (integration: ConnectableEnvironmentConfig) => {
		setSelectedIntegration(integration)
		setIsModalVisible(true)
	}

	const handleCloseModal = () => {
		setIsModalVisible(false)
		setSelectedIntegration(null)
	}

	const handleIntegrationCardClick = (integrationKey: EnvironmentType) => {
		navigate({
			to: RoutePaths.Integration,
			params: {
				integrationEnvironmentType: integrationKey,
			},
		})
	}

	const handleSubmit = (formData: EnvironmentIntegrationFormData) => {
		// Selected integration is not null because we render the form only if there is an integration selected
		handleConnectSubmit(selectedIntegration!, formData, handleCloseModal)
	}

	if (isLoading) {
		return <IntegrationsSkeleton />
	}

	if (isError) {
		return <IntegrationsError error={error instanceof Error ? error : new Error('An unknown error occurred')} />
	}

	return (
		<>
			<div className="flex flex-wrap gap-5">
				{integrationConfigs.map((integrationConfig) => {
					const environmentNames = integrationConfig.environmentNamesResolver(environments)
					return (
						<IntegrationCard
							key={integrationConfig.key}
							logo={integrationConfig.logo}
							name={integrationConfig.name}
							description={integrationConfig.description}
							status={environmentNames.length > 0 ? IntegrationStatus.ACTIVE : IntegrationStatus.INACTIVE}
							environmentsConfig={{
								names: environmentNames,
								logsConnected: integrationConfig.logEnvironmentsCounter?.(environments),
							}}
							onConnect={
								isIntegrationConfigConnectable(integrationConfig)
									? () => handleConnect(integrationConfig)
									: undefined
							}
							onCardClick={
								SinglePageIntegrationEnabledEnvironments.includes(integrationConfig.key) &&
								environmentNames.length > 0
									? () => handleIntegrationCardClick(integrationConfig.key)
									: undefined
							}
						/>
					)
				})}
			</div>

			<Modal
				open={isModalVisible}
				onCancel={handleCloseModal}
				footer={null}
				width={530}
				className="integrationModal"
				destroyOnClose={true}
			>
				{selectedIntegration && (
					<EnvironmentIntegrationForm
						key={selectedIntegration.key}
						integration={selectedIntegration}
						onSubmit={handleSubmit}
						onTestConnection={handleTestConnection}
						onClose={handleCloseModal}
						isConnecting={isCreatingEnvironment}
						isTestingConnection={testConnection.isLoading}
					/>
				)}
			</Modal>
		</>
	)
}
