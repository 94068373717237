import React from 'react'
import { EnvironmentCreateRequest, ServerEnvironments } from '../../../schemas/environments/environments.ts'
import { IntegrationTestConnectionRequest } from '../../../schemas/integrations/integrations.ts'
import { EnvironmentType } from '../../../schemas/envType.ts'

export type EnvironmentIntegrationCategory = 'Cloud' | 'Identity Provider' | 'Contextual' | 'SaaS'

export const SinglePageIntegrationEnabledEnvironments = [EnvironmentType.ENTRA_ID]

interface FormField {
	name: string
	label: string
	type: 'text' | 'password' | 'number'
	required: boolean
	placeholder?: string
}

export interface EnvironmentIntegrationFormData {
	[key: string]: string | number
}

export interface EnvironmentConfig {
	key: keyof ServerEnvironments
	name: string
	description: string
	logo: React.ReactNode
	category: EnvironmentIntegrationCategory
	environmentNamesResolver: (environments: ServerEnvironments) => Array<string>
	logEnvironmentsCounter?: (environments: ServerEnvironments) => number | boolean
	filterEnvironments: (
		environments: ServerEnvironments,
		searchText: string,
	) => ServerEnvironments[keyof ServerEnvironments]
}

export interface ConnectableEnvironmentConfig extends EnvironmentConfig {
	guideFileName: string
	formFields: FormField[]
	createEnvironmentPayload: (formData: EnvironmentIntegrationFormData) => EnvironmentCreateRequest
	createTestConnectionPayload: (formData: EnvironmentIntegrationFormData) => IntegrationTestConnectionRequest
}

export function isIntegrationConfigConnectable(
	integration: EnvironmentConfig,
): integration is ConnectableEnvironmentConfig {
	if (
		'guideFileName' in integration &&
		'formFields' in integration &&
		'createEnvironmentPayload' in integration &&
		'createTestConnectionPayload' in integration
	) {
		return true
	}

	return false
}
