import { Node, NodeProps } from '@xyflow/react'
import { BaseDependenciesNode } from '../common/baseNodes/BaseDependenciesNode'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { DemoServerAdServer } from '../../../../schemas/identities/activeDirectory/demoAdServerSchema'
import ServerIcon from '../../../../assets/server_icon_white_20.svg?react'

export type DemoAdServersNodeType = Node<{ servers: DemoServerAdServer[] }, 'demoAdServers'>
type AdServersNodeProps = NodeProps<DemoAdServersNodeType>

export const DemoAdServersNode = ({ data: { servers } }: AdServersNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'demoAdServers')

	return (
		<BaseDependenciesNode
			resources={servers}
			isHighlighted={isHighlighted}
			nodeLabel="Servers"
			tooltipTitle={`Servers from which the identity has been used (${servers.length} ${
				servers.length === 1 ? 'server' : 'servers'
			})`}
			Icon={ServerIcon}
		/>
	)
}
