import { message } from 'antd'
import {
	ConnectableEnvironmentConfig,
	EnvironmentIntegrationFormData,
} from '../EnvironmentIntegrations/environmentIntegrationsTypes'
import { useCreateEnvironment } from '../../../api/environments'
import { useTestConnection } from '../../../api/integrations'
import { IntegrationTestConnectionRequest } from '../../../schemas/integrations/integrations'

export const useIntegrationFunctions = () => {
	const { mutate: createEnvironment, isLoading: isCreatingEnvironment } = useCreateEnvironment()
	const testConnection = useTestConnection()

	const handleConnectSubmit = (
		selectedIntegration: ConnectableEnvironmentConfig,
		formData: EnvironmentIntegrationFormData,
		handleCloseModal: () => void,
	) => {
		const environmentData = selectedIntegration.createEnvironmentPayload(formData)
		createEnvironment(environmentData, {
			onSuccess: () => {
				message.success(`${selectedIntegration.name} environment created successfully`)
				handleCloseModal()
			},
			onError: () => {
				message.error(`Failed to create ${selectedIntegration.name} environment.`)
			},
		})
	}

	const handleTestConnection = async (request: IntegrationTestConnectionRequest) => {
		return await testConnection.mutateAsync(request)
	}

	return {
		handleConnectSubmit,
		handleTestConnection,
		isCreatingEnvironment,
		testConnection,
	}
}
