import { Button, Col, Layout, Row, Tabs, Tooltip } from 'antd'
import { useCallback, useMemo } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { IdentitiesSideBar } from './IdentitiesSideBar/IdentitiesSideBar.tsx'
import { IdentityTabName, IdentitiesPageSearch } from '../../schemas/identity.ts'
import { IssueDrawer } from '../../components/drawers/issueDrawer'
import ClearFilterIcon from '../../assets/clear_filter_icon_20.svg?react'
import IdentitiesIcon from '../../assets/identities_not_clicked_icon_20.svg?react'
import { capitalize } from 'lodash'
import { RoutePaths } from '../RoutePaths.tsx'
import { IdentitiesTablePaginated } from './IdentitiesTablePaginated.tsx'
import { IdentitiesQuickSearch } from './IdentitiesQuickSearch.tsx'
import { IdentitiesStatsPaginated } from './stats/IdentitiesStatsPaginated.tsx'
import { IdentitiesExport } from './IdentitiesExport.tsx'
import { values } from 'lodash'
import { FilterView } from './FilterView/FilterView.tsx'

const TabToTooltip: Partial<Record<IdentityTabName, string>> = {
	[IdentityTabName.MANAGED]: 'Managed Identities',
	[IdentityTabName.DELETED]: 'Deleted Identities',
}

export const Identities = () => {
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const search = useSearch({ from: RoutePaths.Identities })

	const tabItems = useMemo(
		() =>
			values(IdentityTabName).map((tabName) => ({
				key: tabName,
				label: <Tooltip title={TabToTooltip[tabName]}>{capitalize(tabName)}</Tooltip>,
			})),
		[],
	)

	const onTabChange = (activeKey: string) => {
		void navigate({
			to: RoutePaths.Identities,
			search: (prev) => {
				const newSearch = { ...prev } as IdentitiesPageSearch
				delete newSearch.identityFilter
				delete newSearch.pagination
				newSearch.tab = activeKey as IdentityTabName
				return newSearch
			},
		})
	}

	const clearFilters = useCallback(() => {
		void navigate({
			search: (prev) => {
				const newSearch = { ...prev }
				delete newSearch.identityFilter
				return newSearch
			},
		})
	}, [])

	return (
		<Layout className="h-full rounded-md">
			<IdentitiesSideBar />
			<Layout.Content className="overflow-y-clip overflow-x-auto">
				<div className="h-full flex flex-col relative overflow-hidden">
					<div
						style={{
							backgroundColor: '#FFFFFF',
							border: '2px solid #FFFFFF',
							boxShadow: '0px 0px 10px 0px #0000001A',
						}}
						className="mb-2 rounded"
					>
						<div
							style={{
								border: '2px solid #FFFFFF',
								boxShadow: '0px 0px 10px 0px #0000001A',
							}}
							className="flex flexrow"
						>
							<div className="flex flex-row items-center p-2">
								<IdentitiesIcon />
								<span className="text-2xl font-semibold pl-1">Inventory</span>
							</div>

							<div className="flex w-5/6 justify-center items-center content-center">
								<Tabs
									className="full-width-tabs"
									defaultActiveKey={IdentityTabName.MANAGED}
									activeKey={search.tab}
									onChange={onTabChange}
									size="large"
									tabBarStyle={{
										marginBottom: '0px',
									}}
									items={tabItems}
								/>
							</div>
						</div>
						<IdentitiesStatsPaginated />
					</div>
					<Row className="px-2 items-center justify-between space-x-2 flex-nowrap">
						<Col className="flex flex-row items-center space-x-2 shrink-0">
							<IdentitiesQuickSearch />
							<Tooltip placement="topLeft" title="Clear filters">
								<Button
									icon={<ClearFilterIcon />}
									className="bg-white text-gray-500 !p-1"
									onClick={clearFilters}
								/>
							</Tooltip>
						</Col>
						<Col className="grow overflow-hidden">
							<FilterView />
						</Col>
						<Col className="shrink-0">
							<IdentitiesExport />
						</Col>
					</Row>
					<Row className="h-full w-full flex">
						<IdentitiesTablePaginated />
					</Row>
					<IssueDrawer route={RoutePaths.Identities} />
				</div>
			</Layout.Content>
		</Layout>
	)
}
