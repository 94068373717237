import AwsIcon from '../../../../assets/aws_icon_16.svg?react'
import { EnvironmentConfig } from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { ServerEnvironments } from '../../../../schemas/environments/environments.ts'

export const awsConfig: EnvironmentConfig = {
	key: EnvironmentType.AWS,
	name: 'AWS',
	description: 'Link Token Security to your AWS accounts to map cloud identities within the AWS ecosystem.',
	logo: <IntegrationIcon leftIcon={AwsIcon} />,
	category: 'Cloud',
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.AWS].filter((env) =>
			env.aws_accounts.some(
				(awsAccount) =>
					awsAccount.name.toLowerCase().includes(searchText.toLowerCase()) ||
					awsAccount.account_id.toLowerCase().includes(searchText.toLowerCase()) ||
					env.name.toLowerCase().includes(searchText.toLowerCase()),
			),
		)
		return filtered
	},
	environmentNamesResolver: (environments) =>
		environments[EnvironmentType.AWS]
			.filter((env) => env.enabled)
			.flatMap((awsEnv) =>
				awsEnv.aws_accounts
					.filter((awsAccount) => awsAccount.enabled && awsAccount.token_has_access)
					.map((awsAccount) => awsAccount.name),
			),
	logEnvironmentsCounter: (environments) =>
		environments[EnvironmentType.AWS].reduce((acc, awsEnv) => {
			if (!awsEnv.enabled) {
				return acc
			}

			const accountsWithCloudtrail = awsEnv.aws_accounts.filter(
				(awsAccount) =>
					awsAccount.enabled &&
					awsAccount.token_has_access &&
					awsAccount.account_cloudtrails.some((cloudTrailAccount) => cloudTrailAccount.enabled),
			)
			return acc + accountsWithCloudtrail.length
		}, 0),
}
