import { z } from 'zod'
import { ServerAdAceSchema } from './adAceSchema.ts'
import { ServerAdGroupSchema } from './adGroupSchema.ts'

export const BaseActiveDirectoryComputerSchema = z.object({
	// Computer identity
	sam_account_name: z.string(),
	distinguished_name: z.string(),
	sid: z.string(),
	domain: z.string(),

	// Computer details
	description: z.string().nullish(),
	operating_system: z.string().nullish(),
	enabled: z.boolean(),

	inbound_permissions: z.array(ServerAdAceSchema).nullish(),
	outbound_permissions: z.array(ServerAdAceSchema).nullish(),

	groups: z.array(ServerAdGroupSchema).nullish(),

	// Time-related fields
	when_created: z.string().datetime(),
	pwd_last_set: z.string().datetime(),
	last_logon: z.string().datetime().nullish(),
})

export const ServerActiveDirectoryComputerSchema = BaseActiveDirectoryComputerSchema
