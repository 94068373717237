/* eslint-disable camelcase */

import SalesforceIcon from '../../../../assets/salesforce_icon.svg?react'
import SalesforceGuide from '../../../../assets/integrationGuides/salesforce-integration-guide.pdf'
import { EnvironmentCreateRequest, ServerEnvironments } from '../../../../schemas/environments/environments.ts'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { IntegrationTestConnectionRequest } from '../../../../schemas/integrations/integrations.ts'
import { ConnectableEnvironmentConfig, EnvironmentIntegrationFormData } from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const salesforceConfig: ConnectableEnvironmentConfig = {
	key: EnvironmentType.SALESFORCE,
	name: 'Salesforce',
	description:
		'Link Token Security to your Salesforce account to map cloud identities across your cloud and SaaS applications within the Salesforce platform.',
	logo: <IntegrationIcon leftIcon={SalesforceIcon} />,
	category: 'SaaS',
	guideFileName: SalesforceGuide,
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.SALESFORCE].filter(
			(env) =>
				env.domain.toLowerCase().includes(searchText.toLowerCase()) ||
				env.name.toLowerCase().includes(searchText.toLowerCase()),
		)
		return filtered
	},
	environmentNamesResolver: (environments: ServerEnvironments) =>
		environments[EnvironmentType.SALESFORCE].map((env) => env.name),
	formFields: [
		{
			name: 'domain',
			label: 'Domain',
			type: 'text',
			required: true,
			placeholder: 'https://[ORG_NAME].my.salesforce.com',
		},
		{ name: 'consumerKey', label: 'Consumer Key', type: 'password', required: true },
		{ name: 'consumerSecret', label: 'Consumer Secret', type: 'password', required: true },
	],
	createTestConnectionPayload: (formData: EnvironmentIntegrationFormData): IntegrationTestConnectionRequest => ({
		environment_type: EnvironmentType.SALESFORCE,
		config: {
			type: EnvironmentType.SALESFORCE,
			domain: formData.domain as string,
			consumer_key: formData.consumerKey as string,
			consumer_secret: formData.consumerSecret as string,
		},
	}),
	createEnvironmentPayload: (formData: EnvironmentIntegrationFormData): EnvironmentCreateRequest => ({
		name: formData.name as string,
		environment_type: EnvironmentType.SALESFORCE,
		config: {
			type: EnvironmentType.SALESFORCE,
			domain: formData.domain as string,
			consumer_key: formData.consumerKey as string,
			consumer_secret: formData.consumerSecret as string,
		},
	}),
}
