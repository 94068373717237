import { AgChartsReact } from 'ag-charts-react'
import {
	AgAreaSeriesOptions,
	AgChartOptions,
	AgLineSeriesTooltipRendererParams,
	AgTooltipRendererResult,
} from 'ag-charts-enterprise'
import {
	EnvironmentsColors,
	IdentityEnvSource,
	IdentityEnvSourceName,
	ServerIdentitiesByEnv,
} from '../schemas/metrics/identitiesByEnv.ts'
import { noDataOverlay } from './helpers.tsx'
import { formatDate } from '../utils.ts'
import { useIdentitiesByEnv } from '../api/metrics.ts'
import { Skeleton } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'
import { useMemo } from 'react'

const renderedEnvSource: IdentityEnvSource[] = [
	IdentityEnvSource.AWS,
	IdentityEnvSource.OKTA,
	IdentityEnvSource.GOOGLE_WORKSPACE,
	IdentityEnvSource.JUMPCLOUD,
	IdentityEnvSource.ENTRA_ID,
	IdentityEnvSource.GCP,
	IdentityEnvSource.RDS_POSTGRES,
	IdentityEnvSource.KUBERNETES,
	IdentityEnvSource.SNOWFLAKE,
	IdentityEnvSource.GITHUB,
	IdentityEnvSource.AZURE,
	IdentityEnvSource.DATABRICKS,
	IdentityEnvSource.SALESFORCE,
	IdentityEnvSource.AZURE_DEVOPS,
	IdentityEnvSource.ACTIVE_DIRECTORY,
]

const chartRenderer =
	(title: string, source: IdentityEnvSource) =>
	(params: AgLineSeriesTooltipRendererParams): string | AgTooltipRendererResult => {
		const data = params.datum as ServerIdentitiesByEnv
		if (!data) return { title: '', content: '' }
		return {
			title,
			content: `${formatDate(data.date, false)} - ${data[source]}`,
			backgroundColor: EnvironmentsColors[source],
		}
	}

const getChartSeries = (yKey: IdentityEnvSource): AgAreaSeriesOptions => {
	const sourceName = IdentityEnvSourceName[yKey]
	return {
		type: 'area',
		xKey: 'date',
		yKey: yKey,
		yName: sourceName,
		stroke: EnvironmentsColors[yKey],
		strokeWidth: 0.5,
		stacked: true,
		tooltip: {
			renderer: chartRenderer(sourceName, yKey),
		},
	}
}

export const IdentitiesByEnvironmentChartV2 = () => {
	const { data: identitiesByEnv, isLoading } = useIdentitiesByEnv()
	const sourcesWithData = useMemo(() => {
		if (!identitiesByEnv) {
			return []
		}

		return renderedEnvSource.filter((envSource) => !!identitiesByEnv.find((entry) => !!entry[envSource]))
	}, [identitiesByEnv])

	if (isLoading) {
		return <Skeleton active />
	}

	const options: AgChartOptions = {
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
		data: identitiesByEnv,
		background: {
			fill: themeColors.neutral[100],
		},
		autoSize: true,
		theme: {
			palette: {
				fills: sourcesWithData.map((envSource) => `${EnvironmentsColors[envSource]}7D`),
			},
		},
		axes: [
			{
				type: 'time',
				position: 'bottom',
				label: {
					enabled: true,
					format: '%d-%m-%Y',
				},
				nice: false,
			},
			{
				type: 'number',
				position: 'left',
			},
		],
		series: sourcesWithData.map((envSource) => getChartSeries(envSource)),
	}

	return <AgChartsReact options={options} />
}
