import { NodeProps, Node } from '@xyflow/react'
import { IdentityActivityStatus, IdentitySourceMap, ServerIdentity } from '../../../../schemas/identity.ts'
import { BaseIdentityNode } from './baseNodes/BaseIdentityNode.tsx'
import { TagsNodeIndicator } from './TagsNodeIndicator'
import { NodeLabel } from './NodeLabel.tsx'
import TrashIcon from '../../../../assets/trash_icon_16.svg?react'

export type IdentityNodeType = Node<{ identity: ServerIdentity }, 'identity'>
type IdentityNodeProps = NodeProps<IdentityNodeType>

export const IdentityNode = ({ data: { identity } }: IdentityNodeProps) => {
	const isIdentityDeleted = identity.activity_status === IdentityActivityStatus.DELETED
	return (
		<BaseIdentityNode
			name={{
				text: identity.literal_friendly_name || identity.literal || 'Unknown identity',
				tooltipText: identity.literal,
			}}
			label={
				<NodeLabel
					label={IdentitySourceMap[identity.source]}
					iconNode={
						isIdentityDeleted ? (
							<span>
								<TrashIcon className="text-zinc-600" />
							</span>
						) : undefined
					}
					iconTooltipTitle={isIdentityDeleted ? 'Deleted identity' : undefined}
				/>
			}
			isMainIdentity
		>
			<TagsNodeIndicator identity={identity} keyId={'base-identity-tags'} />
		</BaseIdentityNode>
	)
}
