import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'

export type ConnectedGcpRoleNodeType = Node<{ role: string }, 'connectedGcpRole'>
type ConnectedGcpRoleNodeProps = NodeProps<ConnectedGcpRoleNodeType>

export const ConnectedGcpRoleNode = ({ data: { role } }: ConnectedGcpRoleNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)
	const segments = role.split('/')
	const lastSegment = segments.pop()

	return (
		<BaseNode label="GCP Role" name={lastSegment ? { text: lastSegment } : null}>
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
