// adGroupsData.ts
import { StandardGroupSids } from './adConstants'

export const adGroupsData = {
	DOMAIN_ADMINS: {
		object_sid: StandardGroupSids.DOMAIN_ADMINS,
		sam_account_name: 'Domain Admins',
		description: 'Designated administrators of the domain',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_SID,
				principal_type: 'Group',
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_SID,
				principal_type: 'Group',
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_SID,
				principal_type: 'Group',
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_SID,
				principal_type: 'Group',
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'ADMIN',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	DNS_ADMINS: {
		object_sid: StandardGroupSids.DNS_ADMINS,
		sam_account_name: 'DnsAdmins',
		description: 'DNS Administrators Group',
		outbound_permissions: [
			{
				right_name: 'GenericAll',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-512',
				principal_type: 'Group',
			},
			{
				right_name: 'GenericAll',
				is_inherited: false,
				principal_sid: 'TOKEN.COM-S-1-5-32-548',
				principal_type: 'Group',
			},
			{
				right_name: 'GenericAll',
				is_inherited: true,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-519',
				principal_type: 'Group',
			},
			{
				right_name: 'GenericWrite',
				is_inherited: true,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1242',
				principal_type: 'Group',
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	SQL_WRITE_ACCESS: {
		object_sid: StandardGroupSids.SQL_WRITE_ACCESS,
		sam_account_name: 'SQL_Write_Access',
		description: 'Members have write access to SQL databases',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_ADMINS,
				principal_type: 'Group',
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	PRM_LOG_ADMINS: {
		object_sid: StandardGroupSids.PRM_LOG_ADMINS,
		sam_account_name: 'PRM_LOG_ADMINS',
		description: 'Members have admin access to PRM logs',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: 'S-1-5-21-3623811015-3361044348-30300820-1199',
				principal_type: 'Group',
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
				principal_sid: 'S-1-5-21-3623811015-3361044348-30300820-1199',
				principal_type: 'Group',
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	DOMAIN_USERS: {
		object_sid: StandardGroupSids.DOMAIN_USERS,
		sam_account_name: 'Domain Users',
		description: 'All domain users',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_ADMINS,
				principal_type: 'Group',
			},
		],
		tags: [],
	},
}
