import { AdminPortal, useLoginWithRedirect, useRolesState } from '@frontegg/react'
import { useAuth as useFronteggAuth, useAuthActions as useFronteggAuthActions } from '@frontegg/react-hooks/auth'

export const useAuth = () => {
	const { isAuthenticated, isLoading, user: fronteggUser, profileState, tenantsState } = useFronteggAuth()
	const { roles: fronteggRoles, permissions: fronteggPermissions } = useRolesState()

	// We should map these to our models
	const user = fronteggUser!
	const roles = fronteggRoles
	const permissions = fronteggPermissions
	const activeTenant = tenantsState.activeTenant
	const isAdmin = !!user.roles.find((role) => role.name === 'Admin')
	const isImpersonating = user.act !== undefined

	return {
		isAuthenticated,
		isLoading,
		user,
		roles,
		permissions,
		profileState,
		tenantsState,
		activeTenant,
		isAdmin,
		isImpersonating,
	}
}

export const useAuthActions = () => {
	const { logout, switchTenant } = useFronteggAuthActions()
	const loginWithRedirect = useLoginWithRedirect()
	const showAdminPortal = () => AdminPortal.show()

	return { logout, loginWithRedirect, showAdminPortal, switchTenant }
}
