import { createRouter, parseSearchWith, RouterProvider, stringifySearchWith } from '@tanstack/react-router'
import {
	anyOtherRoute,
	apiRoute,
	findingsRoute,
	identitiesRoute,
	identityRoute,
	indexRoute,
	integrationsRoute,
	singlePageIntegrationRoute,
	itdrRoute,
	logoutRoute,
	metricsRoute,
	rootRoute,
	settingsRoute,
	slackIntegrationRoute,
} from './Routes.tsx'
import { decodeFromBinary, encodeToBinary } from '../utils.ts'

const routeTree = rootRoute.addChildren([
	anyOtherRoute,
	indexRoute,
	findingsRoute,
	metricsRoute,
	logoutRoute,
	identitiesRoute,
	identityRoute,
	apiRoute,
	settingsRoute,
	slackIntegrationRoute,
	itdrRoute,
	integrationsRoute,
	singlePageIntegrationRoute,
])

const router = createRouter({
	routeTree,
	parseSearch: parseSearchWith((value) => JSON.parse(decodeFromBinary(value)) as Record<string, unknown>),
	stringifySearch: stringifySearchWith((value) => encodeToBinary(JSON.stringify(value))),
})

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router
	}
}

export const AppRouter = () => {
	return <RouterProvider router={router} />
}
