import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import openapiDemo from '../../mirage/openapi-demo.json'
import openapi from './api.json'
import './SwaggerStyle.css'
import { isDemo } from '../../utils/demoUtils.ts'

export const SwaggerComponent = () => (
	<div className="swagger-container">
		<SwaggerUI spec={isDemo ? openapiDemo : openapi} />
	</div>
)
