import { Layout, Skeleton, Spin, Typography } from 'antd'
import { useIdentity } from '../../api/identities.ts'
import { IdentityHeader } from './IdentityHeader/IdentityHeader.tsx'
import { IdentityPageBody } from './IdentityPageBody.tsx'
import { HighlightedNodesProvider } from './HighlightedNodesContext.tsx'
import { useParams } from '@tanstack/react-router'
import { RoutePaths } from '../RoutePaths.tsx'
import { ActiveSidePanelKeysProvider } from './ActiveSidePanelKeysContext.tsx'
import { ActiveTagsKeysProvider } from './ActiveTagsKeysContext.tsx'

export const IdentityPage = () => {
	const { identityId } = useParams({ from: RoutePaths.Identity })
	const {
		data: identity,
		isLoading: isIdentityLoading,
		isError: isIdentityError,
		isFetching: isIdentityFetching,
	} = useIdentity(identityId)

	return (
		<>
			<Layout className="h-full rounded-md relative overflow-hidden">
				<Layout.Header
					className="rounded-t-md px-6 pt-3 pb-4 h-auto"
					style={{
						backgroundColor: 'rgba(255, 255, 255, 0.7)',
						boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
					}}
				>
					{isIdentityLoading ? (
						<Skeleton active />
					) : isIdentityError ? (
						<Typography.Title>Error</Typography.Title>
					) : (
						<IdentityHeader identity={identity} />
					)}
				</Layout.Header>
				<Layout.Content
					className="mt-3 h-full border-t"
					style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
				>
					{isIdentityLoading ? (
						<Skeleton className="p-4" active />
					) : isIdentityError ? (
						<div className="flex justify-center pt-8">{`Could not fetch identity ${identityId}`}</div>
					) : (
						<HighlightedNodesProvider>
							<ActiveSidePanelKeysProvider>
								<ActiveTagsKeysProvider>
									<IdentityPageBody identity={identity} />
								</ActiveTagsKeysProvider>
							</ActiveSidePanelKeysProvider>
						</HighlightedNodesProvider>
					)}
				</Layout.Content>
			</Layout>
			<Spin
				spinning={isIdentityFetching && !isIdentityLoading}
				delay={100}
				fullscreen={isIdentityFetching && !isIdentityLoading}
				size="large"
			/>
		</>
	)
}
