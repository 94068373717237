import { z } from 'zod'
import { DateSchema } from '../../common.ts'

export const DemoServerAdServerSchema = z.object({
	host_name: z.string(),
	source_ip: z.string(),
	destination_ip: z.string(),
	destination: z.string(),
	last_login: DateSchema,
})

export type DemoServerAdServer = z.infer<typeof DemoServerAdServerSchema>
