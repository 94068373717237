import { z } from 'zod'
import { ServerAwsEnvironmentSchema } from './awsEnvironment.ts'
import { ServerGcpEnvironmentSchema } from './gcpEnvironment.ts'
import { ServerOktaEnvironmentSchema } from './oktaEnvironment.ts'
import { ServerJumpcloudEnvironmentSchema } from './jumpcloudEnvironment.ts'
import { ServerEntraIdEnvironmentSchema } from './entraIdEnvironment.ts'
import { ServerGoogleWorkspaceEnvironmentSchema } from './googleWorkspaceEnvironment.ts'
import { ServerSalesforceEnvironmentSchema } from './salesforceEnvironment.ts'
import { EnvironmentType } from '../envType.ts'
import { ServerAzureDevopsEnvironmentSchema } from './azureDevopsEnvironment.ts'
import { ServerActiveDirectoryEnvironmentSchema } from './activeDirectoryEnvironment.ts'
import { ServerGithubEnvironmentSchema } from './githubEnvironment.ts'
import { ServerSnowflakeEnvironmentSchema } from './snowflakeEnvironment.ts'

const AzureDevOpsEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.AZURE_DEVOPS),
	organization_name: z.string(),
	log_analytics_workspace_id: z.string(),
	personal_access_token: z.string(),
})
const SalesforceEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.SALESFORCE),
	domain: z.string(),
	consumer_key: z.string(),
	consumer_secret: z.string(),
})
const EntraIdEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.ENTRA_ID),
	entra_id_tenant_id: z.string(),
	entra_id_app_id: z.string(),
	entra_id_app_secret: z.string(),
	entra_id_logs_workspace_id: z.string(),
	entra_id_logs_workspace_collector_enabled: z.boolean(),
	entra_id_azure_collector_enabled: z.boolean(),
})

export const ServerEnvironmentsSchema = z.object({
	[EnvironmentType.AWS]: z.array(ServerAwsEnvironmentSchema),
	[EnvironmentType.GCP]: z.array(ServerGcpEnvironmentSchema),
	[EnvironmentType.OKTA]: z.array(ServerOktaEnvironmentSchema),
	[EnvironmentType.JUMPCLOUD]: z.array(ServerJumpcloudEnvironmentSchema),
	[EnvironmentType.ENTRA_ID]: z.array(ServerEntraIdEnvironmentSchema),
	[EnvironmentType.AZURE_DEVOPS]: z.array(ServerAzureDevopsEnvironmentSchema),
	[EnvironmentType.SALESFORCE]: z.array(ServerSalesforceEnvironmentSchema),
	[EnvironmentType.GOOGLE_WORKSPACE]: z.array(ServerGoogleWorkspaceEnvironmentSchema),
	[EnvironmentType.ACTIVE_DIRECTORY]: z.array(ServerActiveDirectoryEnvironmentSchema),
	[EnvironmentType.GITHUB]: z.array(ServerGithubEnvironmentSchema),
	[EnvironmentType.SNOWFLAKE]: z.array(ServerSnowflakeEnvironmentSchema),
})

export type ServerEnvironments = z.infer<typeof ServerEnvironmentsSchema>

export const EnvironmentCreateRequestSchema = z.object({
	name: z.string(),
	environment_type: z.nativeEnum(EnvironmentType),
	config: z.union([
		AzureDevOpsEnvironmentConfigSchema,
		SalesforceEnvironmentConfigSchema,
		EntraIdEnvironmentConfigSchema,
	]),
})

export type EnvironmentCreateRequest = z.infer<typeof EnvironmentCreateRequestSchema>

export const EnvironmentCreateResponseSchema = z.object({
	message: z.string(),
	environment_id: z.string(),
})

export type EnvironmentCreateResponse = z.infer<typeof EnvironmentCreateResponseSchema>
