import { z } from 'zod'
import { DateSchema } from '../common'
import { ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { ServerAwsIamUserPermissionXcSchema } from './awsIamUserXcSchema.ts'
import { ServerOktaUserXcSchema } from './oktaUserXcSchema.ts'
import { ServerKubernetesResourceXcSchema } from './kubernetes/kubernetesResourceXcSchema.ts'
import { ServerAwsAccountSchema } from '../environments/awsAccountSchema.ts'
import { ServerAwsIamChangeLogSchema } from './awsIamChangeLogSchema.ts'
import { ServerAwsEc2InstanceXcSchema } from './awsEc2InstanceXcSchema.ts'
import { ServerEndpointAccessXcSchema } from './endpointAccessXcSchema.ts'
import { ServerAwsIdentityPolicySchema } from './awsPoliciesSchema.ts'
import { UsageAssociationItemSchema } from './usageAssociationItemSchema.ts'
import { UsageAssociationToActionsSchema } from './usageActionItemSchema.ts'

export const ServerAwsIamUserAccessKeySchema = z
	.object({
		is_active: z.boolean().nullish(),
		last_used_date: z.string().nullish(),
		last_rotated: z.string().nullish(),
		last_used_region: z.string().nullish(),
		last_used_service: z.string().nullish(),
		access_key_id: z.string().nullish(),
		updated_at: z.string().nullish(),
	})
	.nullish()

export type ServerAwsIamUserAccessKey = z.infer<typeof ServerAwsIamUserAccessKeySchema>

export const ServerAwsIamUserCertReport = z
	.object({
		is_active: z.boolean().nullish(),
		last_rotated: z.string().nullish(),
	})

	.nullish()

export const ServerAwsIamUserPasswordInfo = z
	.object({
		enabled: z.boolean().nullish(),
		last_rotated: z.string().nullish(),
		last_changed: z.string().nullish(),
		next_rotation: z.string().nullish(),
	})
	.nullish()
export const ServerAwsUsageLogSchema = z.object({
	customer_id: z.string(),
	environment_id: z.string(),
	sub_env_id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),

	arn: z.string(),
	association: z.string().nullable(),
	association_type: z.string().nullish(),
	ip: z.string(),
	day: DateSchema,
	events: z.array(z.string()),
	events_count: z.number(),
	user_agent: z.string().nullish(),
	vpc_endpoint_id: z.string().nullish(),
	aws_access_key_id: z.string().nullish(),
})
export type ServerAwsUsageLog = z.infer<typeof ServerAwsUsageLogSchema>

export const ServerAwsUsageLogGroupedSchema = z.object({
	customer_id: z.string(),
	environment_id: z.string(),
	sub_env_id: z.string(),
	arn: z.string(),
	awsAccessKeyId: z.string(),
	associations: z.array(UsageAssociationItemSchema),
	actions: UsageAssociationToActionsSchema,
})
export type ServerAwsUsageLogGrouped = z.infer<typeof ServerAwsUsageLogGroupedSchema>

export const ServerAwsIamUserSchema = z.object({
	name: z.string().nullish(),
	arn: z.string(),
	last_activity: DateSchema.optional().nullish(),
	created_at: DateSchema.optional().nullish(),
	mfa_active: z.boolean().nullish(),
	access_key_1: ServerAwsIamUserAccessKeySchema.optional().nullish(),
	access_key_2: ServerAwsIamUserAccessKeySchema.optional().nullish(),
	cert_1: ServerAwsIamUserCertReport.optional().nullish(),
	cert_2: ServerAwsIamUserCertReport.optional().nullish(),
	password_info: ServerAwsIamUserPasswordInfo.optional().nullish(),
	aws_iam_roles_xc: z.lazy(() => z.array(ServerAwsIamRoleXcSchema).nullish()),
	aws_ec2_instances_xc: z.array(ServerAwsEc2InstanceXcSchema).nullish(),
	aws_iam_user_details_xc: ServerAwsIamUserPermissionXcSchema.nullish(),
	aws_iam_access_keys_xc: z.array(ServerAwsIamUserAccessKeySchema).nullish(),
	okta_user_xc: z.array(ServerOktaUserXcSchema).nullish(),
	kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(),
	aws_account: ServerAwsAccountSchema.nullish(),
	change_logs: z.array(ServerAwsIamChangeLogSchema).nullish(),
	endpoint_access_xc: z.array(ServerEndpointAccessXcSchema).nullish(),
	usage_logs: z.array(ServerAwsUsageLogSchema).nullish(),
	permission_boundary: ServerAwsIdentityPolicySchema.nullish(),
})

export interface Tag {
	Key: string
	Value: string
}

export type ServerAwsIamUser = z.infer<typeof ServerAwsIamUserSchema>
