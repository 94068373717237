import { tableFilterToQueryFilter } from '../../utils/tableQueryUtils.ts'
import { TableFilterEntry } from '../../schemas/tableFilter.ts'
import { FilterModel, GridApi, ISetFilter } from '@ag-grid-community/core'
import { IServerSideGetRowsParams } from '@ag-grid-community/core'
import {
	ClientIssueSearch,
	findingsUrlQuickSearchFilterParameter,
	IssueName,
	IssuesPageLens,
	IssuesTabName,
	IssueTablePaginatedRow,
} from '../../schemas/issue.ts'
import { getCsvFileName } from '../../utils.ts'
import { fetchIssuesQueryForExport } from '../../api/issues.ts'

const tableFieldToQueryFields = {
	/* eslint-disable camelcase */
	identity_literal_friendly_name: ['identity_literal', 'identity_literal_friendly_name'],
	[findingsUrlQuickSearchFilterParameter]: [
		'identity_literal_friendly_name',
		'identity_account_literal_friendly_name',
		'identity_account_literal',
	],
	/* eslint-enable camelcase */
}

const tableSetToTextContainsNullFields = new Set(['assignee'])

export const getQueryFilterFromTableFilterModel = (filterModel: FilterModel) =>
	tableFilterToQueryFilter(filterModel as Record<string, TableFilterEntry>, {
		tableFieldToQueryFields,
		tableSetToTextContainsNullFields,
	})

const columnsWithDynamicFilterValues = ['issue_name', 'identity_account_literal', 'assignee']

export function refreshDynamicFilterValues(
	gridApi?: GridApi<IssueTablePaginatedRow> | GridApi<ClientIssueSearch>,
	columnToIgnore?: string,
) {
	if (!gridApi) {
		return
	}

	columnsWithDynamicFilterValues.forEach((columnId) => {
		if (columnId === columnToIgnore) {
			return
		}

		gridApi.getColumnFilterInstance(columnId).then((setFilter) => {
			// eslint-disable-next-line no-extra-semi
			;(setFilter as ISetFilter).refreshFilterValues()
		})
	})
}

export function handleExportingPaginatedDataToCsv(gridApi?: GridApi<IssueTablePaginatedRow>) {
	if (!gridApi || gridApi.isDestroyed()) {
		return
	}

	const originalDataSource = gridApi.getGridOption('serverSideDatasource')
	type TableContext = { tab?: IssuesTabName; lens?: IssuesPageLens | IssueName }
	const tempDataSource = {
		getRows: (params: IServerSideGetRowsParams<IssueTablePaginatedRow, TableContext>) => {
			fetchIssuesQueryForExport(
				{
					sort: params.request.sortModel.map((col) => ({
						field: col.colId,
						direction: col.sort,
					})),
					filter: getQueryFilterFromTableFilterModel(params.request.filterModel as FilterModel),
				},
				params.context.lens,
				params.context.tab,
			)
				.then((result) => {
					params.success({ rowData: result.issues, rowCount: result.total_count })
					gridApi.exportDataAsCsv({ fileName: getCsvFileName('issues') })
				})
				.finally(() => {
					gridApi.setGridOption('serverSideDatasource', originalDataSource)
				})
		},
	}

	gridApi.setGridOption('serverSideDatasource', tempDataSource)
}
