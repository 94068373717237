import React from 'react'
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'

interface SingleIntegrationGenericCardProps {
	title: string
	logo: React.ReactNode
	fields: { label: string; value: string | React.ReactNode }[]
}

export const SingleIntegrationGenericCard: React.FC<SingleIntegrationGenericCardProps> = ({ title, logo, fields }) => {
	return (
		<div className="w-[286px] p-4 bg-white rounded-lg border border-zinc-200 flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-2">
					{logo}
					<Tooltip title={title}>
						<h3 className="text-base font-medium text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px]">
							{title}
						</h3>
					</Tooltip>
				</div>
				<Tooltip title="If you wish to make changes, please contact the Token team.">
					<Button
						icon={<EditOutlined />}
						onClick={() => {}}
						className="text-gray-500 p-1"
						type="text"
						disabled={true}
					/>
				</Tooltip>
			</div>
			<div className="flex flex-col gap-2">
				<div className="flex justify-between gap-4">
					{fields.map((field) => (
						<span key={`title-${field.label}`} className="flex-1 text-zinc-500 text-start">
							{field.label}
						</span>
					))}
				</div>
				<div className="flex justify-between gap-4">
					{fields.map((field) => (
						<Tooltip key={`value-${field.label}`} title={field.value}>
							<span
								className="flex-1 text-zinc-700 text-start overflow-hidden text-ellipsis whitespace-nowrap"
								style={{ width: '150px' }}
							>
								{field.value}
							</span>
						</Tooltip>
					))}
				</div>
			</div>
			<div className="w-full h-8 flex items-center justify-center gap-2 rounded text-sm font-medium bg-green-100 text-green-500">
				<CheckCircleOutlined /> Connected
			</div>
		</div>
	)
}
