import React, { useState, useEffect, useMemo } from 'react'
import { useParams, Link } from '@tanstack/react-router'
import { RoutePaths } from '../../../RoutePaths'
import { EnvironmentTypeMap } from '../../../../schemas/envType'
import { Layout, Input, Button, Modal, Breadcrumb, Tooltip } from 'antd'
import { LeftOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons'
import { useEnvironments } from '../../../../api/environments'
import { EnvironmentIntegrationForm } from '../components/EnvironmentIntegrationForm'
import {
	ConnectableEnvironmentConfig,
	EnvironmentConfig,
	EnvironmentIntegrationFormData,
	isIntegrationConfigConnectable,
} from '../environmentIntegrationsTypes'
import { integrationConfigs } from '../integrationsRegistry'
import { IntegrationsSkeleton } from '../../common/components/IntegrationStates'
import { useIntegrationFunctions } from '../../Utils/IntegrationUtils'
import { ServerEnvironments } from '../../../../schemas/environments/environments'
import { SinglePageIntegrationBody } from './SinglePageIntegrationBody'

export const SinglePageIntegration: React.FC = () => {
	const [searchText, setSearchText] = useState('')
	const { data: environments, isLoading, isError } = useEnvironments()
	const { integrationEnvironmentType } = useParams({ from: RoutePaths.Integration })
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedIntegration, setSelectedIntegration] = useState<
		ConnectableEnvironmentConfig | EnvironmentConfig | null
	>(null)
	const { handleConnectSubmit, handleTestConnection, isCreatingEnvironment, testConnection } =
		useIntegrationFunctions()
	const [filteredEnvironments, setFilteredEnvironments] = useState<ServerEnvironments[keyof ServerEnvironments]>([])

	useEffect(() => {
		const integrationConfig = integrationConfigs.find((config) => config.key === integrationEnvironmentType)
		setSelectedIntegration(integrationConfig ?? null)

		if (environments && integrationConfig) {
			setFilteredEnvironments(integrationConfig.filterEnvironments(environments, searchText))
		}
	}, [integrationEnvironmentType, environments, searchText])

	const handleConnect = (integration: ConnectableEnvironmentConfig) => {
		setSelectedIntegration(integration)
		setIsModalVisible(true)
	}

	const handleCloseModal = () => {
		setIsModalVisible(false)
		setSelectedIntegration(null)
	}

	const handleSubmit = (formData: EnvironmentIntegrationFormData) => {
		handleConnectSubmit(selectedIntegration as ConnectableEnvironmentConfig, formData, handleCloseModal)
	}

	const breadcrumbItems = useMemo(
		() => [
			{
				title: (
					<Link
						from={RoutePaths.Integration}
						to={RoutePaths.Integrations}
						className="!flex items-center !h-full"
					>
						<LeftOutlined className="mr-2" />
						All Integrations
					</Link>
				),
			},
			{ title: <span>{EnvironmentTypeMap[integrationEnvironmentType]}</span> },
		],
		[integrationEnvironmentType],
	)

	return (
		<Layout className="h-full rounded-md">
			<Layout.Content className="px-6 pt-4">
				<div className="flex flex-col gap-5">
					<div className="w-full h-12 bg-zinc-100 border border-zinc-200 rounded-lg flex items-center pl-5">
						<Breadcrumb className="text-base" items={breadcrumbItems} />
					</div>
					<div className="flex justify-between items-center">
						<span className="text-lg text-neutral-800">My Accounts</span>
						<div className="flex gap-2">
							{selectedIntegration && isIntegrationConfigConnectable(selectedIntegration) ? (
								<Button
									onClick={() => handleConnect(selectedIntegration)}
									type="primary"
									className="bg-black"
									icon={<PlusOutlined />}
								>
									Add Account
								</Button>
							) : (
								<Tooltip title="If you wish to add an account, please contact the Token team.">
									<Button disabled={true} type="primary" className="bg-black" icon={<PlusOutlined />}>
										Add Account
									</Button>
								</Tooltip>
							)}
							<Input
								value={searchText}
								size="small"
								onChange={(e) => {
									setSearchText(e.target.value)
								}}
								allowClear={true}
								placeholder="Search"
								className="mr-4"
								prefix={<SearchOutlined />}
							/>
						</div>
					</div>
					<div className="flex flex-wrap gap-5 bg-white border border-zinc-100 rounded-lg p-6">
						{isLoading ? (
							<IntegrationsSkeleton />
						) : isError ? (
							<div>Error loading environments</div>
						) : (
							<SinglePageIntegrationBody
								integrationEnvironmentType={integrationEnvironmentType}
								singlePageFilteredEnvironments={filteredEnvironments}
								singlePageEnvironments={
									environments[integrationEnvironmentType as keyof ServerEnvironments]
								}
							/>
						)}
					</div>
				</div>
			</Layout.Content>
			<Modal
				open={isModalVisible}
				onCancel={handleCloseModal}
				footer={null}
				width={530}
				className="integrationModal"
				destroyOnClose={true}
			>
				{selectedIntegration && isIntegrationConfigConnectable(selectedIntegration) && (
					<EnvironmentIntegrationForm
						key={selectedIntegration.key}
						integration={selectedIntegration}
						onSubmit={handleSubmit}
						onClose={handleCloseModal}
						isConnecting={isCreatingEnvironment}
						onTestConnection={handleTestConnection}
						isTestingConnection={testConnection.isLoading}
					/>
				)}
			</Modal>
		</Layout>
	)
}
