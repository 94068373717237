import { AccountPriorityEnum } from '../../schemas/environments/accountPriorityEnum.ts'
import { ResourceType } from '../../schemas/issue.ts'

export const environments = [
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0191',
		name: 'prod-aks-app',
		environment_type: ResourceType.AZURE,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 4418,
		priority: AccountPriorityEnum.CRITICAL,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0192',
		name: 'synergy-sandbox-app',
		environment_type: ResourceType.AZURE,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 235,
		priority: AccountPriorityEnum.MEDIUM,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0193',
		name: 'AWS Prod',
		environment_type: ResourceType.AWS,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 4866,
		priority: AccountPriorityEnum.HIGH,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0194',
		name: 'AWS dev',
		environment_type: ResourceType.AWS,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 231,
		priority: AccountPriorityEnum.MEDIUM,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0195',
		name: 'Innovatech Solutions Prod',
		environment_type: ResourceType.AWS,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 538,
		priority: AccountPriorityEnum.HIGH,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0196',
		name: 'Nexovate',
		environment_type: ResourceType.AWS,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 75,
		priority: AccountPriorityEnum.MEDIUM,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0197',
		name: 'SA OpsAccount',
		environment_type: ResourceType.AWS,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 12,
		priority: AccountPriorityEnum.LOW,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0198',
		name: 'prod',
		environment_type: ResourceType.AWS,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 451,
		priority: AccountPriorityEnum.CRITICAL,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0199',
		name: 'Synergy Databricks Account',
		environment_type: ResourceType.DATABRICKS,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 624,
		priority: AccountPriorityEnum.MEDIUM,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0200',
		name: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		environment_type: ResourceType.ENTRA_ID,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 513,
		priority: AccountPriorityEnum.MEDIUM,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0201',
		name: 'nayak-prometheus-migration',
		environment_type: ResourceType.GCP,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 157,
		priority: AccountPriorityEnum.HIGH,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0202',
		name: 'organic-search-1',
		environment_type: ResourceType.GCP,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 9,
		priority: AccountPriorityEnum.LOW,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0203',
		name: 'prj-terraform-gwwsa',
		environment_type: ResourceType.GCP,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 21,
		priority: AccountPriorityEnum.LOW,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0204',
		name: 'prod',
		environment_type: ResourceType.GCP,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 1327,
		priority: AccountPriorityEnum.CRITICAL,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0206',
		name: 'synergy',
		environment_type: ResourceType.GITHUB,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 183,
		priority: AccountPriorityEnum.HIGH,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0207',
		name: 'synergistic-sciences',
		environment_type: ResourceType.GITHUB,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 15,
		priority: AccountPriorityEnum.LOW,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0208',
		name: 'C03o59ber',
		environment_type: ResourceType.GOOGLE_WORKSPACE,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 1513,
		priority: AccountPriorityEnum.HIGH,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0209',
		name: '6110ef9a92b04899e1536eb9',
		environment_type: ResourceType.JUMPCLOUD,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 1101,
		priority: AccountPriorityEnum.MEDIUM,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0210',
		name: 'https://synergy.okta.com/',
		environment_type: ResourceType.OKTA,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 2975,
		priority: AccountPriorityEnum.LOW,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0211',
		name: 'uas74660.us-east-1',
		environment_type: ResourceType.SNOWFLAKE,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 823,
		priority: AccountPriorityEnum.CRITICAL,
	},
	{
		id: '93146e5f-20c2-4cdd-b899-6519e20b0211',
		name: 'synergy.com',
		environment_type: ResourceType.ACTIVE_DIRECTORY,
		created_at: '2024-05-29T08:44:02.600170Z',
		updated_at: '2024-07-25T06:31:47.078729Z',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		identity_count: 15,
		priority: AccountPriorityEnum.HIGH,
	},
]
