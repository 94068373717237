import { Input } from 'antd'
import SearchIcon from '../../assets/search_icon_20.svg?react'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket.ts'
import { ChangeEvent, useCallback, useEffect } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../RoutePaths.tsx'
import { inventoryUrlQuickSearchFilterParameter } from '../../schemas/identity.ts'
import { TableTextFilterEntry, TableTextFilterType } from '../../schemas/tableFilter.ts'
import { isEmpty } from 'lodash'
import { useIdentitiesContext } from './context/IdentitiesProvider.tsx'

export const IdentitiesQuickSearch = () => {
	const { searchText, setSearchText } = useIdentitiesContext()
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const { identityFilter: searchIdentityFilter } = useSearch({ from: RoutePaths.Identities })
	const onFilterTextBoxChanged = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			trackEvent(LogRocketTrackEvent.IssuesTextSearchChanged, {})
			setSearchText(e.currentTarget.value)
		},
		[setSearchText],
	)

	useEffect(() => {
		const filterSearchText = searchIdentityFilter?.[inventoryUrlQuickSearchFilterParameter] as TableTextFilterEntry
		setSearchText(filterSearchText?.filter ?? '')
	}, [searchIdentityFilter])

	useEffect(() => {
		const filterSetTimeout = setTimeout(() => {
			void navigate({
				search: (prev) => {
					const newSearch = { ...prev }
					const newIdentityFilter = { ...prev.identityFilter }
					if (!searchText) {
						// This is safe since inventoryUrlQuickSearchFilterParameter is a runtime constant
						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete newIdentityFilter[inventoryUrlQuickSearchFilterParameter]
					} else {
						newIdentityFilter[inventoryUrlQuickSearchFilterParameter] = {
							filterType: 'text',
							type: TableTextFilterType.CONTAINS,
							filter: searchText,
						}
					}

					if (isEmpty(newIdentityFilter)) {
						delete newSearch.identityFilter
					} else {
						newSearch.identityFilter = newIdentityFilter
					}

					return newSearch
				},
			})
		}, 300)

		return () => {
			clearTimeout(filterSetTimeout)
		}
	}, [searchText])

	return (
		<Input
			value={searchText}
			size="middle"
			placeholder="Search"
			onChange={onFilterTextBoxChanged}
			allowClear={true}
			prefix={<SearchIcon />}
		/>
	)
}
