import React from 'react'
import { AssociationTypeEnum } from '../../schemas/identities/usageAssociationItemSchema'
import EC2Icon from '../../assets/ec2.svg?react'
import UnknownIcon from '../../assets/unknown.svg?react'
import NATIcon from '../../assets/nat_GW.svg?react'
import HumanIcon from '../../assets/user.svg?react'
import GlobalIcon from '../../assets/service_account_icon_20.svg?react'

export const UsageAssociationIcon: React.FunctionComponent<{
	name?: string | null
	color?: string
	size?: number
	style?: React.CSSProperties
}> = ({ name, color = '#E4750D', size = 28, style }) => {
	let IconComponent
	switch (name) {
		case AssociationTypeEnum.EC2:
			IconComponent = EC2Icon
			break
		case AssociationTypeEnum.NAT_GW:
			IconComponent = NATIcon
			break
		case AssociationTypeEnum.GLOBAL:
			IconComponent = GlobalIcon
			break
		case AssociationTypeEnum.HUMAN:
			IconComponent = HumanIcon
			break
		case AssociationTypeEnum.UNKNOWN:
			IconComponent = UnknownIcon
			break

		default:
			IconComponent = UnknownIcon
	}

	return <IconComponent style={{ color, width: size, height: size, ...style }} />
}
