import React from 'react'
import SlackMarketplaceImage from '../../../../assets/IntegrationPage/SlackIntegrationModalContent/SlackIntegrationModalContentStep1.svg?react'
import ChannelSelectionImage from '../../../../assets/IntegrationPage/SlackIntegrationModalContent/SlackIntegrationModalContentStep2.svg?react'
import TestConnectionImage from '../../../../assets/IntegrationPage/SlackIntegrationModalContent/SlackIntegrationModalContentStep3.svg?react'
import FinalizeConfigImage from '../../../../assets/IntegrationPage/SlackIntegrationModalContent/SlackIntegrationModalContentStep4.svg?react'
import { useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../../RoutePaths.tsx'

interface IntegrationStepProps {
	image: React.ReactNode
	title: string
	description: React.ReactNode | string
	stepNumber: number
}

const IntegrationStep = ({ image, title, description, stepNumber }: IntegrationStepProps) => {
	return (
		<div className="flex flex-col items-center p-4 bg-neutral-100 rounded-lg border border-neutral-200">
			<div className="mb-3  w-full flex items-center justify-center">{image}</div>
			<div className="space-y-1.5 w-full">
				<div className="text-sm font-medium max-w-full">
					{stepNumber}. {title}
				</div>
				<p className="text-sm text-gray-600">{description}</p>
			</div>
		</div>
	)
}

export const SlackIntegrationModalContent = () => {
	const navigate = useNavigate()

	const handleSlackIntegration = () => {
		navigate({ to: RoutePaths.SlackIntegration })
	}

	return (
		<div className="px-1 pt-2">
			<div className="mb-6">
				<h2 className="text-sm font-semibold mb-1">Enable Token Security Alerts in Your Slack Channel</h2>
				<p className="text-gray-600">
					Stay informed with notifications from Token Security directly in your Slack channel. Receive instant
					alerts about security issues, empowering your team to respond swiftly and maintain a robust security
					posture.
				</p>
			</div>

			<div className="bg-neutral-100 rounded-lg p-2 mb-6 border border-neutral-300">
				<ul className="list-disc pl-2 space-y-2">
					<li>
						<span className="font-medium">Admin Access: </span>
						<span className="text-gray-600">
							Verify you have administrative rights to your company's Token Security account.
						</span>
					</li>
					<li>
						<span className="font-medium">Slack Permissions: </span>
						<span className="text-gray-600">
							Ensure you have the necessary permissions to add integrations to your desired Slack channel.
						</span>
					</li>
				</ul>
			</div>

			<div className="grid grid-cols-4 gap-4">
				<IntegrationStep
					stepNumber={1}
					image={<SlackMarketplaceImage className="w-full h-auto" />}
					title="Install the Token app from the Slack marketplace"
					description={
						<>
							Visit the{' '}
							<a href="#" className="text-blue-600 hover:underline" onClick={handleSlackIntegration}>
								Slack marketplace
							</a>
						</>
					}
				/>
				<IntegrationStep
					stepNumber={2}
					image={<ChannelSelectionImage className="w-full h-auto" />}
					title="Choose Your Channel"
					description="Select the specific Slack channel where you want Token Security alerts to appear."
				/>
				<IntegrationStep
					stepNumber={3}
					image={<TestConnectionImage className="w-full h-auto" />}
					title="Test Connection"
					description='Click the "Test Connection" button and check your chosen channel connection.'
				/>
				<IntegrationStep
					stepNumber={4}
					image={<FinalizeConfigImage className="w-full h-auto" />}
					title="Finalize Configuration 🎉"
					description="Complete the setup process. Token Security will now automatically send alerts to your designated Slack channel."
				/>
			</div>
		</div>
	)
}
