import { Node, NodeProps } from '@xyflow/react'
import { ServerGcpPrincipalInfoXc } from '../../../../schemas/identities/gcp/gcpProjectXcSchema.ts'
import GcpIcon from '../../../../assets/gcp_project_icon.svg?react'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode.tsx'

export type ConnectedGcpProjectNodeType = Node<{ principalInfoXc: ServerGcpPrincipalInfoXc }, 'connectedGcpProject'>
type ConnectedGcpProjectNodeProps = NodeProps<ConnectedGcpProjectNodeType>

export const ConnectedGcpProjectNode = ({ data: { principalInfoXc } }: ConnectedGcpProjectNodeProps) => (
	<BaseEnvironmentNode
		label="GCP Project"
		name={principalInfoXc.project.displayName ? { text: principalInfoXc.project.displayName } : null}
		icon={<GcpIcon />}
	/>
)
