import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { Layout, Menu, MenuProps, Button, ConfigProvider, Skeleton } from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { IdentitiesPageLens } from '../../../schemas/identity.ts'
import { isDemo } from '../../../utils/demoUtils.ts'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { useIdentitiesContext } from '../context/IdentitiesProvider.tsx'
import ResourcesIcon from '../../../assets/resources.svg?react'
import CloudIcon from '../../../assets/cloud_icon_20.svg?react'
import AwsIcon from '../../../assets/aws_icon.svg?react'
import GcpIcon from '../../../assets/gcp_icon_20.svg?react'
import AzureIcon from '../../../assets/azure_icon.svg?react'
import OktaIcon from '../../../assets/okta_icon_no_color.svg?react'
import JumpcloudIcon from '../../../assets/jumpcloud_icon_16.svg?react'
import EntraIdIcon from '../../../assets/entra_id_icon_16.svg?react'
import GoogleWorkspaceIcon from '../../../assets/google_workspace_logo_16.svg?react'
import DatastoresIcon from '../../../assets/hard_drives.svg?react'
import PostgresIcon from '../../../assets/postgres_icon_20.svg?react'
import SnowflakeIcon from '../../../assets/snowflake_icon.svg?react'
import DatabricksIcon from '../../../assets/databricks_icon.svg?react'
import WorkloadsIcon from '../../../assets/workloads.svg?react'
import KubernetesIcon from '../../../assets/kubernetes_icon_20.svg?react'
import OffBoardedIcon from '../../../assets/off_boarded_icon_20.svg?react'
import AccessKeysIcon from '../../../assets/access_keys_icon_20.svg?react'
import UnfederatedIcon from '../../../assets/unfederated_identity_16.svg?react'
import RecentlyCreatedIcon from '../../../assets/recently_created.svg?react'
import MostWantedIcon from '../../../assets/most_wanted.svg?react'
import OverPrivilegedIcon from '../../../assets/over_privileged_identities.svg?react'
import ThirdPartyIcon from '../../../assets/third_party.svg?react'
import GithubIcon from '../../../assets/github_icon_20.svg?react'
import AzureDevopsIcon from '../../../assets/azure_devops_logo_16.svg?react'
import CICDIcon from '../../../assets/ci_cd_16.svg?react'
import CorporateSaasIcon from '../../../assets/corporate_saas_16.svg?react'
import SalesforceIcon from '../../../assets/salesforce_icon.svg?react'
import { useIdentityLensStats } from '../../../api/identities.ts'
import { themeColors } from '../../../utils/colorUtils.ts'

type MenuItem = Required<MenuProps>['items'][number]

interface MenuItemLabelProps {
	children: React.ReactNode
	count?: number
	isFolder?: boolean
}

interface MenuItemConfig {
	key: IdentitiesPageLens
	label: string
	icon: React.ComponentType<{ className?: string }>
	isDemoOnly?: boolean
}

interface MenuCategory {
	key: string
	label: string
	icon: React.ComponentType<{ className?: string }>
	items: MenuItemConfig[]
}

const MENU_STRUCTURE: MenuCategory[] = [
	{
		key: 'cloud-providers',
		label: 'Cloud Providers',
		icon: CloudIcon,
		items: [
			{ key: IdentitiesPageLens.AWS, label: 'AWS', icon: AwsIcon },
			{ key: IdentitiesPageLens.GCP, label: 'GCP', icon: GcpIcon },
			{ key: IdentitiesPageLens.AZURE, label: 'Azure', icon: AzureIcon },
		],
	},
	{
		key: 'identity-providers',
		label: 'Identity Providers',
		icon: TeamOutlined,
		items: [
			{ key: IdentitiesPageLens.OKTA, label: 'Okta', icon: OktaIcon },
			{ key: IdentitiesPageLens.JUMPCLOUD, label: 'Jumpcloud', icon: JumpcloudIcon },
			{ key: IdentitiesPageLens.ENTRA_ID, label: 'Entra ID', icon: EntraIdIcon },
			{ key: IdentitiesPageLens.GOOGLE_WORKSPACE, label: 'Google Workspace', icon: GoogleWorkspaceIcon },
		],
	},
	{
		key: 'databases',
		label: 'Databases',
		icon: DatastoresIcon,
		items: [
			{ key: IdentitiesPageLens.POSTGRES, label: 'Postgres', icon: PostgresIcon },
			{ key: IdentitiesPageLens.SNOWFLAKE, label: 'Snowflake', icon: SnowflakeIcon },
			{ key: IdentitiesPageLens.DEMO_DATABRICKS, label: 'Databricks', icon: DatabricksIcon, isDemoOnly: true },
		],
	},
	{
		key: 'workloads',
		label: 'Workloads',
		icon: WorkloadsIcon,
		items: [{ key: IdentitiesPageLens.KUBERNETES, label: 'Kubernetes', icon: KubernetesIcon }],
	},
	{
		key: 'ci/cd',
		label: 'CI/CD',
		icon: CICDIcon,
		items: [
			{ key: IdentitiesPageLens.AZURE_DEVOPS, label: 'Azure Devops', icon: AzureDevopsIcon },
			{ key: IdentitiesPageLens.GITHUB, label: 'Github', icon: GithubIcon },
		],
	},
	{
		key: 'saas',
		label: 'Corporate SaaS',
		icon: CorporateSaasIcon,
		items: [{ key: IdentitiesPageLens.SALESFORCE, label: 'Salesforce', icon: SalesforceIcon }],
	},
]

const STANDALONE_ITEMS: MenuItemConfig[] = [
	{ key: IdentitiesPageLens.OFF_BOARDED_EMPLOYEES, label: 'Off-Boarded Employees', icon: OffBoardedIcon },
	{ key: IdentitiesPageLens.MULTIPLE_ACCESS_KEYS, label: 'Multiple Access Keys', icon: AccessKeysIcon },
	{ key: IdentitiesPageLens.UNFEDERATED_IDENTITIES, label: 'Unfederated Identities', icon: UnfederatedIcon },
	{ key: IdentitiesPageLens.RECENTLY_CREATED, label: 'Recently Created Identities', icon: RecentlyCreatedIcon },
	{ key: IdentitiesPageLens.MOST_WANTED, label: 'Most Wanted Identities', icon: MostWantedIcon },
	{
		key: IdentitiesPageLens.DEMO_OVER_PRIVILEGED_IDENTITY,
		label: 'Over-Privileged Identities',
		icon: OverPrivilegedIcon,
		isDemoOnly: true,
	},
	{
		key: IdentitiesPageLens.DEMO_IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR,
		label: '3rd Party Identities',
		icon: ThirdPartyIcon,
		isDemoOnly: true,
	},
]

const shouldShowMenuItem = (item: MenuItemConfig, count: number | undefined, isDemo: boolean): boolean => {
	// Don't show demo items in non-demo mode
	if (item.isDemoOnly && !isDemo) {
		return false
	}

	// Don't show items with count of 0 or undefined
	return count !== undefined && count > 0
}

const MenuItemLabel = ({ children, count, isFolder = false }: MenuItemLabelProps) => (
	<div className="flex w-full items-center">
		<span className="truncate">{children}</span>
		{!isFolder && <span className="ml-auto pl-1 text-sm shrink-0">{`(${count || 0})`}</span>}
	</div>
)

const MenuItemIcon = ({ Icon, ...rest }: { Icon: React.ComponentType<{ className?: string }> }) => (
	<span {...rest}>
		<Icon className="w-5 h-5 !text-base" />
	</span>
)

const siderStyle: React.CSSProperties = {
	lineHeight: '120px',
	backgroundColor: '#353535',
	boxShadow: '0px 0px 10px 0px #0000001A',
}

export const IdentitiesSideBar = () => {
	const { lens, tab } = useSearch({ from: RoutePaths.Identities })
	const { gridRef } = useIdentitiesContext()
	const [isCollapsed, setIsCollapsed] = useState(false)
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const [openKeys, setOpenKeys] = useState<string[]>([])

	useEffect(() => {
		const columnFitTimeout = setTimeout(() => {
			gridRef.current?.api?.sizeColumnsToFit()
		}, 200)

		return () => {
			clearTimeout(columnFitTimeout)
		}
	}, [isCollapsed])

	const onClick: MenuClickEventHandler = (menuEntry) => {
		const newLens: IdentitiesPageLens | undefined = Object.values(IdentitiesPageLens).includes(
			menuEntry.key as IdentitiesPageLens,
		)
			? (menuEntry.key as IdentitiesPageLens)
			: undefined

		void navigate({
			search: {
				tab,
				...(newLens && { lens: newLens }),
			},
		})
	}
	const { data: lensStats, isLoading } = useIdentityLensStats()
	const items = useMemo(() => {
		const menuItems: MenuItem[] = []

		menuItems.push({
			key: IdentitiesPageLens.ALL,
			label: <MenuItemLabel count={lensStats?.[IdentitiesPageLens.ALL]}>All</MenuItemLabel>,
			icon: <MenuItemIcon Icon={ResourcesIcon} />,
		})

		MENU_STRUCTURE.forEach((category) => {
			const validItems = category.items
				.filter((item) => shouldShowMenuItem(item, lensStats?.[item.key], isDemo))
				.map((item) => ({
					key: item.key,
					label: <MenuItemLabel count={lensStats?.[item.key]}>{item.label}</MenuItemLabel>,
					icon: <MenuItemIcon Icon={item.icon} />,
				}))

			if (validItems.length > 0) {
				menuItems.push({
					key: category.key,
					label: <MenuItemLabel isFolder>{category.label}</MenuItemLabel>,
					icon: <MenuItemIcon Icon={category.icon} />,
					children: validItems,
				})
			}
		})

		STANDALONE_ITEMS.forEach((item) => {
			if (shouldShowMenuItem(item, lensStats?.[item.key], isDemo)) {
				menuItems.push({
					key: item.key,
					label: <MenuItemLabel count={lensStats?.[item.key]}>{item.label}</MenuItemLabel>,
					icon: <MenuItemIcon Icon={item.icon} />,
				})
			}
		})

		return menuItems
	}, [lensStats])

	const siderHeaderClassName = cx('h-14 px-2 mx-2 text-white flex items-center border-b border-gray-500 mb-2', {
		'justify-between': !isCollapsed,
		'justify-center': isCollapsed,
	})

	useEffect(() => {
		if (!lens) {
			return
		}

		const parentCategory = MENU_STRUCTURE.find((category) => category.items.some((item) => item.key === lens))

		if (parentCategory) {
			setOpenKeys((currentOpenKeys) => [...currentOpenKeys, parentCategory.key])
		}
	}, [lens])

	const toggleSiderCollapse = useCallback(() => {
		setIsCollapsed((currentIsCollapsed) => !currentIsCollapsed)
	}, [setIsCollapsed])
	return (
		<Layout.Sider
			collapsed={isCollapsed}
			collapsedWidth={64}
			className="overflow-y-auto"
			width="15%"
			style={siderStyle}
		>
			<div className={siderHeaderClassName}>
				{!isCollapsed && 'Inventory view'}
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: '#7C987D',
						},
					}}
				>
					<Button
						ghost
						icon={isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
						onClick={toggleSiderCollapse}
					/>
				</ConfigProvider>
			</div>
			{isLoading ? (
				<div className="px-4">
					<div className="py-2">
						<ConfigProvider
							theme={{
								token: {
									colorFillContent: themeColors.neutral[500],
								},
							}}
						>
							<Skeleton active paragraph={{ rows: 12 }} className="!text-gray-100" />
						</ConfigProvider>
					</div>
				</div>
			) : (
				<Menu
					onClick={onClick}
					className="bg-[#353535] text-[#BABBBF]"
					selectedKeys={lens ? [lens] : [IdentitiesPageLens.ALL]}
					openKeys={openKeys}
					onOpenChange={setOpenKeys}
					mode="inline"
					theme={'dark'}
					items={items}
				/>
			)}
		</Layout.Sider>
	)
}
