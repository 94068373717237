import { FilterChipValue } from './FilterChipValue.tsx'
import { TableNumberFilterEntry, TableNumberFilterType } from '../../../schemas/tableFilter.ts'

const TableNumberFilterToChipValue: Record<TableNumberFilterType, (value: number) => string> = {
	[TableNumberFilterType.EQUALS]: (value: number) => `=${value}`,
	[TableNumberFilterType.NOT_EQUAL]: (value: number) => `≠${value}`,
	[TableNumberFilterType.GREATER_THAN]: (value: number) => `>${value}`,
	[TableNumberFilterType.GREATER_THAN_OR_EQUAL]: (value: number) => `>=${value}`,
	[TableNumberFilterType.LESS_THAN]: (value: number) => `<${value}`,
	[TableNumberFilterType.LESS_THAN_OR_EQUAL]: (value: number) => `<=${value}`,
}

type NumericFilterChipProps = {
	filter: TableNumberFilterEntry
}

export const NumericFilterChip = ({ filter }: NumericFilterChipProps) => {
	if (filter.type in TableNumberFilterToChipValue) {
		return <FilterChipValue title={TableNumberFilterToChipValue[filter.type](filter.filter)} />
	} else {
		console.error(`Unsupported number filter type: ${filter.type}`)
		return <FilterChipValue title={filter.filter.toString()} />
	}
}
