import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { EdgeType, getEdge } from '../graphUtils/nodesAndEdges.ts'
import { DatabricksPATNodeType } from './DatabricksPATNode.tsx'
import { DatabricksOAuthNodeType } from './DatabricksOAuthNode.tsx'
import { DatabricksRoleNodeType } from './DatabricksRoleNode.tsx'
import { ServerKubernetesResourceXc } from '../../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { Ec2InstancesNodeType } from '../aws/Ec2InstancesNode.tsx'
import { KubernetesResourcesNodeType } from '../common/KubernetesResourcesNode.tsx'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import { ServerAwsIamChangeLog } from '../../../../schemas/identities/awsIamChangeLogSchema.ts'
import { aggregateAwsIamChangeLogs } from '../../../../utils/awsIdentityUtils.ts'
import { getActorArnFriendlyName } from '../../../../components/drawers/identities/utils.tsx'

const nodeLogicalTypeToColumnId = {
	k8s: 0,
	generalIssue: 1,
	identity: 2,
	oauthTokens: 3,
	personalAccessToken: 4,
	role: 5,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

export const getDatabricksNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Array<EdgeType>] => {
	const edges: Array<EdgeType> = []
	const identityNodes: Array<BareNodeType<IdentityNodeType> | BareNodeType<OwnershipNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const changeLogs: ServerAwsIamChangeLog[] = identity.databricks_service_principal?.change_logs || []
	if (changeLogs?.length) {
		const aggregatedChangeLogs = aggregateAwsIamChangeLogs(changeLogs)
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: aggregatedChangeLogs.map((aggChangeLog) => ({
					id: aggChangeLog.actorArn,
					name: getActorArnFriendlyName(aggChangeLog.actorArn),
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})

	const patNodes: BareNodeType<DatabricksPATNodeType>[] =
		identity.databricks_user?.personal_access_tokens?.map((pat, index) => ({
			type: 'pat',
			data: { pat },
			id: `${nodeLogicalTypeToColumnId.personalAccessToken}-${index}`,
		})) || []

	const k8sNodes: Array<BareNodeType<Ec2InstancesNodeType> | BareNodeType<KubernetesResourcesNodeType>> = []
	const kubernetesResources: ServerKubernetesResourceXc[] =
		identity.databricks_service_principal?.kubernetes_resources_xc || []
	if (kubernetesResources.length) {
		k8sNodes.push({
			type: 'kubernetesResources',
			data: { resources: kubernetesResources },
			id: `${nodeLogicalTypeToColumnId.k8s}-${k8sNodes.length}`,
		})
	}

	const oauthNodes: BareNodeType<DatabricksOAuthNodeType>[] =
		identity.databricks_service_principal?.oauth_tokens?.map((oauthToken, index) => ({
			type: 'oauthToken',
			data: { oauthToken },
			id: `${nodeLogicalTypeToColumnId.oauthTokens}-${index}`,
		})) || []

	const roles = identity.databricks_service_principal?.roles || identity.databricks_user?.roles || []
	const rolesNodes: BareNodeType<DatabricksRoleNodeType>[] =
		roles.map((databricksRole, index) => ({
			type: 'databricksRole',
			data: { databricksRole },
			id: `${nodeLogicalTypeToColumnId.role}-${index}`,
		})) || []

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	patNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.personalAccessToken}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	oauthNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.oauthTokens}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	rolesNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.role}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	kubernetesResources.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.k8s}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'top', nodes: k8sNodes },
		{ yPosition: 'center', nodes: generalIssueNodes },
		{ yPosition: 'top', nodes: patNodes },
		{ yPosition: 'top', nodes: oauthNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: rolesNodes },
	]
	return [nodes, edges]
}
