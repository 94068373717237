import { issuePrioritySorter } from '../../../../../utils/issueUtils'
import { IssueNodeType } from '../../common/IssueNode'
import { BareNodesColumnsType, BareNodeType } from '../../identityGraphTypes'
import { IdentityNodeType } from '../../common/IdentityNode'
import { ServerIdentity } from '../../../../../schemas/identity'
import { EdgeType, getEdge } from '../../graphUtils/nodesAndEdges'
import { AzureDevopsPatNodeType } from './AzureDevopsPatNode'
import { EntraIDUserNodeType } from '../../entraId/EntraIDUserNode'
import { EntraIDServicePrincipalNodeType } from '../../entraId/EntraIDServicePrincipalNode'
import { IssueName } from '../../../../../schemas/issue'

const nodeLogicalTypeToColumnId = {
	patIssue: 0,
	generalIssue: 1,
	pat: 2,
	identity: 3,
}

const identityNodeRowIndex = 0
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`
const entraIdNodeRowIndex = 1
const entraIdNodeId = `${nodeLogicalTypeToColumnId.identity}-${entraIdNodeRowIndex}`

const patIssueNames: IssueName[] = [
	IssueName.UnrotatedPAT,
	IssueName.OverExtendedPAT,
	// Add other PAT-related issue names here
]

export const getAzureDevOpsNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Array<EdgeType>] => {
	const edges: Array<EdgeType> = []
	const nodes: Array<
		| BareNodeType<IdentityNodeType>
		| BareNodeType<EntraIDUserNodeType>
		| BareNodeType<EntraIDServicePrincipalNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	// Add Entra ID relationship based on identity type
	if (identity.azure_devops_user?.entra_id_user) {
		// For Azure DevOps Users
		const entraIdUser = identity.azure_devops_user.entra_id_user
		nodes.push({
			type: 'entraIDUser',
			data: {
				user: {
					principalName: entraIdUser.user_principal_name,
					objectId: entraIdUser.entra_user_id,
				},
			},
			id: entraIdNodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: entraIdNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	} else if (identity.azure_devops_service_principal?.entra_id_service_principal) {
		// For Azure DevOps Service Principals
		const entraIdSp = identity.azure_devops_service_principal.entra_id_service_principal
		nodes.push({
			type: 'entraIDServicePrincipal',
			data: {
				servicePrincipal: {
					principalName: entraIdSp.display_name ?? 'Unknown Service Principal',
					objectId: entraIdSp.service_principal_id,
				},
			},
			id: entraIdNodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: entraIdNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	// Separate PAT-related issues from general issues
	const patIssueNodes: BareNodeType<IssueNodeType>[] = []
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []

	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issue_name && patIssueNames.includes(issue.issue_name)) {
			patIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.patIssue}-${patIssueNodes.length}`,
			})
		} else {
			generalIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
			})
		}
	})

	// Add PAT nodes (only for Azure DevOps Users)
	const patNodes: BareNodeType<AzureDevopsPatNodeType>[] = []
	if (identity.azure_devops_user) {
		identity.azure_devops_user.personal_access_tokens?.forEach((pat, index) => {
			patNodes.push({
				type: 'azureDevopsPat',
				data: { pat },
				id: `${nodeLogicalTypeToColumnId.pat}-${index}`,
			})
		})

		// Connect PATs to identity
		patNodes.forEach((_, index) => {
			const source = `${nodeLogicalTypeToColumnId.pat}-${index}`
			edges.push(getEdge({ source, target: identityNodeId }))
		})

		// Connect PAT issue nodes to:
		//  - PAT nodes, if the issue is for specific keys.
		//  - The identity node if there is no relevant PAT node.
		patIssueNodes.forEach((issueNode, issueIndex) => {
			const source = `${nodeLogicalTypeToColumnId.patIssue}-${issueIndex}`
			const patNodeIndices: number[] = []
			// Find every relevant PAT for this specific issue.
			patNodes.forEach((patNode, patIndex) => {
				if (issueNode.data.issue.description?.includes(patNode.data.pat.display_name)) {
					patNodeIndices.push(patIndex)
				}
			})

			// If we found relevant PAT nodes - connect the issue to each of them.
			if (patNodeIndices.length > 0) {
				patNodeIndices.forEach((patNodeIndex) => {
					const target = `${nodeLogicalTypeToColumnId.pat}-${patNodeIndex}`
					edges.push(getEdge({ source, target }))
				})
			} else {
				// If no relevant PATs are found for this issue - connect it to the identity node.
				edges.push(getEdge({ source, target: identityNodeId }))
			}
		})
	}

	// Connect general issues to identity
	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	const columns: BareNodesColumnsType[] = [
		{ yPosition: 'center', nodes: [...patIssueNodes, ...generalIssueNodes] },
		{ yPosition: generalIssueNodes.length > 0 ? 'top' : 'center', nodes: patNodes },
		{ yPosition: 'center', nodes },
	]

	return [columns, edges]
}
