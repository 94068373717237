import { z } from 'zod'
import { DateSchema } from '../../common'
import { TagSchema } from '../../tags'
import { ServerGcpPrincipalInfoXcSchema } from './gcpProjectXcSchema.ts'
import { ServerGcpProjectSchema } from '../../environments/gcpProject.ts'
import { ServerEndpointAccessXcSchema } from '../endpointAccessXcSchema.ts'
import { ServerAwsUsageLogSchema } from '../awsIamUserSchema.ts'
import { ServerAwsIamChangeLogSchema } from '../awsIamChangeLogSchema.ts'
import { ServerKubernetesResourceXcSchema } from '../kubernetes/kubernetesResourceXcSchema.ts'

export const GcpServiceAccountKeySchema = z
	.object({
		disabled: z.boolean(),
		keyAlgorithm: z.string(),
		keyOrigin: z.string(),
		keyType: z.string(),
		name: z.string(),
		validAfterTime: DateSchema,
		validBeforeTime: DateSchema,
	})
	.transform((data) => ({
		...data,
		accessKeyId: data.name.split('/').pop() || '',
	}))

export type GcpServiceAccountKey = z.infer<typeof GcpServiceAccountKeySchema>

export const ServerGcpRoleSchema = z.object({
	id: z.string(),
	customer_id: z.string(),
	environment_id: z.string(),
	name: z.string(),
	title: z.string(),
	description: z.string().nullable(),
	included_permissions: z.array(z.string()),
	stage: z.string().nullable(),
	deleted: z.boolean(),
	system_created_at: z.string().datetime(),
	system_updated_at: z.string().datetime(),
	tags: z.array(TagSchema),
})

export type ServerGcpRole = z.infer<typeof ServerGcpRoleSchema>

export const GcpServiceAccountProjectRoleSchema = z.object({
	id: z.string(),
	service_account_row_id: z.string(),
	role_row_id: z.string(),
	project_row_id: z.string(),
	deleted: z.boolean(),
	role: ServerGcpRoleSchema,
	project: ServerGcpProjectSchema,
})

export type ServerGcpServiceAccountProjectRole = z.infer<typeof GcpServiceAccountProjectRoleSchema>
export const ServerGcpServiceAccountSchema = z.object({
	name: z.string().nullish(),
	disabled: z.boolean().nullish(),
	environment_id: z.string(),
	unique_id: z.string(),
	email: z.string(),
	service_account_keys_xc: GcpServiceAccountKeySchema.array().nullish(),
	last_activity: DateSchema.nullish(),
	gcp_project: ServerGcpProjectSchema.nullish(),
	service_account_projects_roles_xc: z.array(ServerGcpPrincipalInfoXcSchema).nullish(),
	endpoint_access_xc: z.array(ServerEndpointAccessXcSchema).nullish(),
	usage_logs: z.array(ServerAwsUsageLogSchema).nullish(),
	change_logs: z.array(ServerAwsIamChangeLogSchema).nullish(),
	kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(),
	role_mappings: z.array(GcpServiceAccountProjectRoleSchema),
})

export type ServerGcpServiceAccount = z.infer<typeof ServerGcpServiceAccountSchema>
