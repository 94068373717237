/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react'
import { ServerAwsUsageLog, ServerAwsUsageLogGrouped } from '../../../schemas/identities/awsIamUserSchema.ts'
import { useHighlightedNodesContext } from '../../../routes/Identity/HighlightedNodesContext.tsx'
import { Collapse, CollapseProps, Space } from 'antd'
import UnknownIcon from '../../../assets/unkown_sidepnael.svg?react'
import GlobalIcon from '../../../assets/global_sidepanel.svg?react'
import HumanIcon from '../../../assets/human_sidepanel.svg?react'
import EC2Icon from '../../../assets/ec2_sidepanel.svg?react'
import NatGWIcon from '../../../assets/natgw_sidepanel.svg?react'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel'
import { AssociationTypeEnum, UsageAssociationItem } from '../../../schemas/identities/usageAssociationItemSchema.ts'
import { AwsUsageLogsContent } from './AwsUsageLogsContent.tsx'

type UsageLogsGroupedCollapseItem = Required<CollapseProps>['items'][number] & {
	key: keyof typeof AssociationTypeEnum
}

type SidePanelUsageLogsGroupedProps = {
	activeUsageLogsGroupedKeys: (keyof typeof AssociationTypeEnum)[]
	setActiveUsageLogsGroupedKeys: (keys: (keyof typeof AssociationTypeEnum)[]) => void
	usageLogsGrouped: ServerAwsUsageLogGrouped[]
	usageLogs: ServerAwsUsageLog[]
	environmentId: string
	subEnvironmentId: string
	identityArn: string
}

type AssociationAccumulator = Partial<{
	[K in AssociationTypeEnum]: { instances_count: number; total_events_count: number }
}>

const IconsMap = {
	[AssociationTypeEnum.NAT_GW]: NatGWIcon,
	[AssociationTypeEnum.EC2]: EC2Icon,
	[AssociationTypeEnum.HUMAN]: HumanIcon,
	[AssociationTypeEnum.GLOBAL]: GlobalIcon,
	[AssociationTypeEnum.UNKNOWN]: UnknownIcon,
}

export const AwsUsageLogsGroupedContent = ({
	activeUsageLogsGroupedKeys,
	setActiveUsageLogsGroupedKeys,
	usageLogsGrouped,
	usageLogs,
	environmentId,
	subEnvironmentId,
	identityArn,
}: SidePanelUsageLogsGroupedProps) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()

	const onCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList =
				typeof key === 'string'
					? [key as keyof typeof AssociationTypeEnum]
					: (key as (keyof typeof AssociationTypeEnum)[])
			setActiveUsageLogsGroupedKeys(keyList)
		},
		[activeUsageLogsGroupedKeys],
	)

	const sumByType = useMemo(
		() =>
			usageLogsGrouped.reduce<AssociationAccumulator>((acc, item: { associations: UsageAssociationItem[] }) => {
				item.associations.forEach((assoc: UsageAssociationItem) => {
					if (!acc[assoc.name as AssociationTypeEnum]) {
						acc[assoc.name as AssociationTypeEnum] = {
							instances_count: 0,
							total_events_count: 0,
						}
					}

					acc[assoc.name as AssociationTypeEnum]!.instances_count += assoc.instances_count
					acc[assoc.name as AssociationTypeEnum]!.total_events_count += assoc.total_events_count
				})
				return acc
			}, {}),
		[usageLogsGrouped],
	)

	const items: UsageLogsGroupedCollapseItem[] = useMemo(() => {
		const collapseItems: UsageLogsGroupedCollapseItem[] = []
		Object.keys(sumByType).forEach((key) => {
			const typedKey = key as keyof typeof AssociationTypeEnum

			collapseItems.push({
				label: (
					<CollapsibleItemLabel
						icon={IconsMap[AssociationTypeEnum[typedKey]] || UnknownIcon}
						label={`${key} (${sumByType[`${AssociationTypeEnum[typedKey]}`]?.instances_count ?? 0})`}
						secondaryLabel={`${sumByType[`${AssociationTypeEnum[typedKey]}`]?.total_events_count ?? 0} Events`}
					/>
				),
				headerClass: '!items-center',
				key: typedKey,
				children: (
					<AwsUsageLogsContent
						environmentId={environmentId}
						subEnvironmentId={subEnvironmentId}
						identityArn={identityArn}
						usageLogs={usageLogs.filter(({ association_type }) => association_type === typedKey)}
					/>
				),
			})
		})

		return collapseItems
	}, [usageLogsGrouped, setHighlightedNodes])

	if (!usageLogsGrouped.length) {
		return 'No Usage Data Available'
	}

	return (
		<Space direction="vertical" className="w-full">
			{items.map((item) => (
				<Collapse
					onChange={onCollapseChange}
					activeKey={activeUsageLogsGroupedKeys}
					style={{ backgroundColor: 'white' }}
					expandIconPosition={'end'}
					items={[item]}
				/>
			))}
		</Space>
	)
}
