import { z } from 'zod'
import { ServerAdAceSchema } from './adAceSchema'
import { BaseEntraIdUserSchema } from '../entraId/entraIdUserSchema.ts'
import { ServerAdGroupSchema } from './adGroupSchema'
import { DemoServerAdOwnershipSchema } from './demoAdOwnershipSchema.ts'
import { DemoServerAdEndpointsSchema } from './demoAdEndpointsSchema.ts'
import { DemoServerAdServerSchema } from './demoAdServerSchema.ts'

export const BaseAdUserSchema = z.object({
	identity_id: z.string().nullish(), // For demo
	object_sid: z.string(),
	sam_account_name: z.string(),
	distinguished_name: z.string(),
	domain: z.string(),
	display_name: z.string(),
	description: z.string().nullable(),
	mail_address: z.string().nullable(),
	enabled: z.boolean(),
	last_logon: z.string().nullable(),
	pwd_last_set: z.string().nullable(),
	when_created: z.string(),
	inbound_permissions: z.array(ServerAdAceSchema).nullish(),
	outbound_permissions: z.array(ServerAdAceSchema).nullish(),
	group_memberships: z.array(z.string()).nullish(),
	groups: z.array(ServerAdGroupSchema).nullish(),
	demo_ownership: z.array(DemoServerAdOwnershipSchema).nullish(),
	demo_endpoints_dependencies: z.array(DemoServerAdEndpointsSchema).nullish(),
	demo_servers_dependencies: z.array(DemoServerAdServerSchema).nullish(),
})

export const ServerAdUserSchema = BaseAdUserSchema.extend({
	entra_id_user: z.lazy(() => BaseEntraIdUserSchema.nullish()),
})

export type ServerAdUser = z.infer<typeof ServerAdUserSchema>
