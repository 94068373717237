import { z } from 'zod'

export enum TableTextFilterType {
	CONTAINS = 'contains',
	NOT_CONTAINS = 'notContains',
	EQUALS = 'equals',
	NOT_EQUAL = 'notEqual',
	STARTS_WITH = 'startsWith',
	ENDS_WITH = 'endsWith',
}

export enum TableNumberFilterType {
	EQUALS = 'equals',
	NOT_EQUAL = 'notEqual',
	GREATER_THAN = 'greaterThan',
	GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
	LESS_THAN = 'lessThan',
	LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
}

export enum TableDateFilterType {
	GREATER_THAN = 'greaterThan',
	LESS_THAN = 'lessThan',
	IN_RANGE = 'inRange',
	BLANK = 'blank',
}

const TableTextFilterEntrySchema = z.object({
	filterType: z.literal('text'),
	type: z.nativeEnum(TableTextFilterType),
	filter: z.string(),
})

const TableNumberFilterEntrySchema = z.object({
	filterType: z.literal('number'),
	type: z.nativeEnum(TableNumberFilterType),
	filter: z.number(),
})

const BaseTableDateFilterEntrySchema = z.object({
	filterType: z.literal('date'),
})

const TableDateBlankFilterEntrySchema = BaseTableDateFilterEntrySchema.extend({
	type: z.literal(TableDateFilterType.BLANK),
})

const TableDateGreaterThanFilterEntrySchema = BaseTableDateFilterEntrySchema.extend({
	type: z.literal(TableDateFilterType.GREATER_THAN),
	dateFrom: z.string(),
})

const TableDateLessThanFilterEntrySchema = BaseTableDateFilterEntrySchema.extend({
	type: z.literal(TableDateFilterType.LESS_THAN),
	dateFrom: z.string(),
})

const TableDatInRangeFilterEntrySchema = BaseTableDateFilterEntrySchema.extend({
	type: z.literal(TableDateFilterType.IN_RANGE),
	dateFrom: z.string(),
	dateTo: z.string(),
})

const TableDateFilterEntrySchema = z.union([
	TableDateBlankFilterEntrySchema,
	TableDateGreaterThanFilterEntrySchema,
	TableDateLessThanFilterEntrySchema,
	TableDatInRangeFilterEntrySchema,
])

const TableSetFilterEntrySchema = z.object({
	filterType: z.literal('set'),
	values: z.array(z.string().or(z.null())),
})

export const TableFilterEntrySchema = z.union([
	TableTextFilterEntrySchema,
	TableNumberFilterEntrySchema,
	TableDateFilterEntrySchema,
	TableSetFilterEntrySchema,
])

export type TableTextFilterEntry = z.infer<typeof TableTextFilterEntrySchema>
export type TableNumberFilterEntry = z.infer<typeof TableNumberFilterEntrySchema>
export type TableDateFilterEntry = z.infer<typeof TableDateFilterEntrySchema>
export type TableSetFilterEntry = z.infer<typeof TableSetFilterEntrySchema>
export type TableFilterEntry = z.infer<typeof TableFilterEntrySchema>
