import { ServerAwsUsageLog } from '../../../schemas/identities/awsIamUserSchema.ts'
import { AgGridReact } from '@ag-grid-community/react'
import { useMemo } from 'react'
import { ColDef } from '@ag-grid-community/core'
import { Tooltip } from 'antd'

export type AwsUsageLogsContentEntry = ServerAwsUsageLog & {
	associations: string[]
}

type AwsUsageLogsAggregationsColumn = AwsUsageLogsContentEntry & {
	firstDay: Date
	lastDay: Date
}

export const AwsUsageLogsAggregationsTable: React.FunctionComponent<{
	entries: AwsUsageLogsContentEntry[]
}> = ({ entries }) => {
	const defaultColDef: ColDef = {
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	}

	const columnDefs: ColDef<AwsUsageLogsAggregationsColumn>[] = [
		{
			field: 'ip',
			headerName: 'IP',
		},
		{
			field: 'associations',
			headerName: 'Association',
			filter: 'agTextColumnFilter',
			flex: 0.7,
			valueFormatter: (params: { data?: AwsUsageLogsAggregationsColumn }) => {
				return params.data?.associations?.join(', ') || ''
			},
		},
		{
			field: 'events',
			headerName: 'Actions',
			filterParams: {
				valueFormatter: (params: { value: string }) => {
					return params.value
				},
				keyCreator: (params: { value: string }) => {
					return params.value
				},
			},
			valueFormatter: (params: { data?: AwsUsageLogsAggregationsColumn }) => {
				return params.data?.events?.join(', ') || ''
			},
			cellRenderer: (params: { value: string[] }) => {
				const stringValue = params.value.join(', ')
				if (params.value.length < 1) {
					return stringValue
				}

				return (
					<Tooltip
						placement="topLeft"
						title={
							<div className="flex flex-col">
								{params.value.map((action) => (
									<span key={action}>{action}</span>
								))}
							</div>
						}
					>
						<span>{stringValue}</span>
					</Tooltip>
				)
			},
		},
		{
			field: 'events_count',
			headerName: 'Events Count',
			sort: 'desc',
		},
		{
			field: 'aws_access_key_id',
			headerName: 'AWS Access Key ID',
		},
		{
			field: 'firstDay',
			headerName: 'First Day',
		},
		{
			field: 'lastDay',
			headerName: 'Last Day',
		},
	]

	const aggregatedEntries = useMemo(() => {
		const aggregatedMap = entries.reduce(
			(acc, entry) => {
				const existing = acc[entry.ip]
				if (existing) {
					existing.firstDay = existing.firstDay > entry.day ? entry.day : existing.firstDay
					existing.lastDay = existing.lastDay < entry.day ? entry.day : existing.lastDay
					// eslint-disable-next-line camelcase
					existing.events_count += entry.events_count
					if (entry.association && !existing.associations.includes(entry.association)) {
						existing.associations.push(entry.association)
					}
				} else {
					acc[entry.ip] = {
						...entry,
						firstDay: entry.day,
						lastDay: entry.day,
						associations: entry.association ? [entry.association] : [],
					}
				}
				return acc
			},
			{} as Record<string, AwsUsageLogsAggregationsColumn>,
		)

		return Object.values(aggregatedMap)
	}, [entries])

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			rowData={aggregatedEntries}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			enableCellTextSelection
			suppressHorizontalScroll={false}
		/>
	)
}
