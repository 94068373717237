import React from 'react'
import { Tooltip } from 'antd'
import { Tag } from '../../../schemas/tags.ts'
import { tagsIconsMap } from './common.tsx'
import { ResourceType, ResourceTypeMap } from '../../../schemas/issue.ts'

export const IdentityTagIcon: React.FunctionComponent<{
	name?: Tag
	resourceTypes?: Array<ResourceType>
	size?: number
	marginBottom?: string | null
	marginLeft?: string | null
}> = ({ name, resourceTypes, size = 20, marginBottom, marginLeft }) => {
	if (!name) {
		return null
	}
	const TagIcon = tagsIconsMap[name]?.icon
	const tagName = tagsIconsMap[name]?.title || name
	const title = resourceTypes?.length ? (
		<div className="flex flex-col">
			{resourceTypes.map((resourceType) => (
				<span key={resourceType}>{`${ResourceTypeMap[resourceType]} ${tagName}`}</span>
			))}
		</div>
	) : (
		tagName
	)

	return (
		<Tooltip
			placement="topLeft"
			title={title}
			align={{
				offset: [-16, -8],
			}}
		>
			{TagIcon ? (
				<div>
					<TagIcon
						className={'bg-neutral-50 rounded shadow border-2 border-white text-zinc-500'}
						style={{
							minWidth: size,
							minHeight: size,
							marginBottom: marginBottom || '0',
							marginLeft: marginLeft || '0',
						}}
					/>
				</div>
			) : (
				<div>{title}</div>
			)}
		</Tooltip>
	)
}
