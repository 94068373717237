import { z } from 'zod'
import { DateSchema } from '../common'

export const ServerAbstractEnvironmentModelSchema = z.object({
	id: z.string().uuid(),
	name: z.string(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	customer_id: z.string().uuid(),
	enabled: z.boolean(),
})
