import React from 'react'

export const CollapsibleItemLabel: React.FunctionComponent<{
	icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
	label: string
	secondaryLabel?: string
}> = ({ icon, label, secondaryLabel }) => {
	const Icon = icon
	return (
		<div className={`grid grid-flow-col grid-cols-[12%_35%_30%] items-center justify-items-start`}>
			{Icon && <Icon className={'bg-slate-100 w-6 h-6 p-1 mr-2 rounded'} />}
			<div>{label}</div>
			{secondaryLabel && <div className={'text-gray-400'}>{secondaryLabel}</div>}
		</div>
	)
}
