import React, { useCallback, useMemo, useRef, useEffect } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent, ICellRendererParams, GetRowIdParams } from '@ag-grid-community/core'
import { JsonViewer } from '../../components/common/JsonViewer'
import { ServerSalesforceProfile } from '../../schemas/identities/salesforce/salesforceProfileSchema.ts'
import { Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../utils.ts'
import { TagIcons } from '../../components/common/TagIcons.tsx'
import { ServerTag, type Tag, TagsDisplayNameMap } from '../../schemas/tags.ts'

export const SalesforceProfileTable: React.FunctionComponent<{
	data: ServerSalesforceProfile
	lastSelectedProfile?: string
}> = ({ data, lastSelectedProfile }) => {
	const gridRef = useRef<AgGridReact<ServerSalesforceProfile>>(null)

	const highlightRow = useCallback((rowId?: string | null) => {
		if (!rowId) {
			return
		}

		const rowNode = gridRef.current?.api?.getRowNode(rowId)
		if (!rowNode) {
			return
		}

		gridRef.current?.api?.ensureNodeVisible(rowNode, 'middle')
		gridRef.current?.api?.flashCells({ rowNodes: [rowNode] })
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<ServerSalesforceProfile>) => {
			highlightRow(lastSelectedProfile)
		},
		[highlightRow, lastSelectedProfile],
	)

	const columnDefs = useMemo<ColDef<ServerSalesforceProfile>[]>(() => {
		return [
			{ field: 'name', headerName: 'Name', flex: 1 },
			{
				field: 'created_date',
				headerName: 'Created Date',
				flex: 1,
				renderCell: (params: { value: Date }) => (
					<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
						<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
					</Tooltip>
				),
			},
			{
				field: 'last_modified_date',
				headerName: 'Last Modified Date',
				flex: 1,
				cellRenderer: (params: { value: string }) => (
					<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
						<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
					</Tooltip>
				),
			},
			{
				headerName: 'Risks',
				cellRenderer: (params: { data?: ServerSalesforceProfile }) => {
					return <TagIcons tags={params.data?.tags?.map(({ name }) => ({ name: name as Tag }))} />
				},
				valueGetter: (params: { data?: ServerSalesforceProfile }) => {
					return params.data?.tags?.map((tag: ServerTag): string => TagsDisplayNameMap[tag.name as Tag])
				},
			},
			{
				headerName: 'Permissions',
				flex: 1,
				cellRenderer: (params: ICellRendererParams<ServerSalesforceProfile>) => {
					return <JsonViewer data={params.data?.permissions || {}} title="Permissions" />
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	useEffect(() => {
		highlightRow(lastSelectedProfile)
	}, [lastSelectedProfile, highlightRow])

	if (!data) {
		return <div>No profile data available</div>
	}

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				ref={gridRef}
				rowData={[data]}
				getRowId={(rowData: GetRowIdParams<ServerSalesforceProfile>) => rowData.data.id}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				rowHeight={54}
			/>
		</div>
	)
}
