/* eslint-disable @typescript-eslint/no-misused-promises */
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { ControlButton, useReactFlow, Controls, FitViewOptions, useNodesInitialized } from '@xyflow/react'
import { CompactModeControlButton } from './CompactModeControlButton'
import PluseIcon from '../../../assets/pluse.svg?react'
import MinusIcon from '../../../assets/minus.svg?react'
import FitIcon from '../../../assets/CornersIn.svg?react'
import { GraphViewControlButton } from './GraphViewControlButton.tsx'
import { IdentityGraphViewTypeEnum } from './identityGraphTypes.ts'
import cx from 'classnames'
import { useUsageGraph } from '../../../services/auth/featureFlags.ts'
import { isDemo } from '../../../utils/demoUtils.ts'
import { IdentitySource } from '../../../schemas/identity.ts'

type IdentityGraphControlsProps = {
	isCompact: boolean
	setIsCompact: Dispatch<SetStateAction<boolean>>
	fitViewOptions: FitViewOptions
	viewType: IdentityGraphViewTypeEnum
	onViewTypeClicked: (viewType: IdentityGraphViewTypeEnum) => void
	identitySource: IdentitySource
}

const styles = {
	controlButton: '!bg-gray-50 !border !rounded-sm !border-solid !border-gray-100 !p-0 !m-0 hover:!bg-gray-100',
}

export const IdentityGraphControls = ({
	isCompact,
	setIsCompact,
	fitViewOptions,
	viewType,
	onViewTypeClicked,
	identitySource,
}: IdentityGraphControlsProps) => {
	const { zoomIn, zoomOut, fitView } = useReactFlow()
	const nodesInitialized = useNodesInitialized()
	const { isEntitled: isEntitledToUsageGraph } = useUsageGraph()

	useEffect(() => {
		fitView({ duration: 1000, maxZoom: 0.65 })
	}, [nodesInitialized])

	const BasicControlButtons = useMemo(
		() => [
			{
				onClick: () => fitView(),
				icon: FitIcon,
			},
			{
				onClick: () => zoomIn(),
				icon: PluseIcon,
			},
			{
				onClick: () => zoomOut(),
				icon: MinusIcon,
			},
		],
		[],
	)

	return (
		<Controls
			showInteractive={false}
			showFitView={false}
			showZoom={false}
			position="bottom-left"
			fitViewOptions={fitViewOptions}
			className={cx(
				'bg-transparent border-none shadow-transparent shadow-none',
				isEntitledToUsageGraph && isDemo && 'usage-flag-enabled',
			)}
			style={{ boxShadow: 'none' }}
		>
			<div className="grid gap-2 identity-graph-controls">
				{identitySource === IdentitySource.AWS_IAM_USER && (
					<GraphViewControlButton
						viewType={viewType}
						onViewTypeClicked={onViewTypeClicked}
						className={styles.controlButton}
					/>
				)}
				{BasicControlButtons.map((item, index) => (
					<ControlButton
						key={`graph-control-${index}`}
						onClick={item.onClick}
						className={cx(styles.controlButton, '!w-8 !h-8')}
					>
						<item.icon className={'!fill-transparent !max-w-7 !max-h-7'} />
					</ControlButton>
				))}
				<CompactModeControlButton isCompact={isCompact} setIsCompact={setIsCompact} />
			</div>
		</Controls>
	)
}
