import { z } from 'zod'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'
import { AccountPriorityEnum } from './accountPriorityEnum.ts'

export const ServerGcpProjectSchema = ServerAbstractEnvironmentModelSchema.extend({
	customer_id: z.string().uuid(),
	id: z.string(),
	enabled: z.boolean(),
	name: z.string(),
	gcp_environment_id: z.string().uuid().nullish(),
	project_id: z.string(),
	token_has_access: z.boolean().default(false),
	priority: z.nativeEnum(AccountPriorityEnum),
	created_at: z.string(),
	updated_at: z.string(),
})

export type ServerGcpProject = z.infer<typeof ServerGcpProjectSchema>
