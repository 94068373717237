import { Edge, EdgeTypes, SmoothStepEdgeProps } from '@xyflow/react'
import { IdentitySource, ServerIdentity, ServerIdentityUsageLogsGrouped } from '../../../../schemas/identity.ts'
import { getAwsNodesAndEdges, getAwsUsageNodesAndEdges } from '../aws/awsGraphUtils.ts'
import { getGcpNodesAndEdges } from '../gcp/gcpGraphUtils.ts'
import { getGithubNodesAndEdges } from '../github/githubGraphUtils.ts'
import { getEntraIdNodesAndEdges } from '../entraId/entraIdGraphUtils.ts'
import { getDatabricksNodesAndEdges } from '../databricks/databricksGraphUtils.ts'
import { getAzureKVNodesAndEdges } from '../azure/azureKV/azureKVGraphUtils.ts'
import { getOktaNodesAndEdges } from '../okta/oktaGraphUtils.ts'
import { getSnowflakeNodesAndEdges } from '../snowflake/snowflakeGraphUtils.ts'
import { BareNodesColumnsType, IdentityGraphNodeType, IdentityGraphViewTypeEnum } from '../identityGraphTypes.ts'
import { groupNodes } from './nodeGroups.ts'
import { uniqWith } from 'lodash'
import { getJumpcloudNodesAndEdges } from '../jumpcloud/jumpcloudGraphUtils.ts'
import { getSalesforceNodesAndEdges } from '../salesforce/salesforceGraphUtils.ts'
import { getGoogleWorkspaceNodesAndEdges } from '../googleWorkspace/googleWorkspaceGraphUtils.ts'
import { getPostgresRoleNodesAndEdges } from '../postgresRole/postgresGraphUtils.ts'
import { getAzureDevOpsNodesAndEdges } from '../azure/azureDevops/azureDevopsGraphUtils.ts'
import { getActiveDirectoryNodesAndEdges } from '../activeDirectory/activeDirectoryGraphUtils.ts'

const GRAPH_GAPS = {
	usage: { x: 300, y: 180 },
	static: { x: 200, y: 200 },
}

type GetEdgeArgs = {
	source: string
	target: string
	sourceHandle?: string
	targetHandle?: string
	animated?: boolean
	color?: string
	type?: keyof EdgeTypes
	label?: string
	labelStyle?: Record<string, string | number>
	labelBgStyle?: Record<string, string | number>
	labelBgPadding?: [number, number]
	labelBgBorderRadius?: number
	labelShowBg?: boolean
	pathOptions?: Record<string, string | number>
}

export type EdgeType = Edge & Partial<SmoothStepEdgeProps>

export const getEdge = ({
	source,
	target,
	sourceHandle,
	targetHandle,
	animated,
	color,
	type,
	label,
	labelStyle,
	labelBgStyle,
	labelBgPadding,
	labelBgBorderRadius,
	labelShowBg,
	pathOptions,
}: GetEdgeArgs): EdgeType => ({
	id: `edge_${source}_${target}`,
	source,
	sourceHandle: sourceHandle || 'right',
	targetHandle: targetHandle || 'left',
	target,
	animated,
	type,
	style: {
		stroke: color,
	},
	label,
	labelStyle,
	labelBgStyle,
	labelBgPadding,
	labelBgBorderRadius,
	labelShowBg,
	pathOptions,
})

export const getNodesAndEdges = (
	graphViewType: IdentityGraphViewTypeEnum,
	identity: ServerIdentity,
	isCompact: boolean,
	groupedUsageLogs?: ServerIdentityUsageLogsGrouped,
): [IdentityGraphNodeType[], Array<EdgeType>] => {
	let [bareNodesColumns, edges]: [BareNodesColumnsType[], Array<EdgeType>] = [[], []]
	switch (identity?.source) {
		case IdentitySource.AWS_IAM_USER:
			if (graphViewType === IdentityGraphViewTypeEnum.STATIC) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getAwsNodesAndEdges(identity)
			} else if (graphViewType === IdentityGraphViewTypeEnum.USAGE) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getAwsUsageNodesAndEdges(identity, groupedUsageLogs)
			}
			break
		case IdentitySource.AWS_IAM_ROLE:
		case IdentitySource.AWS_KEY_PAIR:
		case IdentitySource.AWS_EC2_INSTANCE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAwsNodesAndEdges(identity)
			break
		case IdentitySource.GCP_SERVICE_ACCOUNT:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getGcpNodesAndEdges(identity)
			break
		case IdentitySource.GITHUB_APP_INSTALLATION:
		case IdentitySource.GITHUB_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getGithubNodesAndEdges(identity)
			break
		case IdentitySource.ENTRA_ID_USER:
		case IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getEntraIdNodesAndEdges(identity)
			break
		case IdentitySource.SNOWFLAKE_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getSnowflakeNodesAndEdges(identity)
			break
		case IdentitySource.OKTA:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getOktaNodesAndEdges(identity)
			break
		case IdentitySource.DATABRICKS_USER:
		case IdentitySource.DATABRICKS_SERVICE_PRINCIPAL:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getDatabricksNodesAndEdges(identity)
			break
		case IdentitySource.AZURE_KV_CERTIFICATE:
		case IdentitySource.AZURE_KV_KEY:
		case IdentitySource.AZURE_KV_SECRET:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAzureKVNodesAndEdges(identity)
			break
		case IdentitySource.JUMPCLOUD_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getJumpcloudNodesAndEdges(identity)
			break
		case IdentitySource.SALESFORCE_USER:
		case IdentitySource.SALESFORCE_CONNECTED_APPLICATION:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getSalesforceNodesAndEdges(identity)
			break
		case IdentitySource.GOOGLE_WORKSPACE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getGoogleWorkspaceNodesAndEdges(identity)
			break
		case IdentitySource.POSTGRES_ROLE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getPostgresRoleNodesAndEdges(identity)
			break
		case IdentitySource.AZURE_DEVOPS_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAzureDevOpsNodesAndEdges(identity)
			break
		case IdentitySource.AZURE_DEVOPS_SERVICE_PRINCIPAL:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAzureDevOpsNodesAndEdges(identity)
			break
		case IdentitySource.ACTIVE_DIRECTORY_USER:
		case IdentitySource.ACTIVE_DIRECTORY_COMPUTER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getActiveDirectoryNodesAndEdges(identity)
			break
	}

	if (isCompact) {
		// eslint-disable-next-line no-extra-semi
		;[bareNodesColumns, edges] = groupNodes(bareNodesColumns, edges)
	}

	edges = uniqWith(edges, (edgeA, edgeB) => edgeA.source === edgeB.source && edgeA.target === edgeB.target)

	const nodes: IdentityGraphNodeType[] = bareNodesColumns
		.filter((bareNodeColumn) => !!bareNodeColumn.nodes.length)
		.map((bareNodeColumn, columnIndex) => {
			const middleYPoint = (bareNodeColumn.nodes.length - 1) / 2
			const x = columnIndex * GRAPH_GAPS[graphViewType].x
			return bareNodeColumn.nodes.toReversed().map((bareNode, rowIndex) => {
				const y =
					bareNodeColumn.yPosition === 'top'
						? (0 - (rowIndex + 1)) * GRAPH_GAPS[graphViewType].y
						: (middleYPoint - rowIndex) * GRAPH_GAPS[graphViewType].y
				return { ...bareNode, position: { x, y }, selectable: true }
			})
		})
		.flat()

	return [nodes, edges]
}
