import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GetRowIdParams, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core'
import { JsonViewer } from '../../components/common/JsonViewer'
import { ServerAdGroup } from '../../schemas/identities/activeDirectory/adGroupSchema'
import { TagIcons } from '../../components/common/TagIcons'
import { ServerTag, Tag, TagsDisplayNameMap } from '../../schemas/tags'
import { ServerAdAce } from '../../schemas/identities/activeDirectory/adAceSchema'

interface AdGroupsTableProps {
	groups: ServerAdGroup[]
	lastSelectedGroup?: string
}

export const AdGroupsTable: React.FC<AdGroupsTableProps> = ({ groups, lastSelectedGroup }) => {
	const gridRef = useRef<AgGridReact<ServerAdGroup>>(null)

	const highlightRow = useCallback((rowId?: string | null) => {
		if (!rowId) return
		const rowNode = gridRef.current?.api?.getRowNode(rowId)
		if (!rowNode) return
		gridRef.current?.api?.ensureNodeVisible(rowNode, 'middle')
		gridRef.current?.api?.flashCells({ rowNodes: [rowNode] })
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<ServerAdGroup>) => {
			highlightRow(lastSelectedGroup)
		},
		[highlightRow, lastSelectedGroup],
	)

	const filterOutTags = useCallback((permissions: ServerAdAce[]) => {
		return permissions.map(({ tags: _tags, ...rest }) => rest)
	}, [])

	const columnDefs = useMemo<ColDef<ServerAdGroup>[]>(
		() => [
			{
				field: 'sam_account_name',
				headerName: 'Group Name',
				flex: 1,
			},
			{
				field: 'object_sid',
				headerName: 'SID',
				flex: 1,
			},
			{
				field: 'description',
				headerName: 'Description',
				flex: 1,
			},
			{
				headerName: 'Risks',
				cellRenderer: (params: { data?: ServerAdGroup }) => {
					return <TagIcons tags={params.data?.tags?.map(({ name }) => ({ name: name as Tag }))} />
				},
				valueGetter: (params: { data?: ServerAdGroup }) => {
					return params.data?.tags?.map((tag: ServerTag): string => TagsDisplayNameMap[tag.name as Tag])
				},
			},
			{
				headerName: 'Outbound Permissions',
				flex: 1,
				cellRenderer: (params: ICellRendererParams<ServerAdGroup>) => (
					<JsonViewer
						data={filterOutTags(params.data?.outbound_permissions || [])}
						title="Access Control Entries"
					/>
				),
			},
		],
		[filterOutTags],
	)

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}),
		[],
	)

	useEffect(() => {
		highlightRow(lastSelectedGroup)
	}, [lastSelectedGroup, highlightRow])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				ref={gridRef}
				rowData={groups}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				getRowId={(params: GetRowIdParams<ServerAdGroup>) => params.data.object_sid}
				enableCellTextSelection
				className="ag-theme-alpine w-full h-full overflow-x-auto"
				rowHeight={54}
			/>
		</div>
	)
}
