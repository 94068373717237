import { z } from 'zod'

export const UsageAssociationItemSchema = z.object({
	name: z.string(),
	instances_count: z.number(),
	total_events_count: z.number(),
})

export type UsageAssociationItem = z.infer<typeof UsageAssociationItemSchema>

export enum AssociationTypeEnum {
	EC2 = 'EC2',
	NAT_GW = 'NAT GW',
	HUMAN = 'HUMAN',
	GLOBAL = 'GLOBAL',
	UNKNOWN = 'UNKNOWN',
}
