import { FilterChipValue, FilterChipValueProps } from './FilterChipValue.tsx'
import { Tooltip } from 'antd'

type SetFilterChipProps = {
	chipValues: Array<FilterChipValueProps & { key: string }>
}

export const SetFilterChip = ({ chipValues }: SetFilterChipProps) => {
	let child: React.ReactNode
	if (chipValues.length > 3) {
		const tooltipTitle = (
			<div className="flex flex-col">
				{chipValues.map((chipValue) => (
					<span key={chipValue.key}>{chipValue.title}</span>
				))}
			</div>
		)
		child = (
			<Tooltip title={tooltipTitle}>
				<span className="cursor-pointer">
					<FilterChipValue title={''}>{`${chipValues.length}...`}</FilterChipValue>
				</span>
			</Tooltip>
		)
	} else {
		child = chipValues.map(({ key, ...filterChipValueProps }) => (
			<FilterChipValue key={key} {...filterChipValueProps} />
		))
	}

	return <div className="flex items-center gap-1 truncate">{child}</div>
}
