import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { CellMouseOutEvent, CellMouseOverEvent, ColDef, GetRowIdParams, GridReadyEvent } from '@ag-grid-community/core'
import { ServerDetailedAzureRoleAssignment } from '../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { JsonViewer } from '../../components/common/JsonViewer.tsx'
import { TagIcons } from '../../components/common/TagIcons.tsx'
import { ServerTag, Tag, TagsDisplayNameMap } from '../../schemas/tags.ts'

export const AzureRoleAssignmentsTable: React.FunctionComponent<{
	data: ServerDetailedAzureRoleAssignment[]
	lastSelectedRole?: string
	onCellMouseOver?: (event: CellMouseOverEvent<ServerDetailedAzureRoleAssignment>) => void
	onCellMouseOut?: (event: CellMouseOutEvent<ServerDetailedAzureRoleAssignment>) => void
}> = ({ data, lastSelectedRole, onCellMouseOver, onCellMouseOut }) => {
	const gridRef = useRef<AgGridReact<ServerDetailedAzureRoleAssignment>>(null)
	const highlightRow = (rowId?: string | null) => {
		if (!rowId) {
			return
		}

		const rowNode = gridRef?.current?.api?.getRowNode(rowId)
		if (!rowNode) {
			return
		}

		gridRef?.current?.api?.ensureNodeVisible(rowNode, 'middle')
		gridRef?.current?.api?.flashCells({ rowNodes: [rowNode] })
	}

	const onGridReady = useCallback((_params: GridReadyEvent<ServerDetailedAzureRoleAssignment>) => {
		highlightRow(lastSelectedRole)
	}, [])

	const columnDefs = useMemo<ColDef<ServerDetailedAzureRoleAssignment>[]>(() => {
		return [
			{
				headerName: 'Role Name',
				field: 'role_definition.role_name',
				flex: 2,
			},
			{
				headerName: 'Scope',
				field: 'role_assignment.scope',
				flex: 3,
			},

			{
				headerName: 'Role Type',
				field: 'role_definition.role_type',
				flex: 1,
			},
			{
				headerName: 'Risk',
				flex: 1,
				cellRenderer: (params: { data?: ServerDetailedAzureRoleAssignment }) => {
					return <TagIcons tags={params.data?.tags?.map(({ name }) => ({ name: name as Tag }))} />
				},
				valueGetter: (params: { data?: ServerDetailedAzureRoleAssignment }) => {
					return params.data?.tags?.map((tag: ServerTag): string => TagsDisplayNameMap[tag.name as Tag])
				},
			},
			{
				headerName: 'Role Definition',
				cellRenderer: (params: { data: ServerDetailedAzureRoleAssignment }) => {
					return <JsonViewer data={params.data.role_definition} title="Role Definition" />
				},
				flex: 1,
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	useEffect(() => {
		highlightRow(lastSelectedRole)
	}, [lastSelectedRole])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				rowHeight={54}
				getRowId={(rowData: GetRowIdParams<ServerDetailedAzureRoleAssignment>) =>
					rowData.data.role_assignment.id
				}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
