import SlackIcon from '../../../../assets/slack_icon.svg?react'
import { CollaborationsIntegrationTestConnectionRequest } from '../../../../schemas/integrations/integrations.ts'
import { IntegrationType } from '../../../../jiraIntegration/schemas/common.ts'
import { CollaborationIntegrationConfig } from '../collaborationIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const slackConfig: CollaborationIntegrationConfig = {
	key: IntegrationType.SLACK,
	name: 'Slack',
	description: 'Connect Token Security to your Slack workspace for real-time notifications and alerts.',
	logo: <IntegrationIcon leftIcon={SlackIcon} />,
	createTestConnectionPayload: (): CollaborationsIntegrationTestConnectionRequest => ({
		// eslint-disable-next-line camelcase
		integration_type: IntegrationType.SLACK,
	}),
}
