import { IdentityGraphViewTypeEnum } from './identityGraphTypes.ts'

const IdentityGraphTitles = {
	[IdentityGraphViewTypeEnum.STATIC]: 'Static view',
	[IdentityGraphViewTypeEnum.USAGE]: 'Usage view',
}

export const IdentityGraphTitle = ({
	viewType = IdentityGraphViewTypeEnum.STATIC,
}: {
	viewType?: IdentityGraphViewTypeEnum
}) => <div className="text-s text-zinc-400 font-light mt-2 ml-3 w-max">{IdentityGraphTitles[viewType]}</div>
