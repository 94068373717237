import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, ICellRendererParams } from '@ag-grid-community/core'
import { ServerGcpPrincipalInfoXc } from '../../schemas/identities/gcp/gcpProjectXcSchema.ts'

export interface GcpRoleName {
	roleName: string
}

export const ConnectedGcpRolesTable: React.FunctionComponent<ICellRendererParams<ServerGcpPrincipalInfoXc>> = ({
	data,
	node: _node,
	api: _api,
}) => {
	const gridRef = useRef<AgGridReact<GcpRoleName>>(null)
	const [rowData, setRowData] = useState<GcpRoleName[]>()

	const onGridReady = useCallback(() => {
		const principalRolesInProject: GcpRoleName[] = (data as ServerGcpPrincipalInfoXc).roles.map((role) => {
			return { roleName: role }
		})
		setRowData(principalRolesInProject)
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [data])

	const columnDefs = useMemo<ColDef<GcpRoleName>[]>(() => {
		return [{ field: 'roleName' }]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={rowData}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
