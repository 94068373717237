import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { EdgeType, getEdge } from '../graphUtils/nodesAndEdges.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { OktaUserNodeType } from '../okta/OktaUserNode.tsx'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	identity: 1,
}
const identityNodeRowIndex = 0
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`
const oktaUserNodeRowIndex = 2
const oktaUserNodeId = `${nodeLogicalTypeToColumnId.identity}-${oktaUserNodeRowIndex}`

export const getGoogleWorkspaceNodesAndEdges = (
	identity: ServerIdentity,
): [BareNodesColumnsType[], Array<EdgeType>] => {
	const edges: Array<EdgeType> = []
	const identityNodes: Array<BareNodeType<IdentityNodeType> | BareNodeType<OktaUserNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	if (identity.google_workspace_user?.okta_user_xc?.length) {
		// TODO: We currently use only the first okta user as a node, fix if need rises, requires proper UI design
		const details = identity.google_workspace_user.okta_user_xc[0]
		const displayName = details.profile?.displayName || details.profile?.email
		identityNodes.push({
			type: 'oktaUser',
			data: { oktaUser: { type: 'Okta User', displayName } },
			id: oktaUserNodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: oktaUserNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []

	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})

	// -------------------- Edges --------------------
	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'center', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: identityNodes },
	]
	return [nodes, edges]
}
