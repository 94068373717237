import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import cx from 'classnames'

import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { nodeColorClassNames } from '../graphUtils/nodeColors'
import { ServerAdGroup } from '../../../../schemas/identities/activeDirectory/adGroupSchema.ts'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons.tsx'

export type AdGroupNodeType = Node<{ group: ServerAdGroup }, 'adGroup'>
type AdGroupNodeProps = NodeProps<AdGroupNodeType>

export const AdGroupNode = ({ data: { group } }: AdGroupNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'adGroup' && highlightedNode.id === group.object_sid,
	)

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode
			label="AD Group"
			name={{
				text: group.sam_account_name,
				tooltipText: group.description || '',
			}}
		>
			<div className={iconWrapperClassName}>
				<RiskNodeTagIcons tags={group.tags} />
				<FileTextOutlined className="text-2xl" />
			</div>
		</BaseNode>
	)
}

export default AdGroupNode
