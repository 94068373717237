import { Layout, Skeleton } from 'antd'
import { useMetricsTopBar } from '../../../api/metrics.ts'
import { StatItem } from '../../../components/common/StatItem.tsx'
import { IdentityPosture } from './IdentityPosture.tsx'
import { TotalIdentitiesAtRisk } from './TotalIdentitiesAtRisk.tsx'
import { TotalAdminsAtRisk } from './TotalAdminsAtRisk.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'

const MetricsStatsHeaderContentV2 = () => {
	const { data: metricsTopBar } = useMetricsTopBar()
	const navigate = useNavigate({ from: RoutePaths.Metrics })
	if (!metricsTopBar) {
		return <Skeleton active />
	}
	return (
		<>
			<StatItem title="Identity Posture">
				<IdentityPosture postureScore={metricsTopBar.identity_posture_score} />
			</StatItem>
			<StatItem title="Total Identities">
				{
					<span
						className="cursor-pointer"
						onClick={() => {
							void navigate({ to: RoutePaths.Identities })
						}}
					>
						{formatNumber(metricsTopBar.total_identities)}
					</span>
				}
			</StatItem>
			<StatItem title="Total Identities at Risk">
				<TotalIdentitiesAtRisk metricsTopBar={metricsTopBar} />
			</StatItem>
			<StatItem title="Total Admins at Risk">
				<TotalAdminsAtRisk metricsTopBar={metricsTopBar} />
			</StatItem>
		</>
	)
}

export const MetricsStatsHeader = () => {
	const headerStyle = {
		boxShadow: '0px 0px 20px 0px #8B90AC1A',
	}

	return (
		<Layout.Header
			style={headerStyle}
			className="bg-white border-y border-neutral-200 flex justify-between min-h-[130px] gap-2 max-w-full h-fit py-4 px-8 items-stretch"
		>
			<MetricsStatsHeaderContentV2 />
		</Layout.Header>
	)
}
