import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState, ReactNode } from 'react'
import { TagsCollapseItemKeys } from './IdentitySidePanel/SidePanelTags.tsx'

type ActiveTagsKeysContextValue = {
	activeTagsKeys: Array<TagsCollapseItemKeys>
	setActiveTagsKeys: Dispatch<SetStateAction<Array<TagsCollapseItemKeys>>>
}

const ActiveTagsKeysContext = createContext<ActiveTagsKeysContextValue | null>(null)

export const ActiveTagsKeysProvider = ({ children }: { children: ReactNode }) => {
	const [activeTagsKeys, setActiveTagsKeys] = useState<Array<TagsCollapseItemKeys>>([])
	const value = useMemo(
		() => ({
			activeTagsKeys,
			setActiveTagsKeys,
		}),
		[activeTagsKeys, setActiveTagsKeys],
	)
	return <ActiveTagsKeysContext.Provider value={value}>{children}</ActiveTagsKeysContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export const useActiveTagsKeysContext = () => {
	const contextValue = useContext(ActiveTagsKeysContext)
	if (!contextValue) {
		throw new Error(
			'Attempted to use useActiveTagsKeysContext but no context value was found. You probably forgot to wrap your code with ActiveTagsKeysContextProvider',
		)
	}

	return contextValue
}
