import React from 'react'
import { Layout } from 'antd'
import { SideBarMenu } from '../menus/SideBarMenu'
import { useAuth } from '../../services/auth/hooks.ts'

const { Content, Sider } = Layout
export const MainLayout: React.FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const { user } = useAuth()
	if (!user) {
		return null
	}
	return (
		<Layout
			style={{
				background: 'linear-gradient(246.11deg, #2B2B2B 75.99%, #276C50 128.28%)',
			}}
			className="h-screen"
		>
			<Sider
				collapsedWidth={55}
				style={{
					background: 'transparent',
				}}
				trigger={null}
				defaultCollapsed={true}
				collapsed={true}
			>
				<SideBarMenu />
			</Sider>
			<Content>{children}</Content>
		</Layout>
	)
}
