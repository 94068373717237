import { ResourceType, ResourceTypeIconMap, ResourceTypeMap } from '../../schemas/issue'
import React from 'react'

export const IssueSourceFilterIcon: React.FunctionComponent<{
	source?: string
}> = ({ source }) => {
	if (!source) {
		return null
	}
	const SourceIcon = ResourceTypeIconMap[source as ResourceType]
	const title = ResourceTypeMap[source as ResourceType] || source

	if (!SourceIcon) {
		return <div>{title}</div>
	}

	return (
		<div className="flex items-center gap-x-2">
			<div className="ml-1">
				<SourceIcon style={{ width: 16, height: 16 }} />
			</div>
			<div className="mr-2">{title}</div>
		</div>
	)
}
