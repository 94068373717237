const numFormatter = new Intl.NumberFormat('en-US')

export function formatNumber(number: number): string {
	return numFormatter.format(number)
}

export function formatBigNumber(num: number): string {
	return new Intl.NumberFormat('en-US', {
		notation: 'compact',
		compactDisplay: 'short',
	}).format(num)
}
