import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import EntraIdIcon from '../../../../assets/entra_id_icon_16.svg?react'
import AzureIcon from '../../../../assets/azure_icon.svg?react'
import { ServerEntraIdEnvironment } from '../../../../schemas/environments/entraIdEnvironment'

type EntraIdSinglePageIntegrationBodyProps = {
	entraIdEnvironments: ServerEntraIdEnvironment[]
}

export const EntraIdSinglePageIntegrationBody: React.FC<EntraIdSinglePageIntegrationBodyProps> = ({
	entraIdEnvironments,
}) => {
	return (
		<div className="flex flex-wrap gap-4">
			{entraIdEnvironments?.map((environment) => {
				const cardConfig = {
					title: environment.name,
					logo: <IntegrationIcon leftIcon={EntraIdIcon} rightIcon={AzureIcon} />,
					fields: [
						{ label: 'Tenant', value: environment.entra_id_tenant_id },
						{ label: 'ID', value: environment.entra_id_app_id },
					],
				}
				return (
					<SingleIntegrationGenericCard
						key={environment.id}
						title={cardConfig.title}
						logo={cardConfig.logo}
						fields={cardConfig.fields}
					/>
				)
			})}
		</div>
	)
}
