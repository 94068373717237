import { Button } from 'antd'
import cx from 'classnames'
import { CloseOutlined } from '@ant-design/icons'

export type FilterChipValueProps = {
	title: string
	children?: React.ReactNode
	onRemove?: () => void
}

export const FilterChipValue = ({ title, onRemove, children }: FilterChipValueProps) => {
	const containerClassName = cx(
		'flex items-center gap-0.5 py-[2px] bg-neutral-100 rounded text-neutral-700 text-xs max-w-36 truncate basis-full justify-between',
		{ 'px-1': !onRemove, 'pl-1': !!onRemove },
	)

	return (
		<span className={containerClassName} title={title}>
			{children ?? <span className="truncate">{title}</span>}
			{!!onRemove && (
				<Button
					type="text"
					size="small"
					className="!p-0 !m-0 !w-4 !h-4 flex items-center justify-center hover:bg-neutral-100 bg-neutral-100 shrink-0"
					icon={<CloseOutlined className="hover:!text-neutral-600 h-3 w-3" />}
					onClick={onRemove}
				/>
			)}
		</span>
	)
}
