import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { Prism } from 'react-syntax-highlighter'
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism'

export const SQLViewer: React.FunctionComponent<{
	sqlQuery: string
	title: string
}> = ({ sqlQuery, title }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const showModal = () => {
		setIsModalOpen(true)
	}

	const handleOk = () => {
		setIsModalOpen(false)
	}

	const handleCancel = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<div className="underline cursor-pointer text-blue-600" onClick={showModal}>
				View Query
			</div>
			<Modal
				width={1024}
				title={title}
				open={isModalOpen}
				onCancel={handleCancel}
				onOk={handleOk}
				footer={[
					<Button key="done-button" className="bg-black text-white" onClick={handleCancel}>
						Done
					</Button>,
				]}
			>
				<Prism language="sql" style={coy}>
					{sqlQuery}
				</Prism>
			</Modal>
		</>
	)
}
