import { useCallback, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { CellMouseOutEvent, CellMouseOverEvent, ColDef, GetRowIdParams, GridReadyEvent } from '@ag-grid-community/core'
import { ServerSnowflakeRoleXc } from '../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { TagIcons } from '../components/common/TagIcons.tsx'
import { ServerTag, type Tag, TagsDisplayNameMap } from '../schemas/tags.ts'

export const SnowflakeRolesTable: React.FunctionComponent<{
	data: ServerSnowflakeRoleXc[]
	lastSelectedRole?: string
	onCellMouseOver?: (event: CellMouseOverEvent<ServerSnowflakeRoleXc>) => void
	onCellMouseOut?: (event: CellMouseOutEvent<ServerSnowflakeRoleXc>) => void
}> = ({ data, lastSelectedRole, onCellMouseOver, onCellMouseOut }) => {
	const gridRef = useRef<AgGridReact<ServerSnowflakeRoleXc>>(null)

	useEffect(() => {
		highlightRow(lastSelectedRole)
	}, [lastSelectedRole])

	const highlightRow = useCallback((rowId?: string | null) => {
		if (!rowId) {
			return
		}

		const rowNode = gridRef.current?.api?.getRowNode(rowId)
		if (!rowNode) {
			return
		}

		gridRef.current?.api?.ensureNodeVisible(rowNode, 'middle')
		gridRef.current?.api?.flashCells({ rowNodes: [rowNode] })
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<ServerSnowflakeRoleXc>) => {
		highlightRow(lastSelectedRole)
	}, [])

	const columnDefs = useMemo<ColDef<ServerSnowflakeRoleXc>[]>(() => {
		return [
			{ field: 'name' },
			{ field: 'created_on', headerName: 'Created On' },
			{ field: 'owner' },
			{
				headerName: 'Assignment',
				field: 'direct_user_assignment',
				cellRenderer: (params: { data?: ServerSnowflakeRoleXc }) => {
					return <div>{params?.data?.direct_user_assignment ? 'Direct' : 'Indirect'}</div>
				},
			},
			{
				headerName: 'Risk',
				cellRenderer: (params: { data?: ServerSnowflakeRoleXc }) => {
					return <TagIcons tags={params.data?.tags?.map(({ name }) => ({ name: name as Tag }))} />
				},
				valueGetter: (params: { data?: ServerSnowflakeRoleXc }) => {
					return params.data?.tags?.map((tag: ServerTag): string => TagsDisplayNameMap[tag.name as Tag])
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="h-64 overflow-auto">
			<AgGridReact
				className={'ag-theme-alpine w-full'}
				rowData={data}
				rowHeight={54}
				getRowId={(rowData: GetRowIdParams<ServerSnowflakeRoleXc>) => rowData.data.name}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				ref={gridRef}
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
