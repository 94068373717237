import { PriorityTypeEnum, PriorityTypeNameToNumber } from '../../../schemas/issue.ts'
import React from 'react'
import { ServerMetricsTopBar } from '../../../schemas/metrics/metricsTopBar.ts'
import { Tooltip } from 'antd'
import { PriorityBar } from '../../Findings/stats/PriorityBar.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { IdentitiesPageSearch } from '../../../schemas/identity.ts'
import { Tag } from '../../../schemas/tags.ts'

export const TotalAdminsAtRisk: React.FunctionComponent<{
	metricsTopBar: ServerMetricsTopBar
}> = ({ metricsTopBar }) => {
	const navigate = useNavigate({ from: RoutePaths.Metrics })
	const navigateToIdentities = (priorities?: string[]) => {
		const search: IdentitiesPageSearch = {
			// eslint-disable-next-line camelcase
			identityFilter: { tags_names: { filterType: 'set', values: [Tag.ADMIN] } },
		}
		if (priorities) {
			const convertedPriorities = priorities.map((priority) => {
				return `${PriorityTypeNameToNumber[priority as PriorityTypeEnum]}`
			})

			// eslint-disable-next-line camelcase
			search.identityFilter!.max_priority = { filterType: 'set', values: convertedPriorities }
		}

		void navigate({
			to: RoutePaths.Identities,
			search,
		})
	}

	return (
		<div className="h-full flex gap-4">
			<div className="flex gap-1 items-end self-start">
				<span
					className="cursor-pointer"
					onClick={() => {
						navigateToIdentities([PriorityTypeEnum.HIGH, PriorityTypeEnum.CRITICAL])
					}}
				>
					{formatNumber(metricsTopBar.admin_high_issue_count + metricsTopBar.admin_critical_issue_count)}
				</span>
				<Tooltip title="Total admins">
					<span
						className="font-normal text-neutral-400 text-base cursor-pointer"
						onClick={() => {
							navigateToIdentities()
						}}
					>{`/${formatNumber(metricsTopBar.admin_count)}`}</span>
				</Tooltip>
			</div>
			<PriorityBar
				findingsByPriority={[
					{ priority: PriorityTypeEnum.CRITICAL, count: metricsTopBar.admin_critical_issue_count },
					{ priority: PriorityTypeEnum.HIGH, count: metricsTopBar.admin_high_issue_count },
				]}
				onPriorityNameTagClick={(priority) => {
					navigateToIdentities([priority])
				}}
			/>
		</div>
	)
}
