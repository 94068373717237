import { PriorityTypeEnum, PriorityTypeNameToNumber } from '../../../schemas/issue.ts'
import React from 'react'
import { ServerMetricsTopBar } from '../../../schemas/metrics/metricsTopBar.ts'
import TrendUpIcon from '../../../assets/trend_up_icon.svg?react'
import { PriorityBar } from '../../Findings/stats/PriorityBar.tsx'
import { useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { IdentitiesPageSearch } from '../../../schemas/identity.ts'
import { formatNumber } from '../../../utils/numberUtils.ts'

export const TotalIdentitiesAtRisk: React.FunctionComponent<{
	metricsTopBar: ServerMetricsTopBar
}> = ({ metricsTopBar }) => {
	const navigate = useNavigate({ from: RoutePaths.Metrics })
	const navigateToIdentities = (priorities?: string[]) => {
		const search: IdentitiesPageSearch = {}
		if (priorities) {
			const convertedPriorities = priorities.map((priority) => {
				return `${PriorityTypeNameToNumber[priority as PriorityTypeEnum]}`
			})

			// eslint-disable-next-line camelcase
			search.identityFilter = { max_priority: { filterType: 'set', values: convertedPriorities } }
		}

		void navigate({
			to: RoutePaths.Identities,
			search,
		})
	}

	const difference =
		metricsTopBar.high_count_latest +
		metricsTopBar.critical_count_latest -
		metricsTopBar.high_count_x_days_ago -
		metricsTopBar.critical_count_x_days_ago

	const iconStyle = {
		transform: difference < 0 ? 'rotate(90deg)' : 'none',
		color: difference < 0 ? 'green' : '#FF496A',
	}

	const diffStyle = {
		backgroundColor: difference < 0 ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
		padding: '2px 6px',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		gap: '2px',
		height: 'fit-content',
	}

	return (
		<div className="h-full flex gap-4">
			<div className="flex gap-2 h-min">
				<span
					className="cursor-pointer"
					onClick={() => {
						navigateToIdentities([PriorityTypeEnum.HIGH, PriorityTypeEnum.CRITICAL])
					}}
				>
					{formatNumber(metricsTopBar.high_count_latest + metricsTopBar.critical_count_latest)}{' '}
				</span>
				<div className="flex self-end">
					<div style={diffStyle}>
						<span className="text-xs">
							{difference > 0 ? '+' : ''}
							{formatNumber(difference)}
						</span>
						<TrendUpIcon style={iconStyle} />
					</div>
				</div>
			</div>
			<PriorityBar
				findingsByPriority={[
					{ priority: PriorityTypeEnum.CRITICAL, count: metricsTopBar.critical_count_latest },
					{ priority: PriorityTypeEnum.HIGH, count: metricsTopBar.high_count_latest },
				]}
				onPriorityNameTagClick={(priority) => {
					navigateToIdentities([priority])
				}}
			/>
		</div>
	)
}
