import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { EdgeType, getEdge } from '../graphUtils/nodesAndEdges.ts'
import { GcpProjectNodeType } from './GcpProjectNode.tsx'
import { GcpRoleNodeType } from './GcpRoleNode.tsx'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { IssueName } from '../../../../schemas/issue.ts'
import { GcpAccessKeyNodeType } from './GcpSaAccessKeyNode.tsx'
import { ServerKubernetesResourceXc } from '../../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { KubernetesResourcesNodeType } from '../common/KubernetesResourcesNode.tsx'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import { ServerAwsIamChangeLog } from '../../../../schemas/identities/awsIamChangeLogSchema.ts'
import { aggregateAwsIamChangeLogs } from '../../../../utils/awsIdentityUtils.ts'
import { getActorArnFriendlyName } from '../../../../components/drawers/identities/utils.tsx'
const nodeLogicalTypeToColumnId = {
	vmsAndK8s: 0,
	generalIssue: 1,
	identity: 2,
	accessKey: 3,
	accessKeyIssue: 4,
	project: 5,
	role: 6,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

const accessKeyIssueNames: IssueName[] = [
	IssueName.MultipleAccessKeys,
	IssueName.AccessKeyNotRotated,
	IssueName.ServiceAccountKeyReferencedInPod,
]

export const getGcpNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Array<EdgeType>] => {
	const edges: Array<EdgeType> = []
	const identityNodes: Array<BareNodeType<IdentityNodeType> | BareNodeType<OwnershipNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const changeLogs: ServerAwsIamChangeLog[] = identity.gcp_service_account?.change_logs || []
	if (changeLogs?.length) {
		const aggregatedChangeLogs = aggregateAwsIamChangeLogs(changeLogs)
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: aggregatedChangeLogs.map((aggChangeLog) => ({
					id: aggChangeLog.actorArn,
					name: getActorArnFriendlyName(aggChangeLog.actorArn),
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const accessKeyIssueNodes: BareNodeType<IssueNodeType>[] = []
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issue_name && accessKeyIssueNames.includes(issue.issue_name)) {
			accessKeyIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.accessKeyIssue}-${accessKeyIssueNodes.length}`,
			})
		} else {
			generalIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
			})
		}
	})
	const vmsAndK8sNodes: Array<BareNodeType<KubernetesResourcesNodeType>> = []
	const kubernetesResources: ServerKubernetesResourceXc[] =
		identity.gcp_service_account?.kubernetes_resources_xc || []
	if (kubernetesResources.length) {
		vmsAndK8sNodes.push({
			type: 'kubernetesResources',
			data: { resources: kubernetesResources },
			id: `${nodeLogicalTypeToColumnId.vmsAndK8s}-${vmsAndK8sNodes.length}`,
		})
	}
	const accessKeyNodes: BareNodeType<GcpAccessKeyNodeType>[] =
		identity.gcp_service_account?.service_account_keys_xc?.map((accessKey, index) => ({
			type: 'gcpAccessKey',
			data: { accessKey },
			id: `${nodeLogicalTypeToColumnId.accessKey}-${index}`,
		})) || []

	const gcpProjectNodes: Array<BareNodeType<GcpProjectNodeType>> = []
	const gcpRoleNodes: Array<BareNodeType<GcpRoleNodeType>> = []

	if (identity.gcp_service_account?.role_mappings?.length) {
		identity.gcp_service_account.role_mappings.forEach((mapping) => {
			// Add project if not already in list
			if (!gcpProjectNodes.find((node) => node.data.project.project_id === mapping.project.project_id)) {
				gcpProjectNodes.push({
					type: 'gcpProject',
					data: { project: mapping.project },
					id: `${nodeLogicalTypeToColumnId.project}-${gcpProjectNodes.length}`,
				})
			}

			// Add role if not already in list
			if (!gcpRoleNodes.find((node) => node.data.role.name === mapping.role.name)) {
				gcpRoleNodes.push({
					type: 'gcpRole',
					data: { role: mapping.role },
					id: `${nodeLogicalTypeToColumnId.role}-${gcpRoleNodes.length}`,
				})
			}
		})
	}

	vmsAndK8sNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.vmsAndK8s}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	accessKeyNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessKey}-${index}`
		edges.push(getEdge({ source: source, target: identityNodeId }))
	})

	// Connect roles to identity
	gcpRoleNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.role}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	// Connect projects to roles
	gcpRoleNodes.forEach((roleNode, roleIndex) => {
		const roleId = `${nodeLogicalTypeToColumnId.role}-${roleIndex}`
		// Find all projects that have this role
		identity.gcp_service_account?.role_mappings?.forEach((mapping) => {
			if (mapping.role.name === roleNode.data.role.name) {
				// Find the project node ID for this mapping
				const projectNode = gcpProjectNodes.find((node) => node.data.project.id === mapping.project.id)
				if (projectNode) {
					edges.push(getEdge({ source: roleId, target: projectNode.id }))
				}
			}
		})
	})

	// Connect every access key issue node to either:
	//  - All access key nodes, if the issue is for multiple access keys.
	//  - A single access key node, if the issue is for a specific key.
	//  - The identity node if there is no relevant access key node.
	accessKeyIssueNodes.forEach((issueNode, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessKeyIssue}-${index}`
		// There are no access key nodes - connect the issue node to the identity node.
		if (!accessKeyNodes.length) {
			edges.push(getEdge({ source, target: identityNodeId }))
			return
		}

		// There are access keys and the issue is multiple access keys - connect the issue to all access key nodes.
		if (issueNode.data.issue.issue_name === IssueName.MultipleAccessKeys) {
			accessKeyNodes.forEach((_, accessKeyIndex) => {
				const target = `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyIndex}`
				edges.push(getEdge({ source, target }))
			})
		} else {
			// There are access keys and the issue node should connect to one of them - try and find the relevant
			//  access key node. If it is not found (e.g. the access key was already deleted) - connect the issue node
			//  to the identity node.
			const accessKeyNodeIndex = accessKeyNodes.findIndex(
				(accessKeyNode) =>
					!!accessKeyNode.data.accessKey?.accessKeyId &&
					issueNode.data.issue.description?.includes(accessKeyNode.data.accessKey?.accessKeyId),
			)
			const target =
				accessKeyNodeIndex === -1
					? identityNodeId
					: `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyNodeIndex}`
			edges.push(getEdge({ source, target }))
		}
	})
	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'top', nodes: vmsAndK8sNodes },
		{ yPosition: 'center', nodes: [...accessKeyIssueNodes, ...generalIssueNodes] },
		{ yPosition: generalIssueNodes.length > 0 ? 'top' : 'center', nodes: accessKeyNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: gcpRoleNodes },
		{ yPosition: 'center', nodes: gcpProjectNodes },
	]
	return [nodes, edges]
}
