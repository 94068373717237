import { z } from 'zod'

export const UsageAssociationToActionsSchema = z.record(z.array(z.string()))

export type UsageAssociationToActionsItem = z.infer<typeof UsageAssociationToActionsSchema>

export const UsageActionSchema = z.object({
	name: z.string(),
})

export type UsageActionItem = z.infer<typeof UsageActionSchema>

export enum ActionTypeEnum {
	S3 = 'S3',
	EC2 = 'EC2',
	STS = 'STS',
	SQS = 'SQS',
}
