import { Node } from '@xyflow/react'
import { BaseNode } from './BaseNode.tsx'
import { NodeBadge } from './../NodeBadge.tsx'
import { UsageAssociationItem } from '../../../../../schemas/identities/usageAssociationItemSchema.ts'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { UsageAssociationIcon } from '../../../../../components/common/UsageAssociationIcon.tsx'

export type AssociationNodeType = Node<UsageAssociationItem, 'association'>

export type BaseAssociationNodeProps = {
	data: UsageAssociationItem
}

export const BaseAssociationNode = ({ data: { name, instances_count: count } }: BaseAssociationNodeProps) => {
	const iconWrapperClassName = `${nodeColorClassNames.association.bgHover} ${nodeColorClassNames.association.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`
	const badgeClassName = `${nodeColorClassNames.association.badgeBg} ${nodeColorClassNames.association.badgeBorder} ${nodeColorClassNames.association.badgeText}`
	return (
		<BaseNode label={name}>
			<NodeBadge className={badgeClassName}>{count}</NodeBadge>
			<div className={iconWrapperClassName}>
				<UsageAssociationIcon name={name} color="white" />
			</div>
		</BaseNode>
	)
}
