import React from 'react'
import { ITenantsResponseV2 } from '@frontegg/rest-api/tenants/interfaces'
import { useAuth } from '../../services/auth/hooks.ts'

export const TenantMenuItem: React.FC<{ tenant: ITenantsResponseV2 }> = ({ tenant }) => {
	const { activeTenant } = useAuth()
	const isActive = activeTenant?.id === tenant.id
	const activeClass = isActive ? 'font-extrabold text-green-600' : 'text-grey-900'
	return (
		<div className={activeClass}>
			{tenant.name} {isActive ? '(Active)' : ''}
		</div>
	)
}
